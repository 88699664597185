import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ResultDetailsService } from '../services/result-deatils-service/result-details.service';
import { GridSizeChangedEvent } from 'ag-grid-community';
import { SlideCellRendererComponent } from '../custom/slide-cell-renderer/slide-cell-renderer.component';
import { MatchRendererComponent } from '../custom/match-renderer/match-renderer.component';
import { AppConstants } from '../common-utility/appconstants';
import { ToastrService } from 'ngx-toastr';
import { EntriesLinkRendererComponent } from '../custom/entries-link-renderer/entries-link-renderer.component';
import { ResultDetailsModel } from '../models/result-details.model';
import { MessageService } from '../services/message-service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { StatusDialogComponent } from '../shared/status-dialog/status-dialog.component';


@Component({
  selector: 'app-experiment-result-details',
  templateUrl: './experiment-result-details.component.html',
  styleUrls: ['./experiment-result-details.component.scss']
})
export class ExperimentResultDetailsComponent implements OnInit {

  sampleDaysDataId: number;
  fermDay: number;
  methodId: number;
  experimentId: any;
  methodName: any;
  rowData: ResultDetailsModel[] = [];
  experimentDetails: any;
  public context: any;
  private gridApi;
  private gridColumnApi;
  public subscription: any;
  componentName = AppConstants.BARCODE;
  subComponentName = AppConstants.ACTIVEINACTIVEAll;
  public activeInactiveAll: boolean = false;
  constructor(private route: ActivatedRoute,
              private resultDetailsService: ResultDetailsService,
              public datepipe: DatePipe, private toastr: ToastrService,
              private _messageService: MessageService,
              private router: Router, private dialog: MatDialog) {
                this.context = { componentParent: this };
                this._messageService.listen().subscribe((m: any) => {
                  this.refreshGrid();
                });
               }
  
  ngOnInit() {
  this.route.paramMap.subscribe(params => {
    this.sampleDaysDataId = Number(params.get("sampleDaysDataId"));
  });

  this.getResultDetails();
  
}

getResultDetails() {
  this.resultDetailsService.getResultDetails(this.sampleDaysDataId)
    .subscribe(
      result => {
        this.experimentDetails = result.ExperimentDetails != undefined ? result.ExperimentDetails : {};
        this.rowData = [];
        if(result.ResultDetails != undefined) {
          result.ResultDetails.forEach(r => {
            this.rowData.push({
              IsActive: r.IsActive,
              Entries: r.Entries,
              Barcode: r.Barcode,
              RunDate: this.datepipe.transform(r.RunDate,'MM/dd/yyyy'),  
              RunTime: r.RunTime,  
              DataIn: r.DataIn,
              DataSubmitted: r.DataSubmitted,
              DataMatched: r.DataMatched,
              ResultFileId: r.ResultFileId,
              SampleDaysDataId: r.SampleDaysDataId,
              IsDataMatched: r.IsDataMatched,
              IsProcessed: r.IsProcessed,
              FileName: r.FileName
            })
          });

          this.activeInactiveAll = !this.rowData.every(x => !x.IsActive);
        }
      });
}


  frameworkComponents = {
    linkRenderer: EntriesLinkRendererComponent,
    slideCellRenderer: SlideCellRendererComponent,
    matchCellRenderer: MatchRendererComponent
  };
  defaultColDef: {
    flex: 1,
    enableValue: true,
    enableRowGroup: true,
    enablePivot: true,
    sortable: true,
    filter: true,
  }
  columnDefs = [
    { headerName: 'Active', field: 'IsActive', cellRenderer: 'slideCellRenderer', sortable: true, filter: true},
    { headerName: 'Entries', field: 'Entries', sortable: true, filter: true, cellRenderer: 'linkRenderer' },
    { headerName: 'Barcode', field: 'Barcode', sortable: true, filter: true },
    { headerName: 'File Name', field: 'FileName', sortable: true, filter: true, tooltipValueGetter: (params) => params.value },
    { headerName: 'Date', field: 'RunDate', sortable: true, filter: true },
    { headerName: 'Time', field: 'RunTime', sortable: true, filter: true },
    { headerName: 'Data In', field: 'DataIn', sortable: true, filter: true, headerTooltip: 'Number of samples data was received from files.' },
    { headerName: 'Data Submitted', field: 'DataSubmitted', sortable: true, filter: true, headerTooltip: 'Number of samples submitted from sample information table.' },
    { headerName: 'Data Matched', field: 'DataMatched', sortable: true, filter: true, headerTooltip: 'Number of wells matched from Data In and Data Submitted.' },
    { headerName: 'Matched', field: 'IsDataMatched', cellRenderer: 'matchCellRenderer', sortable: true, filter: true, headerTooltip: 'Visual indicator for Data Matched.' }
  ];

  updateStatus(resultDetail: any, isActive: boolean)
  {
    this.updateBarcodeStatus(resultDetail, isActive);
  }

  updateBarcodeStatus(resultDetail: any, isActive: boolean) {
    this.resultDetailsService.updateBarcodeStatus(resultDetail.SampleDaysDataId, resultDetail.Barcode, isActive)
      .subscribe(result => {
        this.getResultDetails();
        this.toastr.success('Barcode updated successfully !!');
      },
        error => {
          this.toastr.error('Barcode update failed !!');
        })
  }

  updateAllBarcodeStatuses(isActive: boolean) {
    this.resultDetailsService.updateAllBarcodeStatuses(this.sampleDaysDataId, isActive)
      .subscribe(result => {
        this.getResultDetails();
        this.toastr.success('All Barcodes updated successfully !!');
      },
        error => {
          this.toastr.error('All Barcodes update failed !!');
        })
  }

  cancelStatusUpdate() {
    this.resultDetailsService.cancelStatusUpdate();
  }

  cancelStatusUpdateAll() {
    this.activeInactiveAll = !this.rowData.every(x => !x.IsActive);
    this.resultDetailsService.cancelStatusUpdate();

  }

  switchBarcodeResult(resultFileId) {
    this.resultDetailsService.switchBarcodeResult(this.sampleDaysDataId, resultFileId)
      .subscribe(result => {
        this.toastr.success('Barcode result updated successfully !!');
        this.getResultDetails();
      },
        error => {
          this.toastr.error('Barcode result update failed !!');
        })
  }

  refreshGrid(){
    this.getResultDetails();
    this.gridApi.refreshClientSideRowModel('aggregate')
    this.onCloseEvent();
  }

  onCloseEvent() {
    if(this.subscription !== undefined) {
      this.subscription.unsubscribe();
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    //this.gridApi.setDomLayout("autoHeight");
  }

  onGridSizeChanged(params: GridSizeChangedEvent) {
    params.api.sizeColumnsToFit();
  }

  

  onclickExperimentId()
  {
    this.router.navigateByUrl("/analyst-experiment-details?exp="+this.experimentDetails.GroupRequestId);
  }

  onclickViewQCData()
  {
    this.router.navigate([AppConstants.QCDATA, this.sampleDaysDataId]);
  }

  public onChange(event) {
    let dialogConfig = this.getDialogConfig();
    dialogConfig.data = { isActive: event.checked, componentName: this.componentName, subComponentName: this.subComponentName };
    let dialogRef = this.dialog.open(StatusDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
    status => {
        if (status) {
            this.updateAllBarcodeStatuses(event.checked);
        }     
        else {        
            this.cancelStatusUpdateAll();        
        }
    },
    error =>{
        console.log(error);
    },
    // () =>{
    //     this.refreshGrid();
    // }
    );
}

getDialogConfig() {
  const dialogConfig = new MatDialogConfig();
  dialogConfig.disableClose = true;
  dialogConfig.autoFocus = true;
  dialogConfig.width = "30%";
  return dialogConfig;

}


}
