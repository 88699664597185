import {Component, Inject, Output, EventEmitter} from '@angular/core';
import {MatDialogRef} from '@angular/material';
import { VesselService } from 'src/app/services/vessel-service/vessel.service';
import { ToastrService } from "ngx-toastr";
import { MessageService } from 'src/app/services/message-service';


@Component({
    selector: 'add-vessel',
    templateUrl: 'vessel-popup.component.html',
    styleUrls: ['./vessel.component.css']
  })

  
  export class VesselDialogComponent {
    isFormInvalid: boolean = false;
    clickEventTrigger: boolean = false;
    SampleNumberPerVessel:any;
    
    constructor(
        private service: VesselService,
        public dialogRef: MatDialogRef<VesselDialogComponent>, private toastr: ToastrService,private _messageService: MessageService) {}
  
        onNoClick(): void {
        this.dialogRef.close();
        }
        ngOnInit() {
            this.getSampleNumberPerVessel();
        }
        onClear() {
            this.service.form.reset();
            this.service.initializeFormGroup();
        }
  
        onSubmit() {
            this.checkValidRecord();
        }

        onKeyDown(event) {
            this.isFormInvalid = false;
        }
        getSampleNumberPerVessel()
        {
            this.service.getSampleNumberPerVessel()
            .subscribe(
                result => {
                    this.SampleNumberPerVessel=result;
                },
                error =>{
                    console.log(error);
                });

        }
        checkValidRecord() {
            let isValid: boolean = false;
            let model = this.service.form.value;
            this.service.getVessels()
            .subscribe(
                result => {
                    if (!result.some((vesselModel) => vesselModel.VesselName.trim().toLowerCase() == model.VesselName.trim().toLowerCase())) {
                        this.isFormInvalid = false;
                        isValid = true;
                    }
                    else {
                        let vessel = result.find(vessel => vessel.VesselId === model.VesselId);
                        if(vessel !== null && vessel != undefined) {                            
                            if(result.some((vesselModel) => vesselModel.VesselName.trim().toLowerCase() == model.VesselName.trim().toLowerCase()
                            && vesselModel.VesselId != model.VesselId))
                            {
                                this.isFormInvalid = true;
                                isValid = false;
                            }                            
                            else if(vessel.VesselName.trim() !== model.VesselName.trim()
                                || vessel.SampleNumberPerVessel !== model.SampleNumberPerVessel || vessel.Description.trim() !== model.Description.trim() ) {
                                this.isFormInvalid = false;
                                isValid = true;
                            }
                            else {
                                this.isFormInvalid = true;
                                isValid = false;
                            }
                        }
                        else {
                            this.isFormInvalid = true;
                            isValid = false;
                        }
                    }

                    if (this.service.form.valid && isValid) {
                        if (!this.service.form.get('VesselId').value) {
                                this.service.addVessel(this.service.form.value)
                                .subscribe(
                                    result =>{
                                        this._messageService.filter('refresh');
                                        this.toastr.success('Vessel created successfully !!');
                                    },
                                    error =>{
                                        this.toastr.error(error,'Vessel creation failed !!');
                                    }
                                );
                        }
                        else {
                                this.service.updateVessel(this.service.form.value)
                                .subscribe(
                                    result =>{
                                        this._messageService.filter('refresh');
                                        this.toastr.success('Vessel updated successfully !!');
                                    },
                                    error =>{
                                        this.toastr.error(error,'Vessel update failed !!');
                                    }
                                );
                        }
                        
                        this.onClose();
                    }
                    
                },
                error =>{
                    console.log(error);
                }); 
            return isValid;
        }
        // compareFn: ((f1: any, f2: any) => boolean) | null = this.compareByValue;
 
        // compareByValue(f1: any, f2: any) { 
        //     return f1 && f2 && f1.SampleNumberPerVessel === f2.MethodName; 
        // }
        
        onClose() {
            this.service.form.reset();
            this.service.initializeFormGroup();
            this.dialogRef.close();
        }
  }