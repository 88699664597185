import { Component, Inject, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { BroadcastService } from "@azure/msal-angular";
import { Subscription } from "rxjs/Subscription";
import { Router, RoutesRecognized  } from '@angular/router';
import { ConfigurationSettings } from './configuration-settings';
import { SecurityService} from './services/security.service';
import { AppConstants } from './common-utility/appconstants';
import { Settings } from './models/settings';
import { RoleGuardService } from './services/role-gaurd-service';
import { filter, pairwise } from 'rxjs/operators';
import { SettingService } from './services/setting.service';
import { CommonService } from './services/common/common.service';
import { UserClaim } from './models/userClaim';
import { LoadingService } from './services/loading.service';

import { Location } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [ConfigurationSettings, SecurityService, RoleGuardService]
})
export class AppComponent implements OnDestroy {

  private subscription: Subscription;
  public previousRouteURL = '';
  currentEnvironment = ConfigurationSettings.CURRENT_ENVIRONMENT_NAME;
  public isAdministrator: boolean;
  public isAnalyst: boolean;
  public isAuthorizedUser: boolean;
  public claims: UserClaim;

  constructor(@Inject(SettingService) private config: Settings,
    private broadcastService: BroadcastService,
    private router: Router,
    private location: Location,
    private loadingService: LoadingService,
    private commonService: CommonService,
    private cdr: ChangeDetectorRef) {
      broadcastService.subscribe('msal:loginSuccess', this.handleLoginSuccess);
      broadcastService.subscribe('msal:loginFailure', this.handleLoginFailure);
      broadcastService.subscribe('msal:acquireTokenFailure', this.handleAquireTokenFailure);
  }

  //To modify the Program Setup header text in form
  count: number = 0;
  ngAfterViewChecked() {
    if(this.count === 0){
        this.getPermissions();
        this.cdr.detectChanges();
    }
  }

  
  private handleLoginFailure = (payload) => {
    //console.log('msal:handleLoginFailure', payload);
  }

  private handleLoginSuccess = (payload) => {
    //console.log('msal:handleLoginSuccess', payload);
  };

  goToRequestPage(event) {
    event.preventDefault();

    this.router.navigateByUrl('/', {skipLocationChange: true})
      .then(() => this.router.navigate(['/group-request']));
  }

  goToImportFilesPage(event) {
    event.preventDefault();

    this.router.navigateByUrl('/', {skipLocationChange: true})
      .then(() => this.router.navigate(['/import-result-files']));
  }

  goToAssignFilesPage(event) {
    event.preventDefault();

    this.router.navigateByUrl('/', {skipLocationChange: true})
      .then(() => this.router.navigate(['/assign-files']));
  }

  private handleAquireTokenFailure = (payload) => {

    //console.log('msal:handleAquireTokenFailure', payload);

    if (payload.errorDesc === 'consent_required' || payload.errorDesc === 'login_required'
      || payload.errorDesc === 'Token Renewal Failed' || payload.errorDesc === 'User login is required') {
      // this.msalService.acquireTokenRedirect(["user.read"]);
      sessionStorage.removeItem(AppConstants.LOGGEDINUSERINFO);
      sessionStorage.removeItem(AppConstants.UIUSERROLE);
      this.getPermissions();
      // this.router.navigate([AppConstants.ONERINGLOGIN]);
    } else {
      // console.warn('acquire token failure', payload);
    }
  }

  
  //-------------------------------------------
  //To set the previous URL when route change.
  //-------------------------------------------
  public setPreviousRouteURL() {

    this.router.events
      .pipe(filter((e: any) => e instanceof RoutesRecognized),
        pairwise()
      ).subscribe((e: any) => {
        this.previousRouteURL = e[0].urlAfterRedirects;
        // console.log(e[0].urlAfterRedirects); // previous url
      });
  }

  public getPermissions() {
    //get the roles from session storage
    
    let rolesData = this.commonService.getSessionStorageValue(AppConstants.UIUSERROLE);

    if (rolesData !== '' && rolesData !== null) {
      this.claims = JSON.parse(rolesData) as UserClaim;

      if (this.claims.isOneRing_Analyst || this.claims.isOneRing_Requester) {
        this.isAuthorizedUser = true;
        this.count = 1; 
      }
      if (this.claims.isOneRing_Admin) {
        this.isAdministrator = this.claims.isOneRing_Admin
        this.count = 1;
      }
      if (this.claims.isOneRing_Analyst) {
        this.isAnalyst = this.claims.isOneRing_Analyst
        this.count = 1;
      }
      
      if (this.claims.isOneRing_Admin || this.claims.isOneRing_Analyst || this.claims.isOneRing_Requester) {
        if(this.location.path() === AppConstants.ONERINGLOGIN) {
          if(this.commonService.getSessionStorageValue('previousURL') != "" && this.commonService.getSessionStorageValue('previousURL') !== null) {
            let url = this.commonService.getSessionStorageValue('previousURL');
            this.router.navigateByUrl(url);
          }
          else {
            // window.open("#" + AppConstants.ONERINGHOME,"_self");
            this.router.navigate([AppConstants.ONERINGHOME]);
          }          
        }
      } else {
        this.router.navigate([AppConstants.UNAUTHORIZED]);
      }
    }
    else if(this.location.path() !== AppConstants.ONERINGLOGIN && this.commonService.getSessionStorageValue('previousURL') === null) {
      this.commonService.setSessionStorageValue('previousURL', this.location.path());
      this.router.navigate([AppConstants.ONERINGLOGIN]);
      this.count = 1;
    }
    // this.isAdministrator = this.roleGuardService.doesUserHaveRole('Administrator');
  }

  isHidden() {
    if(this.isAdministrator || this.isAnalyst) {
      return false;
    }
    else {
      return true;
    }
  }
  
  ngOnDestroy() {
    sessionStorage.removeItem(AppConstants.LOGGEDINUSERINFO);
    sessionStorage.removeItem(AppConstants.UIUSERROLE);
    this.broadcastService.getMSALSubject().next(1);
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
