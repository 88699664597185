import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { SlideCellRendererComponent } from '../../custom/slide-cell-renderer/slide-cell-renderer.component';
import { EditButtonRendererComponent } from '../../custom/edit-button-renderer/edit-button-renderer.component';
import {MatDialog} from '@angular/material/dialog';
import * as _ from 'lodash';
import { ExperimentTypeModel } from 'src/app/models/experiment-type.model';
import { AppConstants } from 'src/app/common-utility/appconstants';
import { ExperimentTypeService } from 'src/app/services/experiment-type-service/experiment-type.service';
import { ExperimentTypePopupComponent } from './experiment-type-popup.component';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from 'src/app/services/message-service';
import { GridSizeChangedEvent } from 'ag-grid-community';
import { DatePipe } from '@angular/common';

@Component({
    templateUrl: '../../shared/grid-component/grid.component.html',
    styleUrls: ['./experiment-type.component.css']
})

export class ExperimentTypeComponent implements OnInit {
    request: FormGroup;
    rowData: ExperimentTypeModel[] = [];
    private gridApi;
    private gridColumnApi;
    public context: any;
    public subscription: any;
    componentName = AppConstants.EXPERIMENTTYPE;
    componentTitle = AppConstants.EXPERIMENTTYPETITLE;

  constructor(private fb: FormBuilder, private dialog: MatDialog,
    private service: ExperimentTypeService,
    public datepipe: DatePipe,
    private toastr: ToastrService,
    private _messageService: MessageService) {
      this.context = { componentParent: this };
      this._messageService.listen().subscribe((m:any) => {
        this.refreshGrid();
      })
  }

  ngOnInit() {
    this.getExperimentTypeInfo();
  }

  getExperimentTypeInfo(){
    this.service.get()
      .subscribe(
        result => {
          this.rowData = [];
          result.forEach(r => {
            this.rowData.push({
              ExperimentTypeId: r.ExperimentTypeId,
              ExperimentTypeName: r.ExperimentTypeName,
              Description: r.Description,    
              IsActive: r.IsActive,
              CreatedDate: r.CreatedDate,
              CreatedBy: r.CreatedBy,
              ModifiedDate: this.datepipe.transform(r.ModifiedDate,'MM/dd/yyyy'),
              ModifiedBy: r.ModifiedBy
            })
          })
        },
        error =>{
          console.log(error);
        }
      ) 
  }

  defaultColDef: {
    flex: 1,    
    enableValue: true,    
    enableRowGroup: true,
    enablePivot: true,
    sortable: true,
    filter: true,
  }
  
  frameworkComponents = {
    slideCellRenderer: SlideCellRendererComponent,
    btnEditRenderer: EditButtonRendererComponent
  };

  columnDefs = [
    {headerName: 'Name', field: 'ExperimentTypeName', sortable: true, filter: true, width: 200, tooltipValueGetter: (params) => params.value },   
    {headerName: 'Description', field: 'Description', sortable: true, filter: true, width: 350, tooltipValueGetter: (params) => params.value}, 
    {headerName: 'Modified By', field:'ModifiedBy', sortable: true, filter: true, width: 200, tooltipValueGetter: (params) => params.value},
    {headerName: 'Modified Date', field:'ModifiedDate', sortable: true, filter: true, width: 200 },
    {headerName: 'Active', field: 'IsActive', cellRenderer: 'slideCellRenderer', sortable: true, filter: true, width: 150},
    {
      field: '',
      cellRenderer: 'btnEditRenderer',
      tooltipValueGetter: (params) => 'Edit'
    },
  ];

  updateStatus(experimentTypeModel: ExperimentTypeModel, isActive: boolean) {
    this.service.updateStatus(experimentTypeModel.ExperimentTypeId, isActive)
      .subscribe(result => {
        this.getExperimentTypeInfo();
        this.toastr.success('Experiment Type updated successfully !!');
      },
        error => {
          this.toastr.error('Experiment Type update failed !!');
        })
  }

  add(){
    this.showDialog();
  }

  update(row){
    if(row.colDef != undefined && row.colDef !== '' && row.colDef !== null && row.colDef.field == '') {
      this.service.populateForm(row.data);
      this.showDialog();
    }
  }

  showDialog() {
      let dialogConfig = this.service.getDialogConfig();      
      dialogConfig.data = { componentName: this.componentName, componentId: "ExperimentTypeId", controlName: "ExperimentTypeName", popupHeight: "300px" };
      this.dialog.open(ExperimentTypePopupComponent,dialogConfig);
  }

  refreshGrid(){
    this.getExperimentTypeInfo();
    this.gridApi.refreshClientSideRowModel('aggregate')
    this.onCloseEvent();
  }

  cancelStatusUpdate() {
    this.service.cancelStatusUpdate();
  }

  onCloseEvent() {
    if(this.subscription !== undefined) {
      this.subscription.unsubscribe();
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.setDomLayout("autoHeight");
  }

  onGridSizeChanged(params: GridSizeChangedEvent) {
    params.api.sizeColumnsToFit();
  }
}