import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';



@Component({
  selector: 'app-dashboard-alert-dialog',
  templateUrl: './dashboard-alert-dialog.component.html',
   styles: [`
  .float-right {
    display: table-cell;
    width: 180px;
    margin: 8px 8px 8px 0;
    float: right;
  }
.btn-margin{
  margin-right: 10px; 
  margin-left: 123px;
}
`],
})
export class DashboardAlertDialogComponent  {

constructor(@Inject(MAT_DIALOG_DATA) private data: any,  public dialogRef: MatDialogRef<DashboardAlertDialogComponent>,private router: Router) { 
    
    
  }
  public navigateToRequest(): void {
   this.router.navigateByUrl("/group-request?exp="+this.data["experiment"]);

  }

}
