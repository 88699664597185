
import { Component, OnInit, Inject, LOCALE_ID } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import { AppConstants } from 'src/app/common-utility/appconstants';
import { DashboardUIModel } from 'src/app/models/dashboard.model';
import { RequestService } from '../services/request-service/request.service';
import { LinkRendererComponent } from 'src/app/custom/link-renderer/link-renderer.component';
import { CommonService } from '../services/common/common.service';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from 'src/app/services/message-service';
import { GridSizeChangedEvent } from 'ag-grid-community';
import { DatePipe } from '@angular/common';
import { GridOptions } from 'ag-grid-community';
import { formatDate } from '@angular/common';



@Component({
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],

})


export class DashboardComponent implements OnInit {
  request: FormGroup;
  resultData: any;
  rowData: Array<DashboardUIModel> = [];
  private gridApi;
  private gridColumnApi;
  public gridOptions: GridOptions;
  private rowClassRules;
  public context: any;
  public subscription: any;
  public savedSort : any;
  public savedFilter : any;
  public dashboardUIdata: DashboardUIModel[] = [];
  componentTitle = AppConstants.DASHBOARD;
  public showallrecords: boolean = false;
  labelPosition = 'before';
  private dbgroupname:string="";
  public sortModel = [
    { colId: 'SampleDate', sort: 'asc' }
  ];

  constructor(private dialog: MatDialog,
    private toastr: ToastrService,
    public datepipe: DatePipe,
    private service: RequestService,
    private _messageService: MessageService,
    private commonService: CommonService, @Inject(LOCALE_ID) private locale: string) {
    this.context = { componentParent: this };
    this._messageService.listen().subscribe((m: any) => {
      this.showallrecords=this.commonService.getshowAllRecordsStatus();
      this.refreshGrid(this.showallrecords);
    })
  };
  frameworkComponents = {
    linkRenderer: LinkRendererComponent,

  };

  filterParams = {
    comparator: function (filterLocalDateAtMidnight, cellValue) {
      var cellDate = new Date(cellValue);
      cellDate.setHours(0, 0, 0, 0);
      if (cellDate == null) return -1;
  
      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }
  
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
  
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
    }
  };

  defaultColDef: {
    flex: 1,
    enableValue: true,
    enableRowGroup: true,
    enablePivot: true,
    sortable: true,
    filter: true,
  }
  columnDefs = [
    {
      headerName: "Group Request Id",
      field: "GroupRequestId",
      width: 10,
      hide: true,
      suppressColumnsToolPanel: true
    },
    { headerName: 'Group', field: 'Group', sortable: true, filter: true, width: 200, tooltipValueGetter: (params) => params.value },
    {
      headerName: 'Experiment ID', field: 'ExperimentId', sortable: true, filter: true, width: 300, tooltipValueGetter: (params) => params.value,
      cellRenderer: 'linkRenderer'
    },
    { headerName: 'Start Date', field: 'ExperimentStartDate', sortable: true, width: 200, filter: 'agDateColumnFilter', filterParams: this.filterParams, 
      cellRenderer: (data) => { return this.dateFormatter(data); } },
    { headerName: 'Sample Date', field: 'SampleDate', sortable: true, width: 200, filter: 'agDateColumnFilter', filterParams: this.filterParams,
      cellRenderer: (data) => { return this.dateFormatter(data); } },
    { headerName: 'Sample Day', field: 'SampleDay', sortable: true, filter: true, width: 200, tooltipValueGetter: (params) => params.value },
    { headerName: 'Total Vessels Estimate', field: 'VesselEstimate', sortable: true, filter: true, width: 200, tooltipValueGetter: (params) => params.value },
    { headerName: 'Total Vessels Submitted', field: 'SampleVesselCount', sortable: true, filter: true, width: 200, tooltipValueGetter: (params) => params.value },
    { headerName: 'Method', field: 'Method', suppressSizeToFit: false, sortable: true, filter: true, width: 200, tooltipValueGetter: (params) => params.value },
    { headerName: 'Analyst', field: 'Analyst', width: 200, sortable: true, filter: true, tooltipValueGetter: (params) => params.value },
    { headerName: 'Method Status', field: 'Status', sortable: true, filter: true, width: 200, tooltipValueGetter: (params) => params.value },
    { headerName: 'Experiment Status', field: 'ExperimentStatus', sortable: true, filter: true, width: 300, tooltipValueGetter: (params) => params.value }
  ];

  private result: DashboardUIModel[];
  ngOnInit() {
    this.showallrecords=this.commonService.getshowAllRecordsStatus();  
    this.getDashboardInfo(this.showallrecords);
    this.gridOptions = {
      getRowStyle: this.getRowStyleScheduled
    }
    this.dbgroupname=this.commonService.getsGroupName();
  }

  dateFormatter(data) {
    return data.value ? formatDate(data.value, 'MM/dd/yyyy', this.locale) : '';
  }
  getRowStyleScheduled(params) {
    let turnAroundDate = new Date(params.data.SampleDate);
    let turnAroundTimeInDays = parseInt(params.data.TurnAroundTimeInDays);
    turnAroundDate.setDate(turnAroundDate.getDate() + turnAroundTimeInDays);
    turnAroundDate.setHours(0, 0, 0, 0);

    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    if ((turnAroundDate < currentDate)
      && (params.data.Status == AppConstants.New || params.data.Status == AppConstants.InProgressUI) && (params.data.ExperimentStatus!= AppConstants.Cancel)) {
      return {
        'background-color': 'rgb(224, 65, 65)',
      }
    }    
    if (params.data.Status == AppConstants.Cancel || params.data.ExperimentStatus== AppConstants.Cancel)
    {
      return {
        'background-color': 'rgb(128,128,128)',
      }
    }
    return null;

  };

  getDashboardInfo(showallrecords) {
    this.dashboardUIdata = [];
    this.service.getDashboard(showallrecords)
      .subscribe(
        result => {
          this.resultData = result;
          
          result.forEach(r => {
            for (let j = 0; j < r.Rows.length; j++) {
              for (let i = 0; i < r.Rows[j].Methods.length; i++) {
                this.dashboardUIdata.push({
                  GroupRequestId: r.GroupRequestId,
                  VesselEstimate: r.TotalVesselsSubmitted,
                  ExperimentStartDate: r.ExperimentStartDate,
                  Group: r.GroupType.GroupName,
                  Status: r.Rows[j].Methods[i].MethodStatus === AppConstants.InProgress ? AppConstants.InProgressUI : r.Rows[j].Methods[i].MethodStatus,
                  ExperimentStatus:r.ExperimentStatus === AppConstants.InProgress ? AppConstants.InProgressUI : r.ExperimentStatus,
                  ExperimentId: r.ExperimentId,
                  SampleDay: "Day " + r.Rows[j].Day,
                  SampleVesselCount: r.SampleVesselCount,
                  Method: r.Rows[j].Methods[i].MethodName,
                  Analyst: r.Rows[j].Methods[i].DefaultAnalyst === null ? '' : r.Rows[j].Methods[i].DefaultAnalyst.AnalystName,
                  SampleDate: r.Rows[j].SampleDate,
                  TurnAroundTimeInDays: r.Rows[j].Methods[i].TurnAroundTimeInDays
                })
              }
            }
          });

          if (showallrecords == true) {
            this.commonService.setShowAllRecords(showallrecords);
            this.gridApi.setRowData([]);
            this.gridApi.applyTransaction({ add: this.dashboardUIdata });

          }
          this.rowData = this.dashboardUIdata;
      
          this.savedFilter = this.commonService.getAnalystsStorageValue(AppConstants.SAVEDGRIDFILTER);
      
          this.savedSort = this.commonService.getAnalystsStorageValue(AppConstants.SAVEDGRIDSORT);
          
          this.result = _(this.rowData)
            .groupBy('Group')
            .map((array, key) => ({
              "GroupName": key,
              "GroupCount": array.length

            }))
            .value();

            this.dbgroupname=this.commonService.getsGroupName();    
            if(this.dbgroupname!=undefined && this.dbgroupname!="")
            {  
            this.rowData = this.rowData.filter(result => {
              return (result.Group === this.dbgroupname);
            });
            }
        },
        error => {
          console.log(error);
        }

      )

  }

  refreshGrid(showallrecords) {
    this.getDashboardInfo(this.showallrecords);
    this.gridApi.refreshClientSideRowModel('aggregate');
    this.clearSortandFilter();
    this.onCloseEvent();
  }

  cancelStatusUpdate() {
    this.service.cancelStatusUpdate();
  }

  public onShowAllRecordsChange(event) {
    this.showallrecords = event.checked;
    this.commonService.setShowAllRecords(this.showallrecords);  
    this.getDashboardInfo(this.showallrecords);
  }

  filterGroup(groupname: any) {
    this.dbgroupname=groupname;       
    this.commonService.setGroupName(this.dbgroupname);
    this.rowData = this.dashboardUIdata;
    this.gridApi.refreshClientSideRowModel('aggregate');
    this.rowData = this.rowData.filter(result => {
      return (result.Group === groupname);

    });

  }

  onCloseEvent() {
    if (this.subscription !== undefined) {
      this.subscription.unsubscribe();
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;

    this.gridColumnApi = params.columnApi; 
  }

  onGridSizeChanged(params: GridSizeChangedEvent) {
    params.api.sizeColumnsToFit();
  }

  onFirstDataRender(params) {
    
    
    if(this.savedSort == undefined || this.savedSort == null || this.savedSort.length == 0) {
      this.gridApi.setSortModel(this.sortModel);
    }   
    else {
      params.api.setSortModel(this.savedSort);
      this.gridApi.setSortModel(this.savedSort);
    }
    
    params.api.setFilterModel(this.savedFilter);
    
    this.gridApi.setFilterModel(this.savedFilter);
  }

  clearSortandFilter() {
    this.commonService.setGroupName("");
    this.gridApi.setSortModel(null);
    this.commonService.setSessionStorageValue(AppConstants.SAVEDGRIDSORT, JSON.stringify(this.gridApi.getSortModel()));
    this.gridApi.setFilterModel(null);
    this.commonService.setSessionStorageValue(AppConstants.SAVEDGRIDFILTER, JSON.stringify(this.gridApi.getFilterModel()));
    this.gridApi.setSortModel(this.sortModel);
  }

  onSortChanged(params) {
    this.savedSort = this.gridApi.getSortModel();
    this.commonService.setSessionStorageValue(AppConstants.SAVEDGRIDSORT, JSON.stringify(this.savedSort));
  }

  onFilterChanged(params) {
    this.savedFilter = this.gridApi.getFilterModel();
    let dashboardUi: DashboardUIModel[] = [];
    this.gridApi.context.beanWrappers.rowModel.beanInstance.rowsToDisplay.forEach(element => {
      dashboardUi.push(element.data);
    });
    this.commonService.setSessionStorageValue(AppConstants.SAVEDGRIDFILTER, JSON.stringify(this.savedFilter));
    this.result = _(dashboardUi)
    .groupBy('Group')
    .map((array, key) => ({
      "GroupName": key,
      "GroupCount": array.length

    }))
    .value();
  }
}