import { Component,Input, OnInit } from '@angular/core';
import { PARAMETERS } from '@angular/core/src/util/decorators';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { CommonService } from 'src/app/services/common/common.service';
import { UserClaim } from 'src/app/models/userClaim';
import { AppConstants } from 'src/app/common-utility/appconstants';
import { style } from '@angular/animations';
//import { DashboardAlertComponent } from 'src/app/custom/dashboard-alert/dashboard-alert.component';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
//import { DateAlertDialogComponent } from 'src/app/group-request/group-alerts/date-alert-dialog.component';
import { DashboardAlertDialogComponent } from 'src/app/custom/link-renderer/dashboard-alert-dialog/dashboard-alert-dialog.component';
import { AnalystExperimentDetailsService } from 'src/app/services/analyst-experiment-details-service/analyst-experiment-details.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-link-renderer',
  template: ` <button pButton type="button" icon="{{params.value}}" iconPos="left" (click)="onClick($event)">{{params.value}}</button>`,
  styles:['button {background: none!important; border: none;padding: 0!important;font-family: arial, sans-serif;color: #069;text-decoration: underline;cursor: pointer;}button:hover{color:black !important;}']
  
})
export class LinkRendererComponent implements OnInit {

  data: any;
  params: any;
  label: string;
  claims: UserClaim;  


  //constructor(private commonService: CommonService,public dialog: MatDialog,public ExperimentDetailsService:AnalystExperimentDetailsService) {}  
  constructor(private commonService: CommonService,public dialog: MatDialog, private router: Router) {}  
 // sampleCount:string;
  agInit(params) {
    this.params = params;  
    this.label = this.params.label || null;    
    this.data=this.params.data;    
  }

  ngOnInit() {
   // this.ExperimentDetailsService.currentMessage.subscribe(Count => this.sampleCount = Count)
    
  }

  getDialogConfig() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "30%";
    return dialogConfig;

  }
  // newMessage(SampleCount:string) {
  //   this.ExperimentDetailsService.changeMessage(SampleCount)
  // }
  onClick($event) {
    let claims = new UserClaim();
    let rolesData = this.commonService.getSessionStorageValue(AppConstants.UIUSERROLE);
    //this.newMessage(this.data.SampleCount);
    if (rolesData !== '' && rolesData !== null) {
        this.claims = JSON.parse(rolesData) as UserClaim;

        if((this.claims.isOneRing_Analyst || this.claims.isOneRing_Admin)&& !this.claims.isOneRing_Requester) 
        {          
          this.router.navigateByUrl("/analyst-experiment-details?exp="+this.data.GroupRequestId);
        }   

        else if(this.claims.isOneRing_Requester && !(this.claims.isOneRing_Analyst || this.claims.isOneRing_Admin))
        {                       
          this.router.navigateByUrl("/group-request?exp="+this.data.GroupRequestId);
        }
        else if((this.claims.isOneRing_Analyst || this.claims.isOneRing_Admin) && this.claims.isOneRing_Requester){
                  
          this.dialog.open(DashboardAlertDialogComponent, {
            data: {
              experiment: this.data.GroupRequestId
            }
          });         

        }
    }
      //alert();
      //window.open("https://www.google.com","_self");
    
  }

}
