import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { SlideCellRendererComponent } from '../../custom/slide-cell-renderer/slide-cell-renderer.component';
import { EditButtonRendererComponent } from '../../custom/edit-button-renderer/edit-button-renderer.component';
import {MatDialog} from '@angular/material/dialog';
import * as _ from 'lodash';
import { UserFriendlyHeaderModel } from 'src/app/models/user-friendly-header.model';
import { AppConstants } from 'src/app/common-utility/appconstants';
import { UserFriendlyHeadersService } from 'src/app/services/user-friendly-headers-service/user-friendly-headers.service';
import { UserFriendlyHeadersPopupComponent } from './user-friendly-headers-popup.component';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from 'src/app/services/message-service';
import { GridSizeChangedEvent } from 'ag-grid-community';
import { DatePipe } from '@angular/common';
import { convertToParamMap } from '@angular/router';
import { UserFriendlyHeadersValidationPopupAlert } from './user-friendly-headers_alerts/user-friendly-headers-validation-popup-alert.component';

@Component({
    templateUrl: '../../shared/grid-component/grid.component.html',
    styleUrls: ['./user-friendly-headers.component.css']
})

export class UserFriendlyHeadersComponent implements OnInit {
    request: FormGroup;
    rowData: UserFriendlyHeaderModel[] = [];
    private gridApi;
    private gridColumnApi;
    public context: any;
    public subscription: any;
    componentName = AppConstants.USERFRIENDLYHEADER;
    componentTitle = AppConstants.USERFRIENDLYHEADERTITLE; 
    
  constructor(private fb: FormBuilder, private dialog: MatDialog,
    private service: UserFriendlyHeadersService,
    public datepipe: DatePipe,
    private toastr: ToastrService,
    private _messageService: MessageService) {
      this.context = { componentParent: this };
      this._messageService.listen().subscribe((m:any) => {
        this.refreshGrid();
      })
  }

  ngOnInit() {
    this.getUserFriendlyHeadersInfo();
  }

  getUserFriendlyHeadersInfo(){   
    this.service.get()
      .subscribe(
        result => {
          this.rowData = [];
          result.forEach(r => {
            this.rowData.push({
              UserFriendlyHeaderId: r.UserFriendlyHeaderId,
              UserFriendlyHeaderName: r.UserFriendlyHeaderName,
              Description: r.Description,    
              IsActive: r.IsActive,
              CreatedDate: r.CreatedDate,
              CreatedBy: r.CreatedBy,
              ModifiedDate: this.datepipe.transform(r.ModifiedDate,'MM/dd/yyyy'),
              ModifiedBy: r.ModifiedBy,
              show: true
            })
          })

        },
        error =>{
          console.log(error);
        }
      ) 
  }

  defaultColDef: {
    flex: 1,    
    enableValue: true,    
    enableRowGroup: true,
    enablePivot: true,
    sortable: true,
    filter: true,
  }
  
  frameworkComponents = {
    slideCellRenderer: SlideCellRendererComponent,
    btnEditRenderer: EditButtonRendererComponent
  };

  columnDefs = [
    {headerName: 'Name', field: 'UserFriendlyHeaderName', sortable: true, filter: true, width: 200, tooltipValueGetter: (params) => params.value },   
    {headerName: 'Description', field: 'Description', sortable: true, filter: true, width: 350, tooltipValueGetter: (params) => params.value}, 
    {headerName: 'Modified By', field:'ModifiedBy', sortable: true, filter: true, width: 200, tooltipValueGetter: (params) => params.value},
    {headerName: 'Modified Date', field:'ModifiedDate', sortable: true, filter: true, width: 200 },
    {headerName: 'Active', field: 'IsActive', cellRenderer: 'slideCellRenderer', sortable: true, filter: true, width: 150},
    {      
      field: '',
      cellRenderer: 'btnEditRenderer',
      tooltipValueGetter: (params) => 'Edit'
    },
  ];

  updateStatus(userFriendlyHeaderModel: UserFriendlyHeaderModel, isActive: boolean) {
    if(this.unEditValidation(userFriendlyHeaderModel))
        {
      this.service.updateStatus(userFriendlyHeaderModel.UserFriendlyHeaderId, isActive)
        .subscribe(result => {
          this.getUserFriendlyHeadersInfo();
          this.toastr.success('User Friendly Header updated successfully !!');
      },
        error => {
          this.toastr.error('User Friendly Header update failed !!');
        })
      }
  }

  add(){
    this.showDialog();
  }

  update(row){
    if(row.colDef != undefined && row.colDef !== '' && row.colDef !== null && row.colDef.field == '') {
     
      if(this.unEditValidation(row.data))
        { 
          this.service.populateForm(row.data);    
          this.showDialog();
        }
    }
  }
 unEditValidation(row)
 {
  let isEditable=true;
  if(row.UserFriendlyHeaderName==AppConstants.Date ||row.UserFriendlyHeaderName==AppConstants.Day || 
    row.UserFriendlyHeaderName==AppConstants.ExperimentID||
    row.UserFriendlyHeaderName==AppConstants.Barcode||row.UserFriendlyHeaderName==AppConstants.Well||
    row.UserFriendlyHeaderName==AppConstants.Instrument||row.UserFriendlyHeaderName==AppConstants.Date||
    row.UserFriendlyHeaderName==AppConstants.Time)
    { 
      isEditable=false;         
     let dialogConfig = this.service.getAlertDialogConfig(); 
     this.dialog.open(UserFriendlyHeadersValidationPopupAlert, dialogConfig);  
    }
    return isEditable;
 }
  showDialog() {
   
      let dialogConfig = this.service.getDialogConfig();      
      dialogConfig.data = { componentName: this.componentName, componentId: "UserFriendlyHeaderId", controlName: "UserFriendlyHeaderName", popupHeight: "300px" };
      this.dialog.open(UserFriendlyHeadersPopupComponent,dialogConfig);
  }

  refreshGrid(){
    this.getUserFriendlyHeadersInfo();
    this.gridApi.refreshClientSideRowModel('aggregate')
    this.onCloseEvent();
  }

  cancelStatusUpdate() {
    this.service.cancelStatusUpdate();
  }

  onCloseEvent() {
    if(this.subscription !== undefined) {
      this.subscription.unsubscribe();
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.setDomLayout("autoHeight");
  }

  onGridSizeChanged(params: GridSizeChangedEvent) {
    params.api.sizeColumnsToFit();
  }
}