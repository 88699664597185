import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UploadEvent, UploadFile, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { SortByPipe } from 'src/app/shared/sort/sort-pipe';
import { ResultFilesService } from 'src/app/services/result-files-service/result-files-service';
import { startWith, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { FormControl } from "@angular/forms";
import { RequestService } from 'src/app/services/request-service/request.service';
import { FileMappingService } from 'src/app/services/file-mapping-service/file-mapping.service';
import { FileMappingModel } from 'src/app/models/file-mapping.model';

@Component({
  selector: 'app-import-results-file-manually',
  templateUrl: './import-results-file-manually.component.html',
  styleUrls: ['./import-results-file-manually.component.scss']
})
export class ImportResultsFileManuallyComponent implements OnInit {

  constructor(private service: ResultFilesService,
    private requestService: RequestService,
    private toastr: ToastrService,
    private fileMappingService: FileMappingService,
    private sortPipe: SortByPipe) { }

  failedFiles: Array<any> = [];
  successFiles: Array<any> = [];
  isFilesProcessed: boolean = false;
  fileCount: number = 0;
  allowedExtensions = ['xls', 'xlsx'];
  foundInvalidFiles: boolean = false;
  filename = '';
  dropFiles: UploadFile[] = [];
  browseFiles: File[] = [];
  methodsList: FileMappingModel[] = [];
  filteredMethodOptions: Observable<Array<FileMappingModel>>;
  selectedMethodValue: any;

  methodFilterControl = new FormControl();
  isValidAnalyst: boolean = false;
  isAnalystMethodExecuted: boolean = false;

  ngOnInit() {
    this.isUserValidAnalyst();
    this.populateMethodsData();

  }

  populateMethodsData() {

    this.fileMappingService.get()
      .subscribe(async result => {
        this.methodsList = result.filter(r => r.IsActive === true);
        this.methodsList.forEach(option => {
          option.MethodName = option.MethodName + '-' + option.Vessel;
        });
        this.methodsList = this.sortPipe.transform(this.methodsList, 'asc', 'MethodName');

        await new Promise<void>((resolve, reject) => {

          this.filteredMethodOptions = this.methodFilterControl.valueChanges.pipe(
            startWith(""),
            map((value: string) => {
              this.methodsList.forEach(option => {
                option.show = option.MethodName
                  .toLocaleLowerCase()
                  .includes(value.toLowerCase());
              });
              return this.methodsList;
            })
          )
          resolve();
        });
      },
        error => {
          console.log(error);
        }
      );
  }

  isUserValidAnalyst() {

    this.requestService.getAnalystByName()
      .subscribe(async result => {
        this.isAnalystMethodExecuted = true;
        this.isValidAnalyst = result != null;
      },
        error => {
          console.log(error);
        }
      );
  }

  editMethodsSelection(event) {
    //event.value
  }

  compareFn: ((f1: any, f2: any) => boolean) | null = this.compareByValue;

  compareByValue(f1: any, f2: any) {
    return f1 && f2 && f1.MethodName === f2.MethodName;
  }

  //Executes when user browse the files
  onFileBrowsed(files) {
    if (this.isValidAnalyst) {
      this.browseFiles = files;
      this.processFiles(this.browseFiles);
    }

  }

  //Executes when user drag and drop the files
  onFilesDroped(event: UploadEvent) {
    if (this.isValidAnalyst) {
      this.dropFiles = event.files;
      this.failedFiles = [];
      this.successFiles = [];
      if (this.dropFiles.length > 0) {
        this.foundInvalidFiles = false;
        for (const droppedFile of this.dropFiles) {
          if (!this.isValidFile(droppedFile.relativePath)) {
            this.foundInvalidFiles = true;
            break;
          }
        }
        if (!this.foundInvalidFiles) {
          for (const droppedFile of this.dropFiles) {
            if (droppedFile.fileEntry.isFile) {
              const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
              fileEntry.file((file: File) => {
                this.uploadSlectedFile(file);
              });
            }
          }
          this.isFilesProcessed = true;
          this.fileCount = this.dropFiles.length;
        }

      }
      else {
        this.toastr.error('Please select atleast one file !!');
      }
    }


  }

  //It uploads the single file to the server
  uploadSlectedFile(file) {
    const formData = new FormData();
    console.log(this.selectedMethodValue);
    formData.append(this.selectedMethodValue.ColumnMappingRuleId + "/" + file.name, file);
    this.service.UploadFile(formData)
      .subscribe(
        result => {
          this.successFiles.push(file.name);
        },
        error => {
          this.failedFiles.push(file.name);
        }
      );
  }

  //It will process and upload the files when user browse the files
  processFiles(files) {
    this.failedFiles = [];
    this.successFiles = [];
    if (files.length > 0) {
      this.foundInvalidFiles = false;
      for (const droppedFile of files) {
        if (!this.isValidFile(droppedFile.name)) {
          this.foundInvalidFiles = true;
          break;
        }
      }
      if (!this.foundInvalidFiles) {
        for (const droppedFile of files) {
          this.uploadSlectedFile(droppedFile);
        }
        this.isFilesProcessed = true;
        this.fileCount = files.length;
      }

    }
    else {
      this.toastr.error('Please select atleast one file !!');
    }
  }


  //It will validate the file
  isValidFile(name: String) {
    var fileExtension = name.substring(name.lastIndexOf('.') + 1).toLowerCase();
    if (this.allowedExtensions.indexOf(fileExtension) !== -1) {
      return true;
    }
    else {
      return false;
    }
  }

}
