import { Inject, Injectable } from '@angular/core';
import { MsalConfig, MsalService } from '@azure/msal-angular';
import { MSAL_CONFIG } from '@azure/msal-angular/dist/msal.service';
import { IJwtTokenRetriever } from '@corteva-research/ngx-support-bot';

@Injectable()
export class JwtTokenServiceService implements IJwtTokenRetriever {
  public constructor(
    @Inject(MSAL_CONFIG) private msalConfig: MsalConfig,
    private msalService: MsalService
  ) { }

  public get jwtToken(): string {
    return this.msalService.getCachedTokenInternal([this.msalConfig.clientID]).token;
  }
}