import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { ConfigurationSettings } from "../../configuration-settings"
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs-compat/BehaviorSubject';
import { ProjectModel } from 'src/app/models/project.model';
import { MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs-compat/Observable';
import { LoadingService } from '../loading.service';
import { GroupRequestModel } from 'src/app/models/group-request.model';
import { MethodModel } from 'src/app/models/method.model';
import { ExperimentTypeModel } from 'src/app/models/experiment-type.model';
import { map,filter, catchError, finalize } from 'rxjs/operators';
import { throwError} from 'rxjs';

@Injectable({
    providedIn: 'root'
  })
export class RequestService {
  private messageSource;
  // currentMessage = this.messageSource.asObservable();
  
    httpOptions = {
        headers: new HttpHeaders({ 
            'Content-Type': 'application/json' 
        })
    };

    constructor(private http: HttpClient, 
                private fb: FormBuilder,
                private loadingService: LoadingService) {

                 }

    form: FormGroup = new FormGroup({
        GroupRequestId : new FormControl(null),
        //Project : new FormControl(null, Validators.required),
        Project : new FormControl(ProjectModel[''], Validators.required),
        GroupType : new FormControl(null, Validators.required),
        ExperimentType : new FormControl(ExperimentTypeModel[''], null),
        ExperimentId : new FormControl('', Validators.required),
        ExperimentStartDate : new FormControl(null),
        AnalyticalMethods : new FormControl(MethodModel[''],Validators.required),
        ExperimentPurpose : new FormControl(''),
        SpecialInstructions : new FormControl(''),
        Rows: this.fb.array([this.initRows()]),
        AnalysisFrequency : new FormControl(null, Validators.required),
        Vessel : new FormControl(null, Validators.required),
        TotalVesselsSubmitted : new FormControl(null),
        IsActive: new FormControl(true),
        RequestorEmail: new FormControl(''),
        EmailId:new FormControl('')
        
      });

      initializeFormGroup() {
        this.form.setValue({
          GroupRequestId : null,
          Project : null,
          GroupType : null,
          ExperimentType : null,
          ExperimentId : '',
          ExperimentStartDate : null,
          AnalyticalMethods : null,
          ExperimentPurpose :'',
          SpecialInstructions : '',
          Rows: [{Day:0, Active:false, MethodsCount:'', Methods: []}],
          AnalysisFrequency : '',
          Vessel : null,
          TotalVesselsSubmitted : '',
          IsActive: 1,
          RequestorEmail: '',
          EmailId:''
          
        });
      }

      initRows(day?) {
        return this.fb.group({
          Day: day,
          Active: false,
          MethodsCount: '',
          Methods:[""]
        });
      }

    public addRequest(model):  Observable<any> {
      this.loadingService.setMessage('Adding Group Request...');
      this.messageSource = new BehaviorSubject(model);
      let url = ConfigurationSettings.REST_API_URL + "/GroupRequest/insert";

      let body = JSON.stringify(model);

      return this.http.post(
          url,
          body, this.httpOptions)
          .pipe(
                map(result => {
                  return result;
                }), 
                catchError((error: HttpErrorResponse) => {
                  console.log(error);
                  return throwError(error);
                }),
                finalize(() => {
                  this.loadingService.clearMessage();
                })
            );
    }
    public updateRequest(model):  Observable<any> {
      this.loadingService.setMessage('Updating Group Request...');
      this.messageSource = new BehaviorSubject(model);
      let url = ConfigurationSettings.REST_API_URL + "/GroupRequest/update";

      let body = JSON.stringify(model);

      return this.http.put(
          url,
          body, this.httpOptions)
          .pipe(
                map(result => {
                  return result;
                }), 
                catchError((error: HttpErrorResponse) => {
                  console.log(error);
                  return throwError(error);
                }),
                finalize(() => {
                  this.loadingService.clearMessage();
                })
            );
    }
    public getDashboard(showallrecords: boolean): Observable<any> {
      this.loadingService.setMessage('Loading Dashboard...');
      let url = ConfigurationSettings.REST_API_URL + "/GroupRequest/getRequests?showallrecords="+ showallrecords;
      
      return this.http.get(url)
      .pipe(
            map(result => {
              return result;
            }), 
            catchError((error: HttpErrorResponse) => {
              console.log(error);
              return throwError(error);
            }),
            finalize(() => {
              this.loadingService.clearMessage();
            })
        );
          
    }

    public getActiveRequests(): Observable<any> {
      this.loadingService.setMessage('Loading Experiments...');
      let url = ConfigurationSettings.REST_API_URL + "/GroupRequest/getActiveRequests";
      
      return this.http.get(url)
          .pipe(
            map(result => {
              return result;
            }), 
            catchError((error: HttpErrorResponse) => {
              console.log(error);
              return throwError(error);
            }),
            finalize(() => {
              this.loadingService.clearMessage();
            })
          );
          
    }

    public hasStrainInformation(requestId: number): Observable<any> {
      this.loadingService.setMessage('Getting Sample Information...');
      let url = ConfigurationSettings.REST_API_URL + "/GroupRequest/HasStrainInformation?groupRequestId=" + requestId;
      
      return this.http.get(url)
          .pipe(
            map(result => {
              return result;
            }), 
            catchError((error: HttpErrorResponse) => {
              console.log(error);
              return throwError(error);
            }),
            finalize(() => {
              this.loadingService.clearMessage();
            })
          );
          
    }
    
    public getRequest(showallrecords: boolean): Observable<any> {
      this.loadingService.setMessage('Loading Request...');
      let url = ConfigurationSettings.REST_API_URL + "/GroupRequest/getRequests?showallrecords="+ showallrecords;
      
      return this.http.get(url)   
          .pipe(
            map(result => {
              return result;
            }), 
            catchError((error: HttpErrorResponse) => {
              console.log(error);
              return throwError(error);
            }),
            finalize(() => {
              this.loadingService.clearMessage();
            })
          );
          
    }
    
    public getRequestDetail(requestNumber: number): Observable<any> {
      this.loadingService.setMessage('Loading Request Details...');
      let url = ConfigurationSettings.REST_API_URL + "/GroupRequest/getGroupRequestDetails?requestNumber=" + requestNumber;

      return this.http.get(url)
          .pipe(
            map(result => {
              return result;
            }), 
            catchError((error: HttpErrorResponse) => {
              console.log(error);
              return throwError(error);
            }),
            finalize(() => {
              this.loadingService.clearMessage();
            })
          );
    }

    public getRequestByExperimentId(experimentId: number): Observable<any> {
      this.loadingService.setMessage('Loading Request Details...');
      let url = ConfigurationSettings.REST_API_URL + "/GroupRequest/getRequestById?experimentId=" + experimentId;

      return this.http.get(url)
          .pipe(
            map(result => {
              return result;
            }), 
            catchError((error: HttpErrorResponse) => {
              console.log(error);
              return throwError(error);
            }),
            finalize(() => {
              this.loadingService.clearMessage();
            })
          );
    }

    public getDetailsByRequestId(groupRequestId: any): Observable<any> {
      this.loadingService.setMessage('Loading Request Information...');
      let url = ConfigurationSettings.REST_API_URL + "/GroupRequest/getExperimentById?groupRequestId=" + groupRequestId;
    
      
      return this.http.get(url)
          .pipe(
            map(result => {
              return result;
            }), 
            catchError((error: HttpErrorResponse) => {
              console.log(error);
              return throwError(error);
            }),
            finalize(() => {
              this.loadingService.clearMessage();
            })
          );
    }

    public cancelStatusUpdate() {
      this.messageSource = new BehaviorSubject('cancel');
    }
    
    public getGroupRequests(): Observable<any> {
      this.loadingService.setMessage('Loading Group Requests...');
      let url = ConfigurationSettings.REST_API_URL + "/GroupRequest/getGroupRequests";

      return this.http.get(url)
          .pipe(
            map(result => {
              return result;
            }), 
            catchError((error: HttpErrorResponse) => {
              console.log(error);
              return throwError(error);
            }),
            finalize(() => {
              this.loadingService.clearMessage();
            })
          );
    }

    populateForm(popupForm) {
        this.form.setValue(popupForm);
      }

      refreshGrid() {
        let currentMessage;
        if(this.messageSource !== undefined) {
          currentMessage = this.messageSource.asObservable();
          this.messageSource = undefined;
        }
        return currentMessage;
      }

      public updateStatus(Id, isActive, isRequester): Observable<any> {
        this.loadingService.setMessage('Updating Request Status...');
        let url = ConfigurationSettings.REST_API_URL + "/GroupRequest/updateStatus";
        let inputParams = { params: { "groupRequestId": Id, "isActive" : isActive, "isRequester" : isRequester } }
  
        return this.http.put(url, this.httpOptions, inputParams)
            .pipe(
              map(result => {
                return result;
              }), 
              catchError((error: HttpErrorResponse) => {
                console.log(error);
                return throwError(error);
              }),
              finalize(() => {
                this.loadingService.clearMessage();
              })
            );
      }
      public getStrainInformation(groupRequestId): Observable<any> {
        this.loadingService.setMessage('Loading Sample Information...');
        let url = ConfigurationSettings.REST_API_URL + "/GroupRequest/getStrainInformation?groupRequestId=" + groupRequestId;
  
        return this.http.get(url)
            .pipe(
                map(result => {
                  return result;
                }), 
                catchError((error: HttpErrorResponse) => {
                  console.log(error);
                  return throwError(error);
                }),
                finalize(() => {
                  this.loadingService.clearMessage();
                })
              );
      }
      public insertStrainInformation(groupRequestId,strainInfoHTPGrid): Observable<any> {
        this.loadingService.setMessage('Saving Sample Information...');
        let url = ConfigurationSettings.REST_API_URL + "/GroupRequest/insertStrainInformation?groupRequestId=" + groupRequestId;;
  
        let body = JSON.stringify(strainInfoHTPGrid);

      return this.http.post(
          url,
          body, this.httpOptions)
          .pipe(
            map(result => {
              return result;
            }), 
            catchError((error: HttpErrorResponse) => {
              console.log(error);
              return throwError(error);
            }),
            finalize(() => {
              this.loadingService.clearMessage();
            })
          );
      }
      public  getRequestorEmailId(groupRequestId)  : Observable<any> {
      
        this.loadingService.setMessage('Loading Requestor Emails...');
        let url = ConfigurationSettings.REST_API_URL + "/GroupRequest/getRequestorEmail?groupRequestId=" + groupRequestId;
  
        return this.http.get(url)
            .pipe(
                map(result => {
                  return result;
                }), 
                catchError((error: HttpErrorResponse) => {
                  console.log(error);
                  return throwError(error);
                }),
                finalize(() => {
                  this.loadingService.clearMessage();
                })
              );
      }
      public  insertRequestorEmailId(model) : Observable<any> {
     
        this.loadingService.setMessage('Insert Requestor Emails...');
        this.messageSource = new BehaviorSubject(model);
        let url = ConfigurationSettings.REST_API_URL + "/GroupRequest/insertEmails";
        let body = JSON.stringify(model); 
      return this.http.post(
        url,
        body, this.httpOptions)
        .pipe(
              map(result => {
                return result;
              }), 
              catchError((error: HttpErrorResponse) => {
                console.log(error);
                return throwError(error);
              }),
              finalize(() => {
                this.loadingService.clearMessage();
              })
          );
      }

      public  deleteRequestorEmailId(requestorEmailId): Observable<any> {
      
        this.loadingService.setMessage('Delete Requestor Emails...');
        let url = ConfigurationSettings.REST_API_URL + "/GroupRequest/deleteRequestorEmail";
        //let body = JSON.stringify(requestorEmailId);  
        
        let inputParams = { params: { "requestorEmailId": requestorEmailId} }

      return this.http.post(url, this.httpOptions, inputParams)
          .map(result => result)
          .catch(error => error).finally(() => this.loadingService.clearMessage());
        // return this.http.post(
        //   url,
        //   body, this.httpOptions)
        //   .pipe(
        //         map(result => {
        //           return result;
        //         }), 
        //         catchError((error: HttpErrorResponse) => {
        //           console.log(error);
        //           return throwError(error);
        //         }),
        //         finalize(() => {
        //           this.loadingService.clearMessage();
        //         })
        //     );
      }

      public getAnalystByName(): Observable<any> {
        this.loadingService.setMessage('Getting Analyst information...');
        let url = ConfigurationSettings.REST_API_URL + "/GroupRequest/getAnalystByName";
        
        return this.http.get(url)
            .pipe(
              map(result => {
                return result;
              }), 
              catchError((error: HttpErrorResponse) => {
                console.log(error);
                return throwError(error);
              }),
              finalize(() => {
                this.loadingService.clearMessage();
              })
            );
            
      }

      getDialogConfig(isStrainInfoPopup = false) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = isStrainInfoPopup ? "60%" : "30%";
        return dialogConfig;

      }
}