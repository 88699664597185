import {Component, Inject, Output, EventEmitter} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { UserFriendlyHeadersService } from 'src/app/services/user-friendly-headers-service/user-friendly-headers.service';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from 'src/app/services/message-service';



@Component({
    selector: 'user-friendly-headers-popup',
    templateUrl: '../popup-component/popup.component.html',
    styleUrls: ['../popup-component/popup.component.css']
  })


  export class UserFriendlyHeadersPopupComponent {
    isFormInvalid: boolean = false;
    clickEventTrigger: boolean = false;
    constructor(
        private service: UserFriendlyHeadersService,
        public dialogRef: MatDialogRef<UserFriendlyHeadersPopupComponent>,
        private toastr: ToastrService,
        private _messageService: MessageService,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        }
  
        onNoClick(): void {
        this.dialogRef.close();
        }

        onClear() {
            this.service.form.reset();
            this.service.initializeFormGroup();
        }
  
        onSubmit() {
            this.checkValidRecord();
        }

        onKeyDown(event) {
            this.isFormInvalid = false;
        }

        checkValidRecord() {
            let isValid: boolean = false;
            let model = this.service.form.value;            
            this.service.get()
            .subscribe(
                result => {                    
                    if (!result.some((userFriendlyHeadersModel) => userFriendlyHeadersModel.UserFriendlyHeaderName.trim().toLowerCase() == model.UserFriendlyHeaderName.trim().toLowerCase())) {
                        this.isFormInvalid = false;
                        isValid = true;                        
                    }
                    else {
                        let userFriendlyHeader = result.find(userFriendlyHeader => userFriendlyHeader.UserFriendlyHeaderId === model.UserFriendlyHeaderId);
                        if(userFriendlyHeader !== null && userFriendlyHeader != undefined) {
                            if(result.some((userFriendlyHeaderModel) => userFriendlyHeaderModel.UserFriendlyHeaderName.trim().toLowerCase() == model.UserFriendlyHeaderName.trim().toLowerCase()
                            && userFriendlyHeaderModel.UserFriendlyHeaderId != model.UserFriendlyHeaderId))
                            {
                                this.isFormInvalid = true;
                                isValid = false;                               
                            }                            
                            else if(userFriendlyHeader.UserFriendlyHeaderName.trim() !== model.UserFriendlyHeaderName.trim() 
                            || userFriendlyHeader.Description.trim() !== model.Description.trim() ) {
                                this.isFormInvalid = false;
                                isValid = true;       
                            }
                            else {
                                this.isFormInvalid = true;
                                isValid = false;
                            }
                        }
                        else {
                            this.isFormInvalid = true;
                            isValid = false;
                         
                        }
                    }
                    
            if (this.service.form.valid && isValid) {               
                if (!this.service.form.get('UserFriendlyHeaderId').value) {
                  
                    this.service.add(this.service.form.value)
                    .subscribe(
                        result =>{
                            this._messageService.filter('refresh');
                            this.toastr.success('User Friendly Header created successfully !!');
                        },
                        error =>{
                            this.toastr.error(error, 'User Friendly Header creation failed !!');
                        }
                    );
                }
                else{                    
                    this.service.update(this.service.form.value)
                    .subscribe(
                        result =>{
                            this._messageService.filter('refresh');
                            this.toastr.success('User Friendly Header updated successfully !!');
                        },
                        error =>{
                            this.toastr.error(error,'User Friendly Header update failed !!');
                        }
                    );
                }
                this.onClose();
            }
                },
                error =>{                    
                    console.log(error);
                }); 
            return isValid;
        }

        onClose() {
            this.service.form.reset();
            this.service.initializeFormGroup();
            this.dialogRef.close();
        }

        popupHeader() {
           return this.service.form.controls['UserFriendlyHeaderId'].value?"Modify " + this.data.componentName:"New " + this.data.componentName
        }
  }