import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ResultDetailsService } from 'src/app/services/result-deatils-service/result-details.service';

@Component({
  selector: 'app-barcode-entries-popup',
  templateUrl: './barcode-entries-popup.component.html',
  styleUrls: ['./barcode-entries-popup.component.scss']
})
export class BarcodeEntriesPopupComponent implements OnInit {

  sampleDaysDataId: number;
  resultFileId: any;
  initialResultFileId: any;
  barcode: number;
  isProcessed: boolean;
  barcodeEntries: any[] = [];
  isSaveButtonDisabled: boolean = true;
  constructor(@Inject(MAT_DIALOG_DATA) private data: any,
                      private resultDetailsService: ResultDetailsService,
                      public datepipe: DatePipe, private toastr: ToastrService,  
                      public dialogRef: MatDialogRef<BarcodeEntriesPopupComponent>) {}

  ngOnInit() {
    this.sampleDaysDataId = this.data.sampleDaysDataId;
    this.barcode = this.data.barcode;
    this.isProcessed = this.data.isProcessed;
    this.getBarcodeEntries();
  }

  getBarcodeEntries() {

    this.resultDetailsService.getBarcodeEntries(this.sampleDaysDataId, this.barcode)
      .subscribe(
        result => {
          this.barcodeEntries = [];
          if(result != undefined) {
            result.forEach(r => {
              this.barcodeEntries.push({
                IsActive: r.IsActive,
                Barcode: r.Barcode,
                RunDate: this.datepipe.transform(r.RunDate,'MM/dd/yyyy'),    
                RunTime: r.RunTime,
                Instrument: r.Instrument,
                ResultFileId: r.ResultFileId,
                SampleDaysDataId: r.SampleDaysDataId,
                FileName: r.FileName
              })
            });

            this.initialResultFileId = this.barcodeEntries.filter(x => x.IsActive)[0].ResultFileId;
          }
        });
  }

  changeResultFile(event)
  {
    this.resultFileId = event.value;
    this.isSaveButtonDisabled = this.resultFileId == this.initialResultFileId;
  }

  onCancel(){
    this.dialogRef.close(false);
  }

  onSave()
  {
    this.dialogRef.close(this.resultFileId);
  }

}
