import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConstants } from '../common-utility/appconstants';
import { QCDataModel } from '../models/qc-data.model';
import { ResultDetailsService } from '../services/result-deatils-service/result-details.service';
import { MessageService } from '../services/message-service';
import { EntriesLinkRendererComponent } from '../custom/entries-link-renderer/entries-link-renderer.component';
import { SlideCellRendererComponent } from '../custom/slide-cell-renderer/slide-cell-renderer.component';
import { GridSizeChangedEvent } from 'ag-grid-community';
import { QCDataDetailsModel } from '../models/qc-data-details.model';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { StatusDialogComponent } from '../shared/status-dialog/status-dialog.component';

@Component({
  selector: 'app-view-qc-data-details',
  templateUrl: './view-qc-data-details.component.html',
  styleUrls: ['./view-qc-data-details.component.scss']
})
export class ViewQcDataDetailsComponent implements OnInit {

  sampleDaysDataId: number;
  resultFileId: number;
  fileName: string;
  isProcessed: boolean = false;
  rowData: QCDataDetailsModel[] = [];
  public context: any;
  private gridApi;
  private gridColumnApi;
  public subscription: any;
  componentName = AppConstants.QCDATAPOPUPVALUE;
  subComponentName = AppConstants.ACTIVEINACTIVEAll;
  public activeInactiveAll: boolean = false;
  constructor(private route: ActivatedRoute,
    private resultDetailsService: ResultDetailsService,
    private toastr: ToastrService,
    private _messageService: MessageService,
    private router: Router, private dialog: MatDialog) {
    this.context = { componentParent: this };
    this._messageService.listen().subscribe((m: any) => {
      this.refreshGrid();
    });
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.resultFileId = Number(params.get("resultFileId"));
    });

    this.getQCDataDetails();

  }

  getQCDataDetails() {
    this.resultDetailsService.getQCDataDetails(this.resultFileId)
      .subscribe(
        result => {
          this.rowData = [];
          if (result != undefined) {
            this.fileName = result[0].FileName;
            this.isProcessed = result[0].IsProcessed;
            this.sampleDaysDataId = result[0].SampleDaysDataId;
            result.forEach(r => {
              this.rowData.push({
                IsActive: r.IsActive,
                FileName: r.FileName,
                ResultRowId: r.ResultRowId,
                QCData: r.QCData,
                Row: r.Row,
                IsProcessed: r.IsProcessed
              })
            });
            this.activeInactiveAll = !this.rowData.every(x => !x.IsActive);
          }
        });
  }


  frameworkComponents = {
    slideCellRenderer: SlideCellRendererComponent
  };
  defaultColDef: {
    flex: 1,
    enableValue: true,
    enableRowGroup: true,
    enablePivot: true,
    sortable: true,
    filter: true,
  }
  columnDefs = [
    { headerName: 'Active', field: 'IsActive', cellRenderer: 'slideCellRenderer', sortable: true, filter: true, suppressSizeToFit : true },
    { headerName: 'Row Number', field: 'Row', sortable: true, filter: true, suppressSizeToFit : true },
    { headerName: 'QC Data', field: 'QCData', sortable: true, filter: true, tooltipValueGetter: (params) => params.value }
  ];

  updateStatus(qcDataDetails: any, isActive: boolean) {
    this.updateQCDataStatusByRow(qcDataDetails, isActive);
  }

  updateQCDataStatusByRow(qcDataDetails: any, isActive: boolean) {
    this.resultDetailsService.updateQCDataStatusByRow(qcDataDetails.ResultRowId, isActive)
      .subscribe(result => {
        this.getQCDataDetails();
        this.toastr.success('QC data updated successfully !!');
      },
        error => {
          this.toastr.error('QC data update failed !!');
        })
  }

  cancelStatusUpdate() {
    this.resultDetailsService.cancelStatusUpdate();
  }

  refreshGrid() {
    this.getQCDataDetails();
    this.gridApi.refreshClientSideRowModel('aggregate')
    this.onCloseEvent();
  }

  onCloseEvent() {
    if (this.subscription !== undefined) {
      this.subscription.unsubscribe();
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    //this.gridApi.setDomLayout("autoHeight");
  }

  onGridSizeChanged(params: GridSizeChangedEvent) {
    params.api.sizeColumnsToFit();
  }

  onclickViewQCData()
  {
    this.router.navigate([AppConstants.QCDATA, this.sampleDaysDataId]);
  }

  onclickResultDetails()
  {
    this.router.navigate([AppConstants.RESULTDETAILS, this.sampleDaysDataId]);
  }

  public onChange(event) {
    let dialogConfig = this.getDialogConfig();
    dialogConfig.data = { isActive: event.checked, componentName: this.componentName, subComponentName: this.subComponentName };
    let dialogRef = this.dialog.open(StatusDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
    status => {
        if (status) {
            this.updateAllQCDataStatusByFile(event.checked);
        }     
        else {        
            this.cancelStatusUpdateAll();        
        }
    },
    error =>{
        console.log(error);
    },
    );
}

getDialogConfig() {
  const dialogConfig = new MatDialogConfig();
  dialogConfig.disableClose = true;
  dialogConfig.autoFocus = true;
  dialogConfig.width = "30%";
  return dialogConfig;

}

cancelStatusUpdateAll() {
  this.activeInactiveAll = !this.rowData.every(x => !x.IsActive);
  this.resultDetailsService.cancelStatusUpdate();

}

updateAllQCDataStatusByFile(isActive: boolean) {
  this.resultDetailsService.updateQCDataStatusByFile(this.resultFileId, isActive)
    .subscribe(result => {
      this.getQCDataDetails();
      this.toastr.success('QC Data updated successfully !!');
    },
      error => {
        this.toastr.error('QC Data update failed !!');
      })
}

}
