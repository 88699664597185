import { Component, TemplateRef, ViewChild, OnInit, Optional } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { SlideCellRendererComponent } from '../../custom/slide-cell-renderer/slide-cell-renderer.component';
import { EditButtonRendererComponent } from '../../custom/edit-button-renderer/edit-button-renderer.component';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import * as _ from 'lodash';
import { BusinessAlignmentModel } from 'src/app/models/business-alignment.model';
import { AppConstants } from 'src/app/common-utility/appconstants';
import { StatusDialogComponent } from 'src/app/shared/status-dialog/status-dialog.component';
import { BusinessAlignmentService } from 'src/app/services/business-alignment-service/business-alignment.service';
import { BusinessAlignmentPopupComponent } from './business-alignment-popup.component';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from 'src/app/services/message-service';
import { GridSizeChangedEvent } from 'ag-grid-community';

@Component({
    templateUrl: '../../shared/grid-component/grid.component.html',
    styleUrls: ['./business-alignment.component.css']
})

export class BusinessAlignmentComponent implements OnInit {
    request: FormGroup;
    rowData: Array<BusinessAlignmentModel>;
    private gridApi;
    private gridColumnApi;
    public context: any;
    public subscription: any;
    componentName = AppConstants.BUSINESSALIGNMENT;
    componentTitle = AppConstants.BUSINESSALIGNMENTTITLE;

  constructor(private fb: FormBuilder, private dialog: MatDialog,
    private service: BusinessAlignmentService,
    private toastr: ToastrService,
    private _messageService: MessageService) {
      this.context = { componentParent: this };
      this._messageService.listen().subscribe((m:any) => {
        this.refreshGrid();
      })
  }

  ngOnInit() {
    this.getBusinessAlignmentInfo();
  }

  getBusinessAlignmentInfo(){
    this.service.get()
      .subscribe(
        result => {
          this.rowData = result;
        },
        error =>{
          console.log(error);
        }
      ) 
  }

  defaultColDef: {
    flex: 1,    
    enableValue: true,    
    enableRowGroup: true,
    enablePivot: true,
    sortable: true,
    filter: true,
  }
  
  frameworkComponents = {
    slideCellRenderer: SlideCellRendererComponent,
    btnEditRenderer: EditButtonRendererComponent
  };

  columnDefs = [
    {headerName: 'Name', field: 'BusinessAlignmentName', sortable: true, filter: true, width: 200, tooltipValueGetter: (params) => params.value },   
    {headerName: 'Description', field: 'Description', sortable: true, filter: true, width: 350, tooltipValueGetter: (params) => params.value}, 
    {headerName: 'Modified By', field:'ModifiedBy', sortable: true, filter: true, width: 200, tooltipValueGetter: (params) => params.value},
    {headerName: 'Modified Date', field:'ModifiedDate', sortable: true, filter: true, width: 200,
    cellRenderer: (data) => {
      return data.value ? (new Date(data.value)).toLocaleDateString() : '';}
    },
    {headerName: 'Active', field: 'IsActive', cellRenderer: 'slideCellRenderer', sortable: true, filter: true, width: 150},
    {
      field: '',
      cellRenderer: 'btnEditRenderer',
      tooltipValueGetter: (params) => 'Edit'
    },
  ];

  updateStatus(businessAlignmentModel: BusinessAlignmentModel, isActive: boolean) {    
    this.service.updateStatus(businessAlignmentModel.BusinessAlignmentId, isActive)
      .subscribe(result => {
        this.getBusinessAlignmentInfo();
        this.toastr.success('Business Alignment updated successfully !!');
      },
        error => {
          this.toastr.error(error, 'Business Alignment update failed !!');
        })
  }

  add(){
    let dialogConfig = this.service.getDialogConfig();
    dialogConfig.data = { componentName: this.componentName, componentId: "BusinessAlignmentId",controlName: "BusinessAlignmentName" };
    this.dialog.open(BusinessAlignmentPopupComponent, dialogConfig);
  }

  update(row){
    if(row.colDef != undefined && row.colDef !== '' && row.colDef !== null && row.colDef.field == '') {
      this.service.populateForm(row.data);
      let dialogConfig = this.service.getDialogConfig();
      dialogConfig.data = { componentName: this.componentName, componentId: "BusinessAlignmentId",controlName: "BusinessAlignmentName" };
      this.dialog.open(BusinessAlignmentPopupComponent,dialogConfig);
      
    }
  }

  refreshGrid(){
        this.getBusinessAlignmentInfo();
        this.gridApi.refreshClientSideRowModel('aggregate');
    this.onCloseEvent();
  }

  cancelStatusUpdate() {
    this.service.cancelStatusUpdate();
  }

  onCloseEvent() {
    if(this.subscription !== undefined) {
      this.subscription.unsubscribe();
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  onGridSizeChanged(params: GridSizeChangedEvent) {
    params.api.sizeColumnsToFit();
  }
}