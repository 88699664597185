import { InstrumentModel } from './instrument.model';
import { UsersModel } from './users.model';

export class MethodModel {
    MethodId: number;
    MethodName: string;
    Description: string;
    Instruments: InstrumentModel[];
    DefaultInstrument: InstrumentModel;
    Analysts: UsersModel[];
    DefaultAnalyst: UsersModel;
    TurnAroundTimeInDays: number;
    CreatedBy: string;
    CreatedDate: Date;
    ModifiedBy: string;
    ModifiedDate: string;
    IsActive: boolean;
    show: boolean = true;
}