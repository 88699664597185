import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs-compat/Observable';
import { ConfigurationSettings } from 'src/app/configuration-settings';
import { LoadingService } from '../loading.service';
import { map, catchError, finalize } from 'rxjs/operators';
import { throwError} from 'rxjs';
import { BehaviorSubject } from 'rxjs-compat/BehaviorSubject';
import { MatDialogConfig } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class ResultDetailsService {

  private messageSource;
  httpOptions = {
    headers: new HttpHeaders({ 
        'Content-Type': 'application/json' 
    })
};
  
  constructor(private http: HttpClient, 
    private loadingService: LoadingService) { }

    public getResultDetails(sampleDaysDataId: number): Observable<any> {
      this.loadingService.setMessage('Loading Result Details...');
      let url = ConfigurationSettings.REST_API_URL + "/Results/GetResultDetails/" + sampleDaysDataId;
     
      return this.http.get(url)
          .pipe(
            map(result => {
              return result;
            }), 
            catchError((error: HttpErrorResponse) => {
              console.log(error);
              return throwError(error);
            }),
            finalize(() => {
              this.loadingService.clearMessage();
            })
          );
          
    }

    public getBarcodeEntries(sampleDaysDataId: number, barcode: number): Observable<any> {
      this.loadingService.setMessage('Loading Barcode Entries...');
      let url = ConfigurationSettings.REST_API_URL + "/Results/GetBarcodeEntries/" + sampleDaysDataId + "/" + barcode;
     
      return this.http.get(url)
          .pipe(
            map(result => {
              return result;
            }), 
            catchError((error: HttpErrorResponse) => {
              console.log(error);
              return throwError(error);
            }),
            finalize(() => {
              this.loadingService.clearMessage();
            })
          );
          
    }

    public updateBarcodeStatus(sampleDaysDataId, barcode, isActive): Observable<any> {
      this.loadingService.setMessage('Updating barcode Status...');
      let url = ConfigurationSettings.REST_API_URL + "/Results/UpdateBarcodeStatus";
      let inputParams = { params: { "sampleDaysDataId": sampleDaysDataId, "barcode": barcode, "isActive" : isActive } }

      return this.http.put(url, this.httpOptions, inputParams)
              .pipe(
                map(result => {
                  return result;
                }), 
                catchError((error: HttpErrorResponse) => {
                  console.log(error);
                  return throwError(error);
                }),
                finalize(() => {
                  this.loadingService.clearMessage();
                })
              );
    }

    public updateAllBarcodeStatuses(sampleDaysDataId, isActive): Observable<any> {
      this.loadingService.setMessage('Updating all barcode Statuses...');
      let url = ConfigurationSettings.REST_API_URL + "/Results/UpdateAllBarcodeStatuses";
      let inputParams = { params: { "sampleDaysDataId": sampleDaysDataId, "isActive" : isActive } }

      return this.http.put(url, this.httpOptions, inputParams)
              .pipe(
                map(result => {
                  return result;
                }), 
                catchError((error: HttpErrorResponse) => {
                  console.log(error);
                  return throwError(error);
                }),
                finalize(() => {
                  this.loadingService.clearMessage();
                })
              );
    }

    public switchBarcodeResult(sampleDaysDataId, resultFileId): Observable<any> {
      this.loadingService.setMessage('Updating barcode Results...');
      let url = ConfigurationSettings.REST_API_URL + "/Results/SwitchBarcodeResult";
      let inputParams = { params: { "sampleDaysDataId": sampleDaysDataId, "resultFileId" : resultFileId } }
      return this.http.put(url, this.httpOptions, inputParams)
              .pipe(
                map(result => {
                  return result;
                }), 
                catchError((error: HttpErrorResponse) => {
                  console.log(error);
                  return throwError(error);
                }),
                finalize(() => {
                  this.loadingService.clearMessage();
                })
              );
     
    }

    public cancelStatusUpdate() {
      this.messageSource = new BehaviorSubject('cancel');
    }

    public getSearchResults(searchCriteria): Observable<any> {
      this.loadingService.setMessage('Loading Search Results...');
      let url = ConfigurationSettings.REST_API_URL + "/Results/getSearchResults";
      let inputParams = { params: searchCriteria }
      return this.http.post(url, searchCriteria, this.httpOptions)
          .pipe(
            map(result => {
              return result;
            }), 
            catchError((error: HttpErrorResponse) => {
              console.log(error);
              return throwError(error);
            }),
            finalize(() => {
              this.loadingService.clearMessage();
            })
          );
          
    }
    getDialogConfig() {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.width = "23%";
      return dialogConfig;

    }

    public getQCData(sampleDaysDataId: number): Observable<any> {
      this.loadingService.setMessage('Loading QC Data...');
      let url = ConfigurationSettings.REST_API_URL + "/Results/GetQCData/" + sampleDaysDataId;
     
      return this.http.get(url)
          .pipe(
            map(result => {
              return result;
            }), 
            catchError((error: HttpErrorResponse) => {
              console.log(error);
              return throwError(error);
            }),
            finalize(() => {
              this.loadingService.clearMessage();
            })
          );
          
    }

    public getQCDataDetails(resultFileId: number): Observable<any> {
      this.loadingService.setMessage('Loading QC Data Details...');
      let url = ConfigurationSettings.REST_API_URL + "/Results/GetQCDataDetails/" + resultFileId;
     
      return this.http.get(url)
          .pipe(
            map(result => {
              return result;
            }), 
            catchError((error: HttpErrorResponse) => {
              console.log(error);
              return throwError(error);
            }),
            finalize(() => {
              this.loadingService.clearMessage();
            })
          );
          
    }

    public updateQCDataStatusByFile(resultFileId, isActive): Observable<any> {
      this.loadingService.setMessage('Updating QC Data...');
      let url = ConfigurationSettings.REST_API_URL + "/Results/UpdateQCDataStatusByFile";
      let inputParams = { params: { "resultFileId": resultFileId, "isActive" : isActive } }

      return this.http.put(url, this.httpOptions, inputParams)
              .pipe(
                map(result => {
                  return result;
                }), 
                catchError((error: HttpErrorResponse) => {
                  console.log(error);
                  return throwError(error);
                }),
                finalize(() => {
                  this.loadingService.clearMessage();
                })
              );
    }

    public updateAllQCDataStatusBySampleDay(sampleDaysDataId, isActive): Observable<any> {
      this.loadingService.setMessage('Updating QC Data...');
      let url = ConfigurationSettings.REST_API_URL + "/Results/UpdateAllQCDataStatusBySampleDay";
      let inputParams = { params: { "sampleDaysDataId": sampleDaysDataId, "isActive" : isActive } }

      return this.http.put(url, this.httpOptions, inputParams)
              .pipe(
                map(result => {
                  return result;
                }), 
                catchError((error: HttpErrorResponse) => {
                  console.log(error);
                  return throwError(error);
                }),
                finalize(() => {
                  this.loadingService.clearMessage();
                })
              );
    }

    public updateQCDataStatusByRow(resultRowId, isActive): Observable<any> {
      this.loadingService.setMessage('Updating QC Data...');
      let url = ConfigurationSettings.REST_API_URL + "/Results/UpdateQCDataStatusByRow";
      let inputParams = { params: { "resultRowId" : resultRowId, "isActive" : isActive } }
      return this.http.put(url, this.httpOptions, inputParams)
              .pipe(
                map(result => {
                  return result;
                }), 
                catchError((error: HttpErrorResponse) => {
                  console.log(error);
                  return throwError(error);
                }),
                finalize(() => {
                  this.loadingService.clearMessage();
                })
              );
     
    }
}
