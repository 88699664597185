import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { BarcodeEntriesPopupComponent } from './barcode-entries-popup/barcode-entries-popup.component';

@Component({
  selector: 'app-entries-link-renderer',
  templateUrl: './entries-link-renderer.component.html',
  styleUrls: ['./entries-link-renderer.component.scss']
})
export class EntriesLinkRendererComponent implements OnInit {

  data: any;
  params: any;
  label: string;
  isButtonDisable: boolean;

  constructor(public dialog: MatDialog) { }

  ngOnInit() {
  }

  agInit(params) {
    this.params = params;  
    this.label = this.params.label || null;    
    this.data=this.params.data;  
    this.isButtonDisable = !this.data.IsActive  || this.data.Entries == 1
  }

  getDialogConfig() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "50%";
    return dialogConfig;

  }

  onClick($event) {   
    let dialogConfig = this.getDialogConfig();
        dialogConfig.data = {
          sampleDaysDataId: this.data.SampleDaysDataId,
          barcode: this.data.Barcode,
          isProcessed: this.data.IsProcessed
        };  
    let dialogRef = this.dialog.open(BarcodeEntriesPopupComponent, dialogConfig); 
    dialogRef.afterClosed().subscribe(
      result => {
          if (result) {
              this.params.context.componentParent.switchBarcodeResult(result);
          }  
      },
      error =>{
          console.log(error);
      }
      );        
    }

}
