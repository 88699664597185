import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app/common-utility/appconstants';

@Component({
  selector: 'app-qc-data-details-link-renderer',
  templateUrl: './qc-data-details-link-renderer.component.html',
  styleUrls: ['./qc-data-details-link-renderer.component.scss']
})
export class QcDataDetailsLinkRendererComponent implements OnInit {

  data: any;
  params: any;
  label: string;
  isButtonDisable: boolean;

  constructor(public dialog: MatDialog, private router: Router) { }

  ngOnInit() {
  }

  agInit(params) {
    this.params = params;  
    this.label = this.params.label || null;    
    this.data=this.params.data;  
    this.isButtonDisable = !this.data.IsActive
  }

  

  onClick($event) {  
    this.router.navigate([AppConstants.QCDATADETAILS, this.data.ResultFileId]);
    }

}
