import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigurationSettings } from "../../configuration-settings"
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs-compat/BehaviorSubject';
import { MatDialogConfig } from '@angular/material/dialog';
import { VesselModel } from 'src/app/models/vessel.model';
import { Observable } from 'rxjs';
import { LoadingService } from '../loading.service';

@Injectable({
    providedIn: 'root'
  })
export class VesselService {
  private messageSource;
  // currentMessage = this.messageSource.asObservable();
  
    httpOptions = {
        headers: new HttpHeaders({ 
            'Content-Type': 'application/json' 
        })
    };

    constructor(private http: HttpClient, private loadingService: LoadingService) { }

    form: FormGroup = new FormGroup({
        VesselId: new FormControl(null),
        VesselName: new FormControl('', Validators.required),
        SampleNumberPerVessel: new FormControl('',Validators.required),
        SampleNumberPerVesselName:new FormControl(''), 
        Description: new FormControl(''), 
        IsActive: new FormControl(true)
      });

      initializeFormGroup() {
        this.form.setValue({
          VesselId: null,
          VesselName: '',
          SampleNumberPerVessel: '',
          SampleNumberPerVesselName:'',
          Description: '',
          IsActive: 1
        });
      }

    public getVessels(): Observable<any> {
      this.loadingService.setMessage('Loading Vessels...');
      let url = ConfigurationSettings.REST_API_URL + "/Vessel/vessels"
      return this.http.get(url)
          .map(result => result)
          .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
    }

    public getVesselById(vesselId: number): Observable<any> {
      this.loadingService.setMessage('Loading Vessel...');
      let url = ConfigurationSettings.REST_API_URL + "/Vessel/getVesselById?vesselId="+ vesselId;
      return this.http.get(url)
          .map(result => result)
          .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
    }

    public addVessel(model: VesselModel): Observable<any> { 
      this.loadingService.setMessage('Adding Vessel...');
      this.messageSource = new BehaviorSubject(model);
      let url = ConfigurationSettings.REST_API_URL + "/Vessel/insert";
      let body = JSON.stringify(model);

      return this.http.post(
          url,
          body, this.httpOptions)
          .map(result => result)
          .catch(error => error).finally(() => this.loadingService.clearMessage());
  }

    public updateVessel(model: VesselModel): Observable<any> {
      this.loadingService.setMessage('Updating Vessel...');
      let url = ConfigurationSettings.REST_API_URL + "/Vessel/update"
      let body = JSON.stringify(model);
      return this.http.put(url, body, this.httpOptions)
          .map(result => result)
          .catch(error => error).finally(() => this.loadingService.clearMessage());
    }

    public updateStatus(vesselId, isActive): Observable<any> {
      this.loadingService.setMessage('Updating Vessel Status...');
      let url = ConfigurationSettings.REST_API_URL + "/Vessel/updateStatus"
      let inputParams = { params: { "vesselId": vesselId, "isActive" : isActive } }

      return this.http.put(url, this.httpOptions, inputParams)
          .map(result => result)
          .catch(error => error).finally(() => this.loadingService.clearMessage());
    }
    public getSampleNumberPerVessel(): Observable<any> {
      this.loadingService.setMessage('Loading Vessels...');
      let url = ConfigurationSettings.REST_API_URL + "/Vessel/getSampleNumberPerVessel"
      return this.http.get(url)
          .map(result => result)
          .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
    }

    populateForm(vessel) {
        // this.form.setValue(group);
        this.form.setValue(_.omit(vessel,['CreatedBy','CreatedDate','ModifiedBy','ModifiedDate']));
      }

      public cancelStatusUpdate() {
        this.messageSource = new BehaviorSubject('cancel');
      }

      refreshGrid() {
        let currentMessage;
        if(this.messageSource !== undefined) {
          currentMessage = this.messageSource.asObservable();
          this.messageSource = undefined;
        }
        return currentMessage;
      }

      getDialogConfig() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = "20%";
        return dialogConfig;

      }
}