﻿

import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpRequest } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import{RoleModel} from '../../role/role.model';

import { ConfigurationSettings } from "../.././configuration-settings"

@Injectable()
export class RoleService {

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

  constructor(private _http:HttpClient) { }

  public createRole(role:RoleModel):any{
    let url=ConfigurationSettings.REST_API_URL + "/roles"
    let body= JSON.stringify(role);

    return this._http.post(
            url,
            body, this.httpOptions).pipe(
                tap(x => console.log("Created new Role"))
            );
    }

  public getRoles():any{
    let url=ConfigurationSettings.REST_API_URL + "/roles"
        return this._http.get(url).pipe(
            tap(x => console.log("Fetched Roles")));
    }

  public updateRole(role:RoleModel):any{
    let url=ConfigurationSettings.REST_API_URL + "/roles"
    let body= JSON.stringify(role);
    return this._http.put(
            url,
            body, this.httpOptions).pipe(
                tap(x => console.log("Updated Role"))
            );
    }

  public deleteRole(Id:number):any{
    let url=ConfigurationSettings.REST_API_URL + "/roles/"+Id;
   return this._http.delete(url).pipe(
            tap(x => console.log("Deleted Role")));
    }
}