import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { SlideCellRendererComponent } from '../../custom/slide-cell-renderer/slide-cell-renderer.component';
import { EditButtonRendererComponent } from '../../custom/edit-button-renderer/edit-button-renderer.component';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import { ProjectModel } from 'src/app/models/project.model';
import { AppConstants } from 'src/app/common-utility/appconstants';
import { ProjectService } from 'src/app/services/project-service/project.service';
import { ProjectPopupComponent } from './project-popup.component';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from 'src/app/services/message-service';
import { GridSizeChangedEvent } from 'ag-grid-community';
import { DatePipe } from '@angular/common';

@Component({
    templateUrl: '../../shared/grid-component/grid.component.html',
    styleUrls: ['./project.component.css']
})

export class ProjectComponent implements OnInit {
    request: FormGroup;
    rowData: ProjectModel[] = [];
    private gridApi;
    private gridColumnApi;
    public context: any;
    public subscription: any;
    componentName = AppConstants.PROJECT;
    componentTitle = AppConstants.PROJECTTITLE;

  constructor(private fb: FormBuilder, private dialog: MatDialog,
    private service: ProjectService,
    private toastr: ToastrService,
    public datepipe: DatePipe,
    private _messageService: MessageService) {
      this.context = { componentParent: this };
      this._messageService.listen().subscribe((m:any) => {
        this.refreshGrid();
      })
  }

  ngOnInit() {
    this.getProjectInfo();
  }

  getProjectInfo(){
    this.service.get()
      .subscribe(
        result => {
          this.rowData = [];
          result.forEach(r => {
            this.rowData.push({
              ProjectId: r.ProjectId,
              ProjectName: r.ProjectName,
              Description: r.Description,    
              IsActive: r.IsActive,
              CreatedDate: r.CreatedDate,
              CreatedBy: r.CreatedBy,
              ModifiedDate: this.datepipe.transform(r.ModifiedDate,'MM/dd/yyyy'),
              ModifiedBy: r.ModifiedBy
            })
          })
        },
        error =>{
          console.log(error);
        }
      ) 
  }

  defaultColDef: {
    flex: 1,    
    enableValue: true,    
    enableRowGroup: true,
    enablePivot: true,
    sortable: true,
    filter: true,
  }
  
  frameworkComponents = {
    slideCellRenderer: SlideCellRendererComponent,
    btnEditRenderer: EditButtonRendererComponent
  };

  columnDefs = [
    {headerName: 'Name', field: 'ProjectName', sortable: true, filter: true, width: 200, tooltipValueGetter: (params) => params.value },   
    {headerName: 'Description', field: 'Description', sortable: true, filter: true, width: 350, tooltipValueGetter: (params) => params.value}, 
    {headerName: 'Modified By', field:'ModifiedBy', sortable: true, filter: true, width: 200, tooltipValueGetter: (params) => params.value},
    {headerName: 'Modified Date', field:'ModifiedDate', sortable: true, filter: true, width: 200 },
    {headerName: 'Active', field: 'IsActive', cellRenderer: 'slideCellRenderer', sortable: true, filter: true, width: 150},
    {
      field: '',
      cellRenderer: 'btnEditRenderer',
      tooltipValueGetter: (params) => 'Edit'
    },
  ];

  updateStatus(projectModel: ProjectModel, isActive: boolean) {
    this.service.updateStatus(projectModel.ProjectId, isActive)
      .subscribe(result => {
        this.toastr.success('Project updated successfully !!');
        this.getProjectInfo();
      },
        error => {
          this.toastr.error(error, 'Project update failed !!');
        })
  }

  add(){
    this.showPopup();
  }

  update(row){
    if(row.colDef != undefined && row.colDef !== '' && row.colDef !== null && row.colDef.field == '') {
      this.service.populateForm(row.data);
      this.showPopup();
    }
  }

  showPopup() {
      let dialogConfig = this.service.getDialogConfig();      
      dialogConfig.data = { componentName: this.componentName, componentId: "ProjectId", controlName: "ProjectName", popupHeight: "300px" };
      this.dialog.open(ProjectPopupComponent,dialogConfig);
  }
  
  cancelStatusUpdate() {
    this.service.cancelStatusUpdate();
  }

  refreshGrid(){
    this.getProjectInfo();
    this.gridApi.refreshClientSideRowModel('aggregate')
    this.onCloseEvent();
  }

  onCloseEvent() {
    if(this.subscription !== undefined) {
      this.subscription.unsubscribe();
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.setDomLayout("autoHeight");
  }

  onGridSizeChanged(params: GridSizeChangedEvent) {
    params.api.sizeColumnsToFit();
  }
}