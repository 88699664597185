import {Component, Inject, Output, EventEmitter} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { GroupTypeService } from 'src/app/services/group-service/group.service';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from 'src/app/services/message-service';
import { AppConstants } from 'src/app/common-utility/appconstants';
import { ExperimentTypeService } from 'src/app/services/experiment-type-service/experiment-type.service';


@Component({
    selector: 'group-popup',
    templateUrl: '../popup-component/popup.component.html',
    styleUrls: ['../popup-component/popup.component.css']
  })


  export class GroupTypePopupComponent {
      isFormInvalid: boolean = false;
      clickEventTrigger: boolean = false;
      show: boolean = false;
      experimentTypeList: [] = [];
    constructor(
        private service: GroupTypeService,
        public dialogRef: MatDialogRef<GroupTypePopupComponent>,
        private toastr: ToastrService,
        private experimentTypeService: ExperimentTypeService,
        private _messageService: MessageService,
        @Inject(MAT_DIALOG_DATA) public data: any) {
            if(this.data.componentName === AppConstants.GROUP) {
                this.show= true;
                this.populateDropdown();
            }
        }
  
        onNoClick(): void {
        this.dialogRef.close();
        }

        onClear() {
            this.service.form.reset();
            this.service.initializeFormGroup();
        }

        onSubmit() {
            this.checkValidRecord();
        }

        onKeyDown(event) {
            this.isFormInvalid = false;
        }

        compareFn: ((f1: any, f2: any) => boolean) | null = this.compareByValue;

        compareByValue(f1: any, f2: any) { 
            return f1 && f2 && f1.ExperimentTypeName === f2.ExperimentTypeName; 
        }
  
        checkValidRecord() {
            let isValid: boolean = false;
            let model = this.service.form.value;
            this.service.get()
            .subscribe(
                result => {
                    if (!result.some((groupModel) => groupModel.GroupName.trim().toLowerCase() == model.GroupName.trim().toLowerCase())) {
                        this.isFormInvalid = false;
                        isValid = true;
                    }
                    else {
                        let groupType = result.find(groupType => groupType.GroupTypeId === model.GroupTypeId);
                        if(groupType !== null && groupType != undefined) {  
                            if(result.some((groupModel) => groupModel.GroupName.trim().toLowerCase() == model.GroupName.trim().toLowerCase()
                            && groupModel.GroupTypeId != model.GroupTypeId))
                            {
                                this.isFormInvalid = true;
                                isValid = false;
                            }                          
                            else if(groupType.GroupName.trim() !== model.GroupName.trim()
                                || groupType.Description.trim() !== model.Description.trim()
                                || JSON.stringify(groupType.ExperimentTypes) !== JSON.stringify(model.ExperimentTypes) ) {
                                this.isFormInvalid = false;
                                isValid = true;
                            }
                            else {
                                this.isFormInvalid = true;
                                isValid = false;
                            }
                        }
                        else {
                            this.isFormInvalid = true;
                            isValid = false;
                        }
                    }

            if (this.service.form.valid && isValid) {
                if (!this.service.form.get('GroupTypeId').value) {
                    this.service.add(this.service.form.value)
                    .subscribe(
                        result =>{
                            this._messageService.filter('refresh');
                            this.toastr.success('Group created successfully !!');
                        },
                        error =>{
                            this.toastr.error(error,'Group creation failed !!');
                        }
                    );
                }
                else{
                    this.service.update(this.service.form.value)
                    .subscribe(
                        result =>{
                            this._messageService.filter('refresh');
                            this.toastr.success('Group updated successfully !!');
                        },
                        error =>{
                            this.toastr.error(error,'Group update failed !!');
                        }
                    );
                }
                this.onClose();
            }
        },
        error =>{
            console.log(error);
        }); 
            return isValid;
        }

        onClose() {
            this.service.form.reset();
            this.service.initializeFormGroup();
            this.dialogRef.close();
        }

        popupHeader() {
           return this.service.form.controls['GroupTypeId'].value?"Modify " + this.data.componentName:"New " + this.data.componentName
        }

        populateDropdown() {
            this.experimentTypeService.get()
            .subscribe(
                result => {
                this.experimentTypeList = result.filter(r=> r.IsActive === true);
                },
                error =>{
                console.log(error);
                }
            );
        }
  }