import {Component, Inject, Output, EventEmitter,ViewChild,ViewEncapsulation, TemplateRef} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { FileMappingService } from 'src/app/services/file-mapping-service/file-mapping.service';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from 'src/app/services/message-service';
import { SortByPipe } from 'src/app/shared/sort/sort-pipe';
import { MethodService } from 'src/app/services/method-service/method.service';
import { MatDialog } from '@angular/material/dialog';
import { ColumnMappingModel } from 'src/app/models/column-mapping.model';
import { SubRuleColumnMappingModel } from 'src/app/models/column-mapping.model';
import { ColumnMappingRulesModel } from 'src/app/models/column-mapping.model';
import { FileMappingDuplicateRecordAlertComponent } from './file-mapping-popup_alerts/file-mapping-duplicate-record-alert.component';
import { FileMappingValidationPopupAlert } from './file-mapping-validation-popup_alert/file-mapping-validation-popup_alert.component';
import { FileMappingVesselValidationPopupAlert } from './file-mapping-vessel_validation-popup_alert/file-mapping-vessel_validation-popup_alert.component';
import { AppConstants } from 'src/app/common-utility/appconstants';
import { MethodModel } from 'src/app/models/method.model';
import { startWith, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { FormControl } from "@angular/forms";
import { InformationPopupComponent } from './information-popup/information-popup.component';

@Component({
    selector: 'file-mapping-popup',
    templateUrl: 'file-mapping-popup.component.html',
    styleUrls: ['./file-mapping.component.css']
    ,
  encapsulation: ViewEncapsulation.None
  })


  export class FileMappingPopupComponent {
    isFormInvalid: boolean = false;
    clickEventTrigger: boolean = false;
    methodsList: MethodModel[] = [];
    selectedMethodsList: any[] = [];
    selectedMethodsValue: [];
    selectedValue:any;
    dynamicArray: ColumnMappingRulesModel;    
    newColumnMapping: ColumnMappingModel;
    newSubRuleColumn:SubRuleColumnMappingModel;   
    isSaveButtonDisabled=false;
    childindex:number;
    Delimiter:any;
    ColumnsList:any; 
    isPageDisabled:Boolean=true;
    isSaveDisabled:Boolean=true;
    hasValidationError:boolean=false;
    isCopyButtonVisible:boolean=false;
    isdataCopy:boolean=false;
    componentNameNew = AppConstants.FILEMAPPINGNEW;
    componentNameModify = AppConstants.FILEMAPPINGMODIFY;
    componentTitle = AppConstants.FILEMAPPINGTITLE; 
    filteredMethodOptions: Observable<Array<MethodModel>>;
    selectedMethodValue: any;    
    methodFilterControl = new FormControl();  

    constructor(
        private service: FileMappingService,
        public dialogRef: MatDialogRef<FileMappingPopupComponent>,
        private toastr: ToastrService,
        private _messageService: MessageService,
        private methodService: MethodService,
        private sortPipe: SortByPipe,
        public dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any) {
            
        }


        
        ngOnInit(): void {            
            
            this.getMethods();
            this.getFriendlyHeaders();
            this.getDelimiters();
            this.editColumnMapping()
          
            this.newColumnMapping = {
            ColumnMappingId: 0,
            ColumnMappingRuleId: 0,
            Row: 0,
            Column: 0,
            UserFriendlyHeaderId: 0,
            HasQCData: false,
            CreatedDate: new Date(),
            CreatedBy: "",
            ModifiedDate: new Date(),
            ModifiedBy: "",
            IsActive: true,
            ColumnMappingRulesModel:null,
            SubRuleColumnMapping:[]
            };
            this.dynamicArray={    
                    ColumnMappingRuleId: 0,
                    Description: "",
                    MethodId: 0,
                    SheetName: "",  
                    Vessel:"",                 
                    Version: 1,
                    CreatedDate:  new Date(),
                    CreatedBy: "",
                    ModifiedDate: new Date(),
                    ModifiedBy: "",
                    IsActive: true,
                    ColumnMapping:[this.newColumnMapping],
                    Method:null
            } ;

        
        }
        @ViewChild('checkDuplicateFileMap')checkDuplicateFileMap: TemplateRef<any>
        addRow() { 
            this.newColumnMapping = {  
            ColumnMappingId: 0,
            ColumnMappingRuleId: 0,
            Row: 0,
            Column: 0,
            UserFriendlyHeaderId: 0,
            HasQCData: false,
            CreatedDate: new Date(),
            CreatedBy: "",
            ModifiedDate: new Date(),
            ModifiedBy: "",
            IsActive: true,
            ColumnMappingRulesModel:null,
            SubRuleColumnMapping:[]
            };
            this.dynamicArray.ColumnMapping.push(this.newColumnMapping);
              return true;  
          }  
          
          
          addSubRow(index) { 
            this.newSubRuleColumn = { 

                SubColumnMappingId: 0,
                ColumnMappingId: 0,
                UserFriendlyHeaderFirstId:0,
                UserFriendlyHeaderId: 0,
                DelimeterId: 0,
                Version: 1,
                CreatedDate: new Date(),
                CreatedBy: "",
                ModifiedDate: new Date(),
                ModifiedBy: "",
                IsActive: true,
                ColumnMapping:null

               }; 
                    
        this.dynamicArray.ColumnMapping[index].SubRuleColumnMapping.push(this.newSubRuleColumn); 
                                   
              //this.toastr.success('New row added successfully', 'New Row');
              return true;  
          }          
          deleteRow(index) {  
              if(this.dynamicArray.ColumnMapping.length ==1) {  
               // this.toastr.error("Can't delete the row when there is only one row", 'Warning');  
                  return false;  
              } else {  
                this.dynamicArray.ColumnMapping.splice(index, 1); 
                  return true;  
              }  
          }

          deletechildRow(index) {                       
            if( this.dynamicArray.ColumnMapping[index].SubRuleColumnMapping.length >0) {                 
                this.dynamicArray.ColumnMapping[index].SubRuleColumnMapping = []; 
                return true;
             }             
         }  
        

        onCopy()
        {
          this.isCopyButtonVisible=false;
          this.isdataCopy=true;         
          this.isPageDisabled=false;
          this.isSaveDisabled=false  
          this.dynamicArray.ColumnMappingRuleId=0;
          this.dynamicArray.MethodId=0; 
         this.popupHeader();      
        }

        onClear() {
            this.service.form.reset();
            this.service.initializeFormGroup();
        }
        
        getDimensions(id,Vessel) {  
          
          var obj = this.data.selectedGridData.filter(function(node) {           
              return node.MethodId==id && node.Vessel==Vessel;
          });          
        
            return obj;   
        }            
        onSubmit() { 
         
          if(this.dynamicArray.Vessel!="" && this.dynamicArray.Vessel!=null && this.dynamicArray.Vessel!=undefined)
          {             
          if(this.dynamicArray.ColumnMappingRuleId==undefined || this.dynamicArray.ColumnMappingRuleId==0)
            {                              
              if(this.checkValidRecord())
                {                  
                  let dialogConfig = this.service.getValidationDialogConfig();
                  this.dialog.open(FileMappingValidationPopupAlert, dialogConfig)
                }
                else
                {              
                    if(this.getDimensions(this.dynamicArray.MethodId,this.dynamicArray.Vessel)!=undefined && this.getDimensions(this.dynamicArray.MethodId,this.dynamicArray.Vessel)!=null && this.getDimensions(this.dynamicArray.MethodId,this.dynamicArray.Vessel)!="")
                    {             
                      let dialogConfig = this.service.getDuplicateRecordDialogConfig();
                      dialogConfig.data = { length: 0};
                      let dialogRef = this.dialog.open(FileMappingDuplicateRecordAlertComponent, dialogConfig);             
                      dialogRef.afterClosed().subscribe(
                          status => {
                            if (status === 'confirm') {
                              this.saveColumnMappingInfo();                      
                            }                      
                          });                                         
                      }
                      else
                      {                                          
                          this.saveColumnMappingInfo();                
                      } 
                    }
            }
            else
            { 
                this.service.update(this.dynamicArray).subscribe(result => {  
                    this._messageService.filter('refresh');                                  
                    this.toastr.success('Column mapping data updated successfully !!');  
                  },
                    error => {
                      this.toastr.error('Column mapping data updated failed !!');
                    }); 
                 this.onClose();
            }
          }
          else
          {
            let dialogConfig = this.service.getValidationDialogConfig();
            this.dialog.open(FileMappingVesselValidationPopupAlert, dialogConfig)
            
          }    
          } 


        checkValidRecord()
        {        
              this.hasValidationError=false;
              if(this.dynamicArray.MethodId==0 || this.dynamicArray.SheetName==null || this.dynamicArray.SheetName=="") 
              {                
                this.hasValidationError=true;
              }
                if(this.dynamicArray.ColumnMapping!=undefined && this.dynamicArray.ColumnMapping.length>0)
                {                 
                    this.dynamicArray.ColumnMapping.forEach(obj => {
                      if(obj.Row == 0 || obj.Column==0 || obj.UserFriendlyHeaderId==0)
                      {                                     
                        this.hasValidationError=true;                       
                      }
                      if(obj.SubRuleColumnMapping!=undefined && obj.SubRuleColumnMapping.length>0)
                    {
                      obj.SubRuleColumnMapping.forEach(childObj=> {
                        if(obj.SubRuleColumnMapping[0].UserFriendlyHeaderFirstId==0 || childObj.UserFriendlyHeaderId==0 || childObj.DelimeterId==0)
                        {                     
                          this.hasValidationError=true;
                        
                        }
                        });
                    }                    
                  });
              }             
          return  this.hasValidationError;
        }


        recordModify()
        {
            this.isSaveDisabled=false;
        }
           

       saveColumnMappingInfo()
        {         
              if(this.isdataCopy==true)
              {                      
                this.dynamicArray.ColumnMappingRuleId=0;
              }
               this.service.add(this.dynamicArray).subscribe(result => { 
                this._messageService.filter('refresh');                                  
                this.toastr.success('Column mapping data added successfully !!');  
                 },
                error => {
                this.toastr.error('Column mapping data added failed !!');
                }); 
            this.onClose(); 
        }

        onKeyDown(event) {
            this.isFormInvalid = false;
        }


        getMethods()
        {
            this.service.getMethods()
              .subscribe(async result => {
                this.methodsList=result; 
               this.methodsList = this.sortPipe.transform(this.methodsList,'asc','MethodName');
               await new Promise<void>((resolve, reject) => {

                this.filteredMethodOptions = this.methodFilterControl.valueChanges.pipe(
                  startWith(""),
                  map((value: string) => {
                    this.methodsList.forEach(option => {
                      option.show = option.MethodName
                        .toLocaleLowerCase()
                        .includes(value.toLowerCase());
                    });
                    return this.methodsList;
                  })
                )
                resolve();
          });
              },
                error => {
                  this.toastr.error('Get Methods request failed !!');
                })           
        }

         getDelimiters(){
            this.service.getDelimiters()
            .subscribe(result => {
              this.Delimiter=result;
              this.Delimiter = this.sortPipe.transform(this.Delimiter,'asc','DelimeterName');                           
            },
              error => {
                this.toastr.error('Get Delimiters request  failed !!');
            })
         }


         getFriendlyHeaders(){
            this.service.getFriendlyHeaders()
            .subscribe(result => {
              this.ColumnsList=result; 
              this.ColumnsList = this.sortPipe.transform(this.ColumnsList,'asc','UserFriendlyHeaderName');                          
            },
              error => {
                this.toastr.error('Get friendly headers request  failed !!');
              })
        }

        getToolTipDelimiters(data){
         
          let DelimeterName="";         
          if(this.Delimiter!=undefined)  
          {     
            this.Delimiter.forEach(element => {
            if(element.DelimeterId==data)
            {
              DelimeterName= element.DelimeterName
            }
          });
        }
          return DelimeterName;
        }
        getToolTipFriendlyHeaders(data){
         
          let UserFriendlyHeaderName="";         
          if(this.ColumnsList!=undefined)  
          {     
           this.ColumnsList.forEach(element => {
            if(element.UserFriendlyHeaderId==data)
            {
              UserFriendlyHeaderName= element.UserFriendlyHeaderName
            }
          });
        }
          return UserFriendlyHeaderName;
        }


        editColumnMapping()
        {           
           if(this.data.selectedData!=undefined && this.data.selectedData.FileMappingId!=undefined)
           {
            this.isPageDisabled=true;
            this.isSaveDisabled=true; 
            this.isCopyButtonVisible=true;              
            this.service.getById(this.data.selectedData.FileMappingId).subscribe(result => {
                this.dynamicArray=result; 
              },
                error => {
                  this.toastr.error('Edit file mapping failed !!');
                })
           }
           else
           {              
            this.isPageDisabled=false;
            this.isSaveDisabled=false; 
            this.isCopyButtonVisible=false;          
           }
        }       

        onClose() {
            this.service.form.reset();
            this.service.initializeFormGroup();
            this.dialogRef.close();
        }

        displayInformation()
        {
          let dialogConfig = this.service.getDialogConfig();
          this.dialog.open(InformationPopupComponent, dialogConfig);
        }

        popupHeader() { 
           let result =this.data.selectedData!=undefined?this.componentNameModify: this.componentNameNew;
          if( this.isdataCopy==true)
          {
            result=this.componentNameNew
          }          
          return result
           
        }        

           compareFn: ((f1: any, f2: any) => boolean) | null = this.compareByValue;
 
           compareByValue(f1: any, f2: any) { 
               return f1 && f2 && f1.MethodName === f2.MethodName; 
           }
  }