import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'date-alert-dialog',
  templateUrl: './date-alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.css']
})

export class DateAlertDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) private data: any) { 
  }
 }