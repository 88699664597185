import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { SlideCellRendererComponent } from '../../custom/slide-cell-renderer/slide-cell-renderer.component';
import { EditButtonRendererComponent } from '../../custom/edit-button-renderer/edit-button-renderer.component';
import { MatDialog } from '@angular/material/dialog';
import { FileMappingPopupComponent } from './file-mapping-popup.component';
import * as _ from 'lodash';
import { FileMappingModel } from 'src/app/models/file-mapping.model';
import { FileMappingService } from 'src/app/services/file-mapping-service/file-mapping.service';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from 'src/app/services/message-service';
import { GridSizeChangedEvent } from 'ag-grid-community';
import { DatePipe } from '@angular/common';
import { AppConstants } from 'src/app/common-utility/appconstants';
@Component({
    templateUrl: '../../shared/grid-component/grid.component.html',
    styleUrls: ['./file-mapping.component.css']
})

export class FileMappingComponent implements OnInit {
  request: FormGroup;
  rowData: FileMappingModel[] = [];
  private gridApi;
  private gridColumnApi;
  public context: any;
  public subscription: any;
  componentTitle = AppConstants.FILEMAPPINGTITLE;
  componentName = AppConstants.FILEMAPPINGNAME; 


  constructor(private fb: FormBuilder, private dialog: MatDialog,
    private toastr: ToastrService,
    private service: FileMappingService,
    public datepipe: DatePipe,
    private _messageService: MessageService) {
    this.context = { componentParent: this };
    this._messageService.listen().subscribe((m:any) => {
      this.refreshGrid();
  })
  }

  defaultColDef: {
    flex: 1,
    enableValue: true,
    enableRowGroup: true,
    enablePivot: true,
    sortable: true,
    filter: true,
  }

  frameworkComponents = {
    slideCellRenderer: SlideCellRendererComponent,
    btnEditRenderer: EditButtonRendererComponent
  };

  columnDefs = [
    { headerName: 'Name', field: 'MethodName', sortable: true, filter: true, width: 135, tooltipValueGetter: (params) => params.value },
    { headerName: 'Vessel', field: 'Vessel', sortable: true, filter: true, width: 105, tooltipValueGetter: (params) => params.value  },
    { headerName: 'Description', field: 'Description', sortable: true, filter: true, width: 180, tooltipValueGetter: (params) => params.value  },
    { headerName: 'Version', field: 'Version', sortable: true, filter: true, width: 160, tooltipValueGetter: (params) => params.value  },
    { headerName: 'Modified By', field: 'ModifiedBy', sortable: true, filter: true, width: 105, tooltipValueGetter: (params) => params.value  },
    { headerName: 'Modified Date', field: 'ModifiedDate', sortable: true, filter: true, width: 110 },
    { headerName: 'Active', field: 'IsActive', cellRenderer: 'slideCellRenderer', sortable: true, filter: true, width: 75 },
    {
      field: '',
      cellRenderer: 'btnEditRenderer',
      width: 75,
      tooltipValueGetter: (params) => 'Edit' 
    },
  ];

  ngOnInit() {
    this.getFileMappingInfo();
  }

  getFileMappingInfo() {
    this.service.get()
      .subscribe(
        result => {
          this.rowData = [];
          result.forEach(r => {
            this.rowData.push({
              FileMappingId: r.ColumnMappingRuleId,
              SheetName: r.SheetName,
              MethodName:r.MethodName,
              MethodId:r.MethodId,
              Description: r.Description, 
              Vessel:r.Vessel,
              Version: r.Version,   
              IsActive: r.IsActive,
              CreatedDate: r.CreatedDate,
              CreatedBy: r.CreatedBy,
              ModifiedDate: this.datepipe.transform(r.ModifiedDate,'MM/dd/yyyy'),
              ModifiedBy: r.ModifiedBy,
              show: true
            })
          })
        },
        error => {
          console.log(error);
        }
      )
  }

  add() {
    let dialogConfig = this.service.getDialogConfig();
   // this.service.populateForm(this.rowData);
    dialogConfig.data = {selectedGridData:this.rowData};
    this.dialog.open(FileMappingPopupComponent, dialogConfig);
  }

  update(row) {
   
    if (row.colDef != undefined && row.colDef !== '' && row.colDef !== null && row.colDef.field == '') {     
      //let data = this.rowData.find(x=> x.FileMappingId === row.data.FileMappingId);    
      this.service.populateForm(row.data);
      let dialogConfig = this.service.getDialogConfig();
      dialogConfig.data = {selectedData: row.data,selectedGridData:this.rowData};
      this.dialog.open(FileMappingPopupComponent, dialogConfig);
    }
  }

  updateStatus(fileMappingModel: FileMappingModel, isActive: boolean) {
  
    this.service.updateStatus(fileMappingModel.FileMappingId, isActive)
      .subscribe(result => {
        this.toastr.success('File mapping updated successfully !!');
        this.refreshGrid();
      },
        error => {
          this.toastr.error(error, 'File mapping update failed !!');
        })
  }

  refreshGrid() {
    this.getFileMappingInfo();
    this.gridApi.refreshClientSideRowModel('aggregate')
    this.onCloseEvent();
  }

  cancelStatusUpdate() {
    this.service.cancelStatusUpdate();
  }

  onCloseEvent() {
    if (this.subscription !== undefined) {
      this.subscription.unsubscribe();
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.setDomLayout("autoHeight");
  }

  onGridSizeChanged(params: GridSizeChangedEvent) {
    params.api.sizeColumnsToFit();
  }
}