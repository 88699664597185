import { Injectable } from '@angular/core';
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot
} from '@angular/router';
import { AppConstants } from '../common-utility/appconstants';
import { CommonService } from './common/common.service';
import { UserClaim } from '../models/userClaim';
@Injectable()
export class RoleGuardService implements CanActivate {
    constructor(public router: Router, private commonService: CommonService) { }
    canActivate(route: ActivatedRouteSnapshot): boolean {

        // this will be passed from the route config
        // on the data property
        const expectedRole = route.data.expectedRoles;

        if (!this.doesUserHaveRole(expectedRole)) {
            this.router.navigate(['unauthorized']);
            return false;
        }
        return true;
    }

    public doesUserHaveRole(roleName): boolean {
        let claims = new UserClaim();
        let rolesData = this.commonService.getSessionStorageValue(AppConstants.UIUSERROLE);

        if (rolesData !== '' && rolesData !== null) {
            claims = JSON.parse(rolesData) as UserClaim;
            let userHasRole = false;
            roleName.forEach(element => {
                if (claims.securityRoles.indexOf(element) !== -1) {
                    userHasRole = true;
                }
            });
            // if (claims.securityRoles.indexOf(roleName) !== -1) {
            //     userHasRole = true;
            // }
            return userHasRole;
        }
    }
}