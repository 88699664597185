﻿import { RoleModel } from './role.model';

export class UserModel {
    Id?: number;
    UserId?: number
    FirstName?: string
    LastName?: string
    Email?: string
    CreatedBy?: number
    CreatedDate?: Date
    ModifiedBy?: number
    ModifiedDate?: Date
    IsActive?: boolean;
    Roles?: Array<RoleModel> = [];
}

