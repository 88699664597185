import { Component, Inject, LOCALE_ID, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormArray, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ExperimentTypeService } from '../services/experiment-type-service/experiment-type.service';
import { GroupTypeService } from '../services/group-service/group.service';
import { MethodService } from '../services/method-service/method.service';
import { ProjectService } from '../services/project-service/project.service';
import { RequestService } from '../services/request-service/request.service';
import { VesselService } from '../services/vessel-service/vessel.service';
import { SampleDaysMethodsData } from '../models/sample-days-methods.model';
import { AlertDialogComponent } from './group-alerts/alert-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConstants } from '../common-utility/appconstants';
import { DateAlertDialogComponent } from './group-alerts/date-alert-dialog.component';
import { LoadingService } from '../services/loading.service';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/pairwise';
import { GroupRequestModel } from '../models/group-request.model';
import { RequestorEmailModel } from '../models/group-request.model';
import { UserClaim } from 'src/app/models/userClaim';
import { CommonService } from 'src/app/services/common/common.service';
import { ChangeDetectorRef } from '@angular/core';
import { SortByPipe } from '../shared/sort/sort-pipe';
import * as _ from 'lodash';
import { ClonerService } from '../services/deep-clone/deep-clone.service';
import { StrainInfoComponent } from './strain-info-popup/strain-info-popup.component';
import { startWith, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { FormControl } from "@angular/forms";
import { GroupTypeModel } from '../models/group.model';
import { MethodModel } from '../models/method.model';
import { DataService } from '../services/data.service';
import { ConfigurationSettings } from '../configuration-settings';
import { formatDate } from '@angular/common';

@Component({
  templateUrl: './group-request.component.html',
  styleUrls: ['./group-request.component.css'],
})

export class GroupRequestComponent implements OnInit {

  selectedMethodsValue: any[] = [];
  selectedDayMethodValue: [] = [];
  methodsList: MethodModel[] = [];
  filteredMethodOptions: Observable<Array<MethodModel>>;
  selectedMethodsList: any[] = [];
  selectedMethodsListBackup: any[] = [];
  daysMethodsList: [];
  projectsList: any[] = [];
  selectedValue: [];
  groupsList: GroupTypeModel[] = [];
  filteredGroupOptions: Observable<Array<GroupTypeModel>>;
  vesselsList: any[] = [];
  experimentTypeList: any[] = [];
  hasExperimentTypes: boolean = false;
  sampleNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  isTableDisabled: boolean = true;
  disableForm: boolean = false;
  activeRequest: GroupRequestModel;
  minDate: Date;
  maxDate: Date;
  claims: UserClaim;
  public showallrecords: boolean = true;
  expId: any;
  oldExpId: any;
  selected = ' ';
  dateValidator: any;
  tableData: Map<number, string> = new Map();
  strainInfoHTPGrid: any[] = [];
  strainInfoOtherGrid: any[] = [];
  groupRequestsMethods: any[];
  isExperimentSaved: boolean = false;
  name = 'Paste it';
  val: any;
  displayedColumns: string[];
  dataSource: any[] = [];
  isSampleDaysSelected: boolean = false;
  sampleDaysBackup: any[] = [];
  expIdForPopup: any
  sampleInfoExistsMessage: string;
  groupFilterControl = new FormControl();
  methodFilterControl = new FormControl();
  requestorEmailModel: any;
  requestorEmailList: any[] = [];
  requestorEmailId: any = "";
  emailId: string = "";
  requestorEmailIdMsg: string;
  previousStartDate: Date;
  previousSelectedStartDate: Date;
  createdDate: Date;
  modifiedDate: Date;
  public groups;
  //Using Dictionary
  public map = new Map<string, any[]>();
 


  @ViewChild('callDialog') callDialog: TemplateRef<any>;
  @ViewChild('expMethodStatus') expMethodStatus: TemplateRef<any>;
  @ViewChild('updateExperimentStatusDialog') updateExperimentStatusDialog: TemplateRef<any>;
  @ViewChild('saveDialog') saveDialog: TemplateRef<any>;
  @ViewChild('methodStatus') methodStatus: TemplateRef<any>;
  @ViewChild('updateSampleInfoDialog') updateSampleInfoDialog: TemplateRef<any>;
  @ViewChild('requestorEmailDeleteConfirmationDialog') requestorEmailDeleteConfirmationDialog: TemplateRef<any>;
  @ViewChild('requestorEmailExistDialog') requestorEmailExistDialog: TemplateRef<any>;
  @ViewChild('pageNavigationConfirmationDialog') pageNavigationConfirmationDialog: TemplateRef<any>;
  

  constructor(private fb: FormBuilder, public dialog: MatDialog,
    private requestService: RequestService,
    private methodService: MethodService,
    private projectService: ProjectService,
    private groupService: GroupTypeService,
    private vesselService: VesselService,
    private toastr: ToastrService,
    private router: Router,
    private loadingService: LoadingService,
    private clonerService: ClonerService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private sortPipe: SortByPipe,
    private service: DataService,
    private experimentTypeService: ExperimentTypeService,
    private commonService: CommonService,@Inject(LOCALE_ID) private locale: string) {

    this.populateModelData();

    this.setDateLimit();

    this.requestService.form.enable();


  }

  strainInfoBackupOnLoad: any[] = [];
  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.expId = params['exp'];


    });

    if (this.expId != undefined && this.expId !== '' && this.expId !== null) {
      this.isExperimentSaved = true;
      this.requestService.form.reset();
      this.clearFormArray(this.formArr);
      this.requestService.initializeFormGroup();

      this.isTableDisabled = false;
      this.disableForm = false;
      this.requestService.form.enable();

      this.getExperimentInfo(this.showallrecords);
    }
    else {
      this.isExperimentSaved = false;
      this.requestService.form.reset();
      this.clearFormArray(this.formArr);
      this.requestService.initializeFormGroup();
      this.sampleNumbers.forEach(element => {
        this.formArr.push(this.requestService.initRows(element));
      });
    }

  }
  getStrainInformation(GroupRequestId) {
    this.requestService.getStrainInformation(GroupRequestId)
      .subscribe(result => {
        if (result != undefined && result.StrainInformation.length > 0) {
          this.strainInfoBackupOnLoad = result.StrainInformation;
          if (result.StrainInformation[0].GroupType == 'HTP') {
            this.strainInfoHTPGrid = result.StrainInformation;
          }
          else {
            this.strainInfoOtherGrid = result.StrainInformation;
          }
        }
      },
        error => {
          this.toastr.error('Error to get HTP information!!');
        });
  }

  compareTowArray(arrayone, arraytwo) {
    var array1 = arrayone.sort();
    var array2 = arraytwo.sort();

    var is_same = array1.length == array2.length && array1.every(function (element, index) {
      return element === array2[index];
    });
    return is_same;
  }

  getSampleDays() {
    let sampleDays = [];
    if (this.requestService.form.value.Rows === undefined && this.sampleDaysBackup.length > 0)
      sampleDays = this.sampleDaysBackup;
    else
      sampleDays = this.requestService.form.value.Rows;
    if (sampleDays.length > 0)
      return sampleDays.filter(day => day.Active === true);
  }

  displaySampleInfoButton() {
    let daysSelected = this.getSampleDays();
    this.isSampleDaysSelected = daysSelected.length > 0 ? true : false;
  }

  setDateLimit() {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const currentDate = new Date().getDate();
    let rolesData = this.commonService.getSessionStorageValue(AppConstants.UIUSERROLE);

    if (rolesData !== '' && rolesData !== null) {
      this.claims = JSON.parse(rolesData) as UserClaim;
      if (this.claims.isOneRing_Analyst || this.claims.isOneRing_Admin) {
        this.minDate = new Date(currentYear - 1, currentMonth, currentDate);             
      }
      else {
        this.minDate = new Date(currentYear, currentMonth - 1, currentDate);        
      }      
    }
    
    // this.minDate = new Date(currentYear - 1, currentMonth, currentDate);
    this.maxDate = new Date(currentYear + 1, currentMonth, currentDate);
  }

  tableClick() {
    if (this.isTableDisabled) {
      let dialogConfig = this.requestService.getDialogConfig();
      dialogConfig.data = { isSaveEnabled: false };
      this.dialog.open(AlertDialogComponent, dialogConfig);
    }
  }

  onRowSelect(event, itemrow) {
    if (event.checked) {
      itemrow.patchValue({ Methods: this.selectedMethodsValue });
      itemrow.patchValue({ MethodsCount: this.selectedMethodsValue.length })
    }
    else {
      if (itemrow.controls.Methods.value.some(m => m.MethodStatus === AppConstants.InProgress || m.MethodStatus === 'Completed')) {
        this.dialog.open(this.methodStatus);
        itemrow.patchValue({ Active: true });
      }
      else if (this.hasSampleInfoExistsForDay(itemrow.value.Day)) {
        this.displaySampleInfoExists(AppConstants.SampleInfoExistsForDay);
        itemrow.patchValue({ Active: true });
      }
      else {
        itemrow.patchValue({ Methods: [] });
        itemrow.patchValue({ MethodsCount: '' })
      }

    }
    this.displaySampleInfoButton();
  }

  hasSampleInfoExistsForDay(day) {
    return this.strainInfoHTPGrid.filter(sampleData => sampleData.FermDay.toString() == day.toString()).length > 0 ||
      this.strainInfoOtherGrid.filter(sampleData => sampleData.FermDay.toString() == day.toString()).length > 0 ? true : false;
  }

  displaySampleInfoExists(messageText) {
    this.sampleInfoExistsMessage = messageText;
    let dialogConfig = this.requestService.getDialogConfig();
    this.dialog.open(this.updateSampleInfoDialog, dialogConfig);
  }

  experimentStartDateChange(date) {
    let currentDate = new Date();
    let dialogRef;
    date = new Date(date);
    var diff_in_time = date.getTime() - currentDate.getTime();

    let diff_in_days = diff_in_time / (1000 * 3600 * 24);
    if (diff_in_days <= 4) {
      dialogRef = this.dialog.open(DateAlertDialogComponent);
    }
    return dialogRef;
  }

  public onChange(event) {
    let dialogConfig = this.requestService.getDialogConfig();
    dialogConfig.data = { isActive: event.checked };
    let dialogRef = this.dialog.open(this.updateExperimentStatusDialog, dialogConfig);
    dialogRef.afterClosed().subscribe(
      status => {
        if (status === 'confirm') {
          let isRequester: boolean;
          isRequester = this.commonService.isLoggedInUserRequester();
          this.requestService.updateStatus(this.requestService.form.controls['GroupRequestId'].value, !event.checked, isRequester)
            .subscribe(result => {
              this.toastr.success('Request status updated successfully !!');
              this.reloadPage(this.requestService.form.controls['GroupRequestId'].value);
            },
              error => {
                this.toastr.error('Request status update failed !!');
              })
        }
        else {
          this.requestService.form.controls['IsActive'].patchValue(!event.checked);
        }
      },
      error => {
        console.log(error);
      },
      () => {
        // this.params.context.componentParent.refreshGrid(AppConstants.UPDATESTATUS);
      }
    );
  }

  copyRequest() {
    this.confirmPageNavigation(AppConstants.EmptySpace);
  }

  InitializeCopyRequest() {
    this.expId = null;
      this.requestService.form.enable();
      this.disableForm = false;
      this.createdDate = this.previousStartDate = this.previousSelectedStartDate = this.modifiedDate = undefined;
      this.requestService.form.controls['GroupRequestId'].patchValue('');
      this.requestService.form.controls['ExperimentId'].patchValue('');
      if(this.experimentTypeList.length == 0)
        this.requestService.form.controls['ExperimentType'].disable();

      this.hasExperimentTypes = this.experimentTypeList.length > 0 ? true : false;
      this.setDateLimit();
      this.requestService.form.controls['ExperimentStartDate'].patchValue(null);
      this.requestService.form.controls['ExperimentStartDate'].validator = this.dateValidator;
      this.requestService.form.controls['TotalVesselsSubmitted'].patchValue('');
      this.requestService.form.controls['IsActive'].patchValue(true);
      this.strainInfoBackupOnLoad = [];
      this.strainInfoHTPGrid = [];
      this.strainInfoOtherGrid = [];
      this.isExperimentSaved = false;
      this.selectedMethodsValue.forEach(x => { x.MethodStatus = AppConstants.New });
      this.selectedMethodsValue.forEach(x => { 
        if(x.SampleDays !== null && x.SampleDays !== undefined) {
          x.SampleDays.forEach(element => {
            element.MethodStatus = AppConstants.New;
          });
        } });
      this.selectedMethodsValue.forEach(element => {
        element.SampleDays.forEach(e => {
            var rows = <FormArray>this.requestService.form.get('Rows');
            
            rows.controls[e.Day].get('Methods').patchValue([element]);
        });      
      });

      this.selectedMethodsListBackup.forEach(x => { x.MethodStatus = AppConstants.New });
      this.selectedMethodsListBackup.forEach(x => { 
        if(x.SampleDays !== null && x.SampleDays !== undefined) {
          x.SampleDays.forEach(element => {
            element.MethodStatus = AppConstants.New;
          });
        } });

        this.tableData.forEach((value: any, key: number) => {
          let tableMethods = _.values(value);
          tableMethods.forEach(x => { x.MethodStatus = AppConstants.New });
        });

      }

  public experimentUIdata: GroupRequestModel[] = [];
  getExperimentInfo(showallrecords) {

    const sampleNumber = [];
    const sampleNumbers = [];
    this.expId;
    this.experimentTypeService.get()
      .subscribe(
        result => {
          this.experimentTypeList = result.filter(r => r.IsActive === true);
        },
        error => {
          console.log(error);
        }
      );

    this.requestService.getDetailsByRequestId(this.expId)

      .subscribe(

        result => {
          result = result;
          this.oldExpId = result[0].ExperimentId;
          this.expIdForPopup = result[0].ExperimentId;
          this.createdDate = result[0].CreatedDate;
          this.modifiedDate = result[0].ModifiedDate;
          this.requestService.form.controls['GroupRequestId'].patchValue(result[0].GroupRequestId);
          this.requestService.form.controls['ExperimentId'].patchValue(result[0].ExperimentId);
          this.requestService.form.controls['ExperimentPurpose'].patchValue(result[0].ExperimentPurpose);
          this.requestService.form.controls['SpecialInstructions'].patchValue(result[0].SpecialInstructions);
          this.requestService.form.controls['TotalVesselsSubmitted'].patchValue(result[0].TotalVesselsSubmitted);
          this.requestService.form.controls['ExperimentStartDate'].patchValue(result[0].ExperimentStartDate);
          this.previousSelectedStartDate = result[0].ExperimentStartDate;
          this.requestService.form.controls['AnalysisFrequency'].patchValue(result[0].AnalysisFrequency);
          this.dateValidator = this.requestService.form.controls['ExperimentStartDate'].validator;
          this.previousStartDate = result[0].PreviousStartDate;
          if (result[0].GroupRequestId != undefined) {
            this.getStrainInformation(result[0].GroupRequestId);
            this.getRequestorEmailId(result[0].GroupRequestId)
          }
          if (this.projectsList.filter((p: any) => p.ProjectId === result[0].Project.ProjectId).length === 0) {
            this.projectsList.push(result[0].Project);
          }
          this.requestService.form.controls['Project'].patchValue(result[0].Project);

          if (this.groupsList.filter((g: any) => g.GroupTypeId === result[0].GroupType.GroupTypeId).length === 0) {
            this.groupsList.push(result[0].GroupType);
          }
          this.requestService.form.controls['GroupType'].patchValue(result[0].GroupType);

          let selectedGroup: any;
          selectedGroup = this.groupsList.filter((r: any) => r.GroupTypeId === result[0].GroupType.GroupTypeId)[0];
          if (selectedGroup.ExperimentTypes != null && selectedGroup.ExperimentTypes.length > 0) {
            this.experimentTypeList = selectedGroup.ExperimentTypes.filter((g: any) => g.IsActive === true);
            this.hasExperimentTypes = true;
          }
          else {
            this.experimentTypeList = [];
            this.hasExperimentTypes = false;
          }
          if (this.experimentTypeList.length > 0 || result[0].ExperimentType != null) {
            if (this.experimentTypeList.filter((exp: any) => exp.ExperimentTypeId === result[0].ExperimentType.ExperimentTypeId).length === 0) {
              this.experimentTypeList.push(result[0].ExperimentType);
            }
            this.requestService.form.controls['ExperimentType'].patchValue(result[0].ExperimentType);
          }

          if (this.vesselsList.filter((v: any) => v.VesselId === result[0].Vessel.VesselId).length === 0) {
            this.vesselsList.push(result[0].Vessel);
          }
          this.requestService.form.controls['Vessel'].patchValue(result[0].Vessel);

          result[0].AnalyticalMethods.forEach(mt => {
            if (this.methodsList.filter((m: any) => m.MethodId === mt.MethodId).length === 0) {
              this.methodsList.push(mt);
            }
          });
          this.selectedMethodsList = result[0].AnalyticalMethods;
          this.selectedMethodsListBackup = this.selectedMethodsList;
          this.groupRequestsMethods = this.clonerService.deepClone(this.selectedMethodsList);
          this.requestService.form.controls['AnalyticalMethods'].patchValue(this.selectedMethodsList);
          this.requestService.form.controls['IsActive'].patchValue(!result[0].IsActive);

          this.sampleNumbers = [];
          result[0].Rows.forEach(element => {
            sampleNumbers.push(element.Day);
          });

          const max = sampleNumbers.reduce((a, b) => Math.max(a, b)) <= 9 ? 9 : sampleNumbers.reduce((a, b) => Math.max(a, b));
          const min = sampleNumbers.reduce((a, b) => Math.min(a, b));
          let date;
          for (var i = 0; i <= max; i++) {
            this.sampleNumbers.push(i);
          }
          this.sampleNumbers.forEach(element => {
            if (element + 1 <= max) {
              this.formArr.push(this.requestService.initRows(element + 1));
            }
          });
          result[0].Rows.forEach(element => {
            for (var i = 0; i < this.sampleNumbers.length; i++) {
              if (this.sampleNumbers[i] === element.Day) {
                this.setFormArrayValue(this.sampleNumbers[i], element.MethodsCount, element.Day, element.Methods);
              }

              if (min === element.Day) {
                date = new Date(this.requestService.form.value.ExperimentStartDate);
                date.setDate(date.getDate() + min);
              }
            }
          });
          let rolesData = this.commonService.getSessionStorageValue(AppConstants.UIUSERROLE);

          if (rolesData !== '' && rolesData !== null) {
            this.claims = JSON.parse(rolesData) as UserClaim;
            // var experimentDate = new Date(this.requestService.form.value.ExperimentStartDate);
            date.setHours(0, 0, 0, 0);
            if ((!(this.claims.isOneRing_Analyst || this.claims.isOneRing_Admin) && (date <= this.minDate)) || result[0].ExperimentStatus === AppConstants.Complete) {
              this.requestService.form.disable();
              this.disableForm = true;
              this.requestService.form.controls['GroupRequestId'].enable();
            }
            else if ((this.claims.isOneRing_Analyst || this.claims.isOneRing_Admin)) {

              if (result[0].ExperimentStatus === AppConstants.Cancel) {
                this.requestService.form.disable();
                this.disableForm = true;
                this.requestService.form.controls['IsActive'].enable();
                this.requestService.form.controls['GroupRequestId'].enable();
              }
              else {
                var experimentDate = new Date(this.requestService.form.value.ExperimentStartDate);

                if (experimentDate < this.minDate) {

                  this.minDate = new Date(this.requestService.form.value.ExperimentStartDate);
                  
                  this.maxDate.setFullYear(this.minDate.getFullYear() + 1);
                  this.minDate.setFullYear(this.minDate.getFullYear() - 1);
                  this.maxDate.setMonth(this.minDate.getMonth());
                  this.maxDate.setDate(this.minDate.getDate());
                  this.requestService.form.get('ExperimentStartDate').reset();
                  this.dateValidator = this.requestService.form.controls['ExperimentStartDate'].validator;
                  this.requestService.form.controls['ExperimentStartDate'].clearValidators();
                  this.requestService.form.controls['ExperimentStartDate'].patchValue(result[0].ExperimentStartDate);
                  this.requestService.form.get('ExperimentStartDate').setValidators([Validators.required]);
                  this.cdr.detectChanges();
                }
              }
            }
          }
          this.experimentUIdata = result;
          var data = result[0].Rows;
          this.sampleDaysBackup = data;
          data.forEach(element => {
            this.tableData.set(element.Day, { ...element.Methods });
          });
          this.activeRequest = this.requestService.form.value;
          this.displaySampleInfoButton();
        },
        error => {
          console.log(error);
        }

      )
  }

  checkBackdatedDate() {
    var d = new Date('0001-01-01 00:00:00.0000000');
    d.setFullYear(0);
    if(this.previousStartDate !== undefined && (this.dateFormatter(this.previousStartDate) !== this.dateFormatter(d) && this.dateFormatter(this.previousStartDate) > this.dateFormatter(this.requestService.form.controls['ExperimentStartDate'].value))) {
      return true;
    }
    else
      return false;
  }

  compareDate() {
    if(this.createdDate !== undefined && this.modifiedDate !== undefined && this.createdDate === this.modifiedDate && this.createdDate > this.previousStartDate) {
      return true;
    }
    else 
      return false;
  }

  dateFormatter(data) {
    return data ? formatDate(data  , 'MM/dd/yyyy', this.locale) : '';
  }

  public setFormArrayValue(i, count, day, methods) {
    const controlArray = <FormArray>this.requestService.form.get('Rows');
    controlArray.controls[i].get('MethodsCount').setValue(count);
    controlArray.controls[i].get('Day').setValue(day);
    controlArray.controls[i].get('Methods').setValue(methods);
    controlArray.controls[i].patchValue({ Active: true });
  }

  editGroupSelection(event) {
    if (event.value.ExperimentTypes.length > 0) {
      this.experimentTypeList = event.value.ExperimentTypes.filter((g: any) => g.IsActive === true);
      this.hasExperimentTypes = true;
    }
    else {
      this.experimentTypeList = [];
      this.hasExperimentTypes = false;
    }
  }

  onSampleDayMethodChange(event, itemrow) {
    var row = this.tableData.get(itemrow.value.Day);
    if (this.requestService.form.value.GroupRequestId != undefined &&
      this.requestService.form.value.GroupRequestId != null &&
      this.tableData !== undefined &&
      row !== undefined && row !== null) {
      var inProgress: boolean = false;
      var existingMethods;
      var itemRow = itemrow.controls.Methods;
      var meth = _.values(row);
      var newMethodsAdded = event.value.filter(i => i.MethodStatus === undefined || i.MethodStatus === null);
      meth = meth.filter(m => m.MethodStatus !== AppConstants.New);
      existingMethods = meth;
      newMethodsAdded.forEach(element => {
        if (!existingMethods.some(i => i.MethodId === element.MethodId))
          existingMethods.push(element);
      });


      for (var i = 0; i <= meth.length - 1; i++) {
        var exists = itemRow.value.filter(im => im.MethodId === meth[i].MethodId)
        if (exists.length === 0) {
          inProgress = true;
          this.dialog.open(this.methodStatus);
          itemrow.patchValue({ Active: true });
          itemrow.patchValue({ Methods: existingMethods });
          itemrow.patchValue({ MethodsCount: existingMethods.length });
          break;
        }
        else {
          continue;
        }
      }
      itemrow.patchValue({ Active: true });
      itemrow.patchValue({ Methods: existingMethods });
      itemrow.patchValue({ MethodsCount: existingMethods.length });
    }
    else {
      itemrow.patchValue({ Active: true });
      itemrow.patchValue({ MethodsCount: event.value.length });
    }

    if (event.value.length === 0 && !inProgress) {
      if (this.hasSampleInfoExistsForDay(itemrow.value.Day)) {
        this.displaySampleInfoExists(AppConstants.SampleInfoExistsForMethod);
        let methods: any[] = [];
        if (_.values(row).length > 0)
          methods = _.values(row);
        else
          methods = this.selectedMethodsValue;
        itemrow.patchValue({ Active: true });
        itemrow.patchValue({ Methods: methods });
        itemrow.patchValue({ MethodsCount: methods.length });
      }
      else {
        itemrow.patchValue({ Active: false });
        itemrow.patchValue({ MethodsCount: '' });
      }

    }

    this.displaySampleInfoButton();

  }

  editMethodsSelection(event) {
    if (this.methodRemoveAllowed(event)) {
      this.updateSampleTable(event);
    }
    else {
      this.dialog.open(this.methodStatus);
      this.setRemovedMethodsBack();
    }
    this.displaySampleInfoButton();
    this.selectedMethodsListBackup = this.selectedMethodsValue;
  }

  methodRemoveAllowed(event) {
    let isAllowed: boolean = true;
    if (this.selectedMethodsListBackup.length > event.value.length) {
      let unSelectedMethod = this.selectedMethodsListBackup.filter(x => event.value.every(y => y.MethodName != x.MethodName));
      this.tableData.forEach((value: any, key: number) => {
        let tableMethods = _.values(value);
        if (tableMethods.some(x => x.MethodName == unSelectedMethod[0].MethodName && x.MethodStatus !== AppConstants.New)) {
          isAllowed = false;
        }
      });
    }

    return isAllowed;
  }

  updateSampleTable(event) {
    let sampleInfoExits: boolean = false;
    let formControls = this.formArr.controls as FormGroup[];
    sampleInfoExits = event.value.length > 0 ? this.sampleInfoExistsMethodRemoved(formControls, event) : this.sampleInfoExistsAllMethodsRemoved(formControls);
    if (sampleInfoExits) {
      this.displaySampleInfoExists(AppConstants.SampleInfoExistsForMethod);
      this.setRemovedMethodsBack();
    }
    else {
      if (event.value.length > 0) {
        this.removeMethodsInSampleDays(formControls, event)
        this.isTableDisabled = false;
      }
      else {
        this.removeAllSampleDays(formControls);
        this.isTableDisabled = true;
      }
    }
  }

  sampleInfoExistsMethodRemoved(formControls, event) {
    let isSampleInfoExists: boolean = false;
    for (let element of formControls) {
      let ele = element.get('Active');
      if (ele !== undefined && ele.value) {
        //Check is there any methods used for sample day which are not selected in the analytical methods dropdown 
        if (this.checkSampleDayMethodExists(element, event)) {
          if (element.value.Methods.length === 1 && this.hasSampleInfoExistsForDay(element.value.Day)) {
            isSampleInfoExists = true;
            break;
          }
        }
      }
    }
    return isSampleInfoExists;
  }

  sampleInfoExistsAllMethodsRemoved(formControls) {
    let isSampleInfoExists: boolean = false;
    for (let element of formControls) {
      let ele = element.get('Active');
      if (ele !== undefined && ele.value && this.hasSampleInfoExistsForDay(element.value.Day)) {
        isSampleInfoExists = true;
        break;
      }
    }
    return isSampleInfoExists;
  }

  setRemovedMethodsBack() {
    this.requestService.form.controls['AnalyticalMethods'].patchValue(this.selectedMethodsListBackup);
  }

  removeAllSampleDays(formControls) {
    formControls.forEach(element => {
      let ele = element.get('Active');
      if (ele !== undefined) {
        this.removeSampleDay(element);
      }
    });
  }

  checkSampleDayMethodExists(element, event) {
    return element.value.Methods.filter(x => event.value.every(y => y.MethodName != x.MethodName)).length > 0
  }

  updateMethodCount(element, event) {
    element.patchValue({ MethodsCount: element.value.Methods.filter(x => event.value.some(y => y.MethodName == x.MethodName)).length });
    element.patchValue({ Methods: element.value.Methods.filter(x => event.value.some(y => y.MethodName == x.MethodName)) });
  }
  removeMethodsInSampleDays(formControls, event) {
    for (let element of formControls) {
      let ele = element.get('Active');
      if (ele !== undefined && ele.value) {
        //Check is there any methods used for sample day which are not selected in the analytical methods dropdown
        if (this.checkSampleDayMethodExists(element, event)) {
          if (element.value.Methods.length === 1) {
            this.removeSampleDay(element);
          }
          else {
            this.updateMethodCount(element, event);
          }
        }
        else {
          this.updateMethodCount(element, event)
        }
      }
    }
  }

  removeSampleDay(element) {
    element.patchValue({ Active: false });
    element.patchValue({ Methods: [] });
    element.patchValue({ MethodsCount: '' });
  }

  addNewSampleDay() {
    this.formArr.push(this.requestService.initRows(this.formArr.length));
  }

  get formArr() {
    return this.requestService.form.get("Rows") as FormArray;
  }

  clearFormArray = (formArray: FormArray) => {
    while (formArray.length > 1) {
      formArray.removeAt(0)
    }
  }

  deleteRow(index: number) {
    this.formArr.removeAt(index);
  }

  onSubmit(navigateToSampleInfo: boolean) {
    // this.expId;
    if (this.requestService.form.valid) {
      if(this.previousSelectedStartDate !== undefined && this.previousSelectedStartDate !== this.requestService.form.controls['ExperimentStartDate'].value && this.selectedMethodsValue.filter(y => y.SampleDays !== null && y.SampleDays.filter(x=> x.MethodStatus === AppConstants.InProgress || x.MethodStatus === AppConstants.Complete).length > 0).length > 0) {
        this.dialog.open(this.expMethodStatus);
      }
      else {
        if (this.checkValues(this.requestService.form.value.Rows)) {
          let dialogConfig = this.requestService.getDialogConfig();
          dialogConfig.data = { isSaveEnabled: true };
          this.dialog.open(AlertDialogComponent, dialogConfig);
        }
        else {
          this.expId = this.expIdForPopup = this.requestService.form.value.ExperimentId
          this.requestService.getRequestByExperimentId(this.requestService.form.value.ExperimentId)
            .subscribe(
              result => {
                if (this.requestService.form.value.GroupRequestId) {
                  let res = result.filter(r => r.GroupRequestId === this.requestService.form.value.GroupRequestId);
                  if (this.expId === this.requestService.form.value.ExperimentId && res !== null && res.length > 0) {
                    let modifiedData = JSON.stringify(this.requestService.form.value);
                    let activeData = JSON.stringify(this.activeRequest);
                    if (modifiedData !== activeData) {
                      let dialogRef = this.experimentStartDateChange(this.requestService.form.controls['ExperimentStartDate'].value);
                      if (dialogRef !== undefined) {
                        dialogRef.afterClosed().subscribe(
                          status => {
                            if (status) {
                              this.updateRequest(navigateToSampleInfo);
                            }
                          },
                          error => {
                            console.log(error);
                          },
                          () => {
                            // this.params.context.componentParent.refreshGrid(AppConstants.UPDATESTATUS);
                          }
                        );
                      }
                      else {
                        this.updateRequest(navigateToSampleInfo);
                      }
                    }
                    else {
                      if(navigateToSampleInfo) {
                        this.navigateAfterSave(navigateToSampleInfo, this.requestService.form.controls['GroupRequestId'].value);
                      }
                      else {                     
                        let dialogConfig = this.requestService.getDialogConfig();
                        this.dialog.open(this.saveDialog, dialogConfig);
                      }
                      
                    }
                  }
                  /* else if(((result.length!==0) && (this.expId!==this.requestService.form.value.ExperimentId) && result[0].ExperimentStatus==="Completed") || (result.length==0))
                  {
                    this.updateRequest();                
                  } */
                  else if ((result.length == 0) && (this.oldExpId !== this.requestService.form.value.ExperimentId)) {
                    this.updateRequest(navigateToSampleInfo);
                  }
                  else {                  
                    this.dialog.open(this.callDialog);
                  }
                }
                else {
  
                  if (result.length !== 0 && result[0].ExperimentStatus !==AppConstants.Complete && result[0].ExperimentStatus !== AppConstants.Cancel) {             
                    this.dialog.open(this.callDialog);
                  }
                  else {

                    this.requestService.addRequest(this.requestService.form.value)
                      .subscribe(result => {
                        if (this.requestorEmailList != undefined && this.requestorEmailList.length>0) {
                          this.saveRequestorEmail(this.requestorEmailList)
                        }
                        this.toastr.success('Request created successfully !!');
                        this.requestService.getRequestByExperimentId(this.requestService.form.value.ExperimentId)
                        .subscribe(
                          groupRequestResult => {
                            this.navigateAfterSave(navigateToSampleInfo, groupRequestResult[0].GroupRequestId);
                          });
                      },
                        error => {
                          this.toastr.error('Request creation failed !!');
                        });
                  }
  
                }
              },
              error => {
                console.log(error);
              });
  
  
        }
      }
      
    }
    else {    
      this.dialog.open(this.callDialog);
    }
  }

  updateRequest(navigateToSampleInfo: boolean) {
    this.requestService.updateRequest(this.requestService.form.value)
      .subscribe(
        result => {
          this.toastr.success('Request updated successfully !!');
          this.activeRequest = this.requestService.form.value;
          this.navigateAfterSave(navigateToSampleInfo, this.requestService.form.controls['GroupRequestId'].value);
          
        },
        error => {
          this.toastr.error(error, 'Request update failed !!');
        }
      );
  }

  navigateAfterSave(navigateToSampleInfo: boolean, groupRequestId)
  {
    if(navigateToSampleInfo) {
      this.router.navigate([AppConstants.ONERINGSAMPLEINFORMATION, groupRequestId]); 
    }
    else {
      this.reloadPage(groupRequestId);
    }
  }

  reloadPage(groupRequestId) {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigateByUrl( AppConstants.ONERINGREQUESTPAGE + "?exp=" + groupRequestId); 
    });
}

  addSampleInfo() {
    let navigateUrl = AppConstants.ONERINGSAMPLEINFORMATION + "/" + this.requestService.form.controls['GroupRequestId'].value;
    this.confirmPageNavigation(navigateUrl);   
  }

  checkFormDisabled() {
    if (this.isTableDisabled || this.disableForm) {
      return true;
    }
    else {
      return false;
    }
  }

  disableExperimentType() {
    if (this.experimentTypeList == null || this.experimentTypeList.length === 0 || this.disableForm) {
      return true;
    }
    else {
      return false;
    }
  }

  onCancel() {
    this.router.navigate([AppConstants.ONERINGDASHBOARD]);
  }

  navigateToAnalystPage() {
    let navigateUrl = AppConstants.ONERINGANALYSTPAGE + "?exp=" + this.requestService.form.value.GroupRequestId;
    this.confirmPageNavigation(navigateUrl);
  }

  isPageModified() {
    let modifiedData = JSON.stringify(this.requestService.form.value);
    if(!this.hasExperimentTypes) {
      delete this.activeRequest["ExperimentType"];
    }
    let activeData = JSON.stringify(this.activeRequest);
    // console.log(this.hasExperimentTypes);
    return modifiedData !== activeData;
    
  }

  confirmPageNavigation(navigateUrl) {
    if(!this.isPageModified()) {
      this.navigateByUrl(navigateUrl);
    }
    else {
      let dialogConfig = this.requestService.getDialogConfig();
      let dialogRef = this.dialog.open(this.pageNavigationConfirmationDialog, dialogConfig);
      dialogRef.afterClosed().subscribe(
        status => {
          if (status === 'confirm') {
            this.navigateByUrl(navigateUrl);
            
          }
        });
    }
  }

  navigateByUrl(navigateUrl) {
    if(navigateUrl.length > 0) {
      this.router.navigateByUrl(navigateUrl);
    }
    else {
      this.InitializeCopyRequest();
    }
  }

  checkValues(values: SampleDaysMethodsData[]) {
    let onlyMethodsSelected = values.find((v) => v.Active === false && v.Methods.length > 0);
    let onlyDaySelected = Object.values(values).find((value) => (value.Active == true && value.Methods.length == 0));
    let noSelection = values.find((v) => v.Active === true);
    if (onlyMethodsSelected !== undefined || onlyDaySelected !== undefined) {
      return true;
    }
    else if (noSelection === undefined) {
      return true;
    }

    return false;
  }

  onClose() {
    this.requestService.form.reset();
    this.requestService.initializeFormGroup();
  }

  getRequestorEmailId(groupRequestId) {
    this.requestService.getRequestorEmailId(groupRequestId)
      .subscribe(
        result => {
          result = result;
          this.requestorEmailList = result;
        },
        error => {
          this.toastr.error('Get requestor email failed !!');
        });
  }

  

  async addEmailId() {

    if (this.requestService.form.controls['EmailId'].value != undefined && this.requestService.form.controls['EmailId'].value != null) {

      if (this.validateEmail(this.requestService.form.controls['EmailId'].value)) {
        this.requestorEmailId = this.requestService.form.controls['EmailId'].value;
        let findings = this.requestorEmailList == undefined ? null : this.requestorEmailList.find(data => data.RequestorEmail.toLowerCase() === this.requestService.form.controls['EmailId'].value.toLowerCase());
        if (findings == undefined || findings == null) {
          let userInfo = this.commonService.getSessionStorageValue(AppConstants.LOGGEDINUSERINFO);
          if (userInfo !== undefined && userInfo !== null) {
            var email = this.requestService.form.controls['EmailId'].value;
            
            let userData = await this.service.getUserId(email).toPromise();
            if(userData.value.length > 0)
            {
              let id = userData.value[0].id;
              await this.service.isUserInGroup(id).toPromise().then(
                res => {
                  this.groups = res;
                },
                msg => { // Error
                  this.requestorEmailIdMsg = AppConstants.UserNotExistsInOneRing;
                  let dialogConfig = this.requestService.getDialogConfig();
                  this.dialog.open(this.requestorEmailExistDialog, dialogConfig);
                }
              )
            }
            else 
            {
              this.requestorEmailIdMsg = AppConstants.UserNotExistsInOneRing;
              let dialogConfig = this.requestService.getDialogConfig();
              this.dialog.open(this.requestorEmailExistDialog, dialogConfig);
            }
          }
          if (this.groups != undefined && this.groups.value != undefined && this.groups.value.length > 0) {
            if (this.expId != null) {
              this.requestorEmailModel = {
                RequestorEmailId: 0,
                ExperimentId: "",
                GroupRequestId: this.requestService.form.controls['GroupRequestId'].value,
                RequestorEmail: this.requestorEmailId,
                IsActive: false,
                CreatedDate: new Date(),
                CreatedBy: "",
                ModifiedDate: new Date(),
                ModifiedBy: ""
              }
              let requestorOneEmailList: any[] = [];
              this.requestorEmailList.push(this.requestorEmailModel);
              requestorOneEmailList.push(this.requestorEmailModel);
              this.saveRequestorEmail(requestorOneEmailList);
              this.groups = [];
            }
            else {
              this.requestorEmailModel = {
                RequestorEmailId: 0,
                GroupRequestId: 0,
                ExperimentId: this.requestService.form.value.ExperimentId,
                RequestorEmail: this.requestorEmailId,
                IsActive: false,
                CreatedDate: new Date(),
                CreatedBy: "",
                ModifiedDate: new Date(),
                ModifiedBy: ""
              }
              this.requestorEmailModel.RequestorEmail = this.requestorEmailId;
              this.requestorEmailList.push(this.requestorEmailModel);
              this.groups = [];
            }
          }
        }
        else {
          this.requestorEmailIdMsg = AppConstants.RequestorEmailExistInListPartOne + this.requestService.form.controls['EmailId'].value + AppConstants.RequestorEmailExistInListPartTwo;
          let dialogConfig = this.requestService.getDialogConfig();
          this.dialog.open(this.requestorEmailExistDialog, dialogConfig);
        }
      }
      else {
        this.requestorEmailIdMsg = AppConstants.InvalidEmailId;
        let dialogConfig = this.requestService.getDialogConfig();
        this.dialog.open(this.requestorEmailExistDialog, dialogConfig);
      }
    }
  }
  saveRequestorEmail(emailList) {
    this.requestService.insertRequestorEmailId(emailList).subscribe(
      result => {
        result = result;

      },
      error => {
        this.toastr.error('Get requestor email failed !!');
      });
  }
  
  validateEmail(email) {
    const regularExpression = /^[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}$/;
    return regularExpression.test(String(email).toLowerCase());
  }
  deleteEmailId(index, email) {
    this.requestorEmailId = email.RequestorEmail;
    let dialogConfig = this.requestService.getDialogConfig();
    let dialogRef = this.dialog.open(this.requestorEmailDeleteConfirmationDialog, dialogConfig);
    dialogRef.afterClosed().subscribe(
      status => {
        if (status === 'confirm') {

          this.requestorEmailList.splice(index, 1);
          if (email != undefined && email.RequestorEmailId != undefined && email.RequestorEmailId != 0) {
            this.requestService.deleteRequestorEmailId(email.RequestorEmailId).subscribe(
              result => {
                result = result;
              },
              error => {
                this.toastr.error('Get requestor email failed !!');
              });
          }
        }
      });
    return true;
  }
  compareFn: ((f1: any, f2: any) => boolean) | null = this.compareByValue;

  compareByValue(f1: any, f2: any) {
    return f1 && f2 && f1.MethodName === f2.MethodName;
  }

  compareProjectsFn: ((f1: any, f2: any) => boolean) | null = this.compareProjectByValue;

  compareProjectByValue(f1: any, f2: any) {
    return f1 && f2 && f1.ProjectName === f2.ProjectName;
  }
  compareGroupsFn: ((f1: any, f2: any) => boolean) | null = this.compareGroupsByValue;

  compareGroupsByValue(f1: any, f2: any) {
    return f1 && f2 && f1.GroupName === f2.GroupName;
  }
  compareExperimentsFn: ((f1: any, f2: any) => boolean) | null = this.compareExperimentsByValue;

  compareExperimentsByValue(f1: any, f2: any) {
    return f1 && f2 && f1.ExperimentTypeName === f2.ExperimentTypeName;
  }
  compareVesslsFn: ((f1: any, f2: any) => boolean) | null = this.compareVesselsByValue;

  compareVesselsByValue(f1: any, f2: any) {
    return f1 && f2 && f1.VesselName === f2.VesselName;
  }
  compareAnalyticalMethodsFn: ((f1: any, f2: any) => boolean) | null = this.compareAnalyticalMethodsByValue;

  compareAnalyticalMethodsByValue(f1: any, f2: any) {
    return f1 && f2 && f1.VesselName === f2.VesselName;
  }



  populateModelData() {
    this.projectService.get()
      .subscribe(async result => {
        this.projectsList = result.filter(r => r.IsActive === true);
        this.projectsList = this.sortPipe.transform(this.projectsList, 'asc', 'ProjectName');

      },
        error => {
          console.log(error);
        }
      );

    this.groupService.get()
      .subscribe(async result => {
        this.groupsList = result.filter(r => r.IsActive === true);
        this.groupsList = this.sortPipe.transform(this.groupsList, 'asc', 'GroupName');
        await new Promise<void>((resolve, reject) => {

          this.filteredGroupOptions = this.groupFilterControl.valueChanges.pipe(
            startWith(""),
            map((value: string) => {
              this.groupsList.forEach(option => {
                option.show = option.GroupName
                  .toLocaleLowerCase()
                  .includes(value.toLowerCase());
              });
              return this.groupsList;
            })
          )
          resolve();
        });
      },
        error => {
          console.log(error);
        }
      );

    this.vesselService.getVessels()
      .subscribe(
        result => {
          this.vesselsList = result.filter(r => r.IsActive === true);
          this.vesselsList = this.sortPipe.transform(this.vesselsList, 'asc', 'VesselName');
        },
        error => {
          console.log(error);
        }
      );
    this.methodService.getActiveMethods()
      .subscribe(async result => {
        let tempMethodsList = result.filter(r => r.IsActive === true);
        if (this.selectedMethodsList.length > 0) {
          this.selectedMethodsList.forEach(mt => {
            if (tempMethodsList.filter((m: any) => m.MethodId === mt.MethodId).length === 0) {
              tempMethodsList.push(mt);
            }
          });
        }
        this.methodsList = this.sortPipe.transform(tempMethodsList, 'asc', 'MethodName');

        await new Promise<void>((resolve, reject) => {

          this.filteredMethodOptions = this.methodFilterControl.valueChanges.pipe(
            startWith(""),
            map((value: string) => {
              this.methodsList.forEach(option => {
                option.show = option.MethodName
                  .toLocaleLowerCase()
                  .includes(value.toLowerCase());
              });
              return this.methodsList;
            })
          )
          resolve();
        });
      },
        error => {
          console.log(error);
        }
      );
  }
}