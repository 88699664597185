import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormArray, FormGroup, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AnalystExperimentDetailsService } from '../services/analyst-experiment-details-service/analyst-experiment-details.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/pairwise';
import { AnalystExperimentDetailsModel } from '../models/analyst-experiment-details.model';
import { CommonService } from 'src/app/services/common/common.service';
import { pairwise, startWith } from 'rxjs/operators'
import { AppConstants } from 'src/app/common-utility/appconstants';
import { UserClaim } from 'src/app/models/userClaim';
import { SortByPipe } from '../shared/sort/sort-pipe';
interface ExperimentStatus {
  value: string;
  viewValue: string;
}
export interface SampleDays {
  Day: number;
  SampleDate: Date;
  MethodsCount: number;
}

export interface Methods {
  MethodId: string;
  MethodName: number;
  Description: string;
  SampleDays: any;
}


@Component({
  templateUrl: './analyst-experiment-details.component.html',
  styleUrls: ['./analyst-experiment-details.component.css']
})

export class AnalystExperimentDetailsComponent implements OnInit {

  claims: UserClaim;
  constructor(private fb: FormBuilder, public dialog: MatDialog,
    private analystExperimentDetailsService: AnalystExperimentDetailsService,
    private sortPipe: SortByPipe,
    private route: ActivatedRoute, private router: Router, 
    private toastr: ToastrService, private commonService: CommonService

  ) {

  }
  ExperimentStatusDetails: ExperimentStatus[] = [
    { value: 'New', viewValue: 'New' },
    { value: 'InProgress', viewValue: 'In Progress' },
    { value: 'Completed', viewValue: 'Completed' },
    { value: 'Cancelled', viewValue: 'Cancelled' },
    { value: 'Reopened', viewValue: 'Reopened' }
  ];

  ExperimentMethodStatusDetails: ExperimentStatus[] = [
    { value: 'New', viewValue: 'New' },
    { value: 'InProgress', viewValue: 'In Progress' },
    { value: 'Completed', viewValue: 'Completed' },
    { value: 'Cancelled', viewValue: 'Cancelled' }

  ];
  SampleCount: any;
  AnalystExperimentDetails: any;
  groupRequestId: any;
  LstSampleDays: SampleDays[];
  LstMethods: Methods[];
  visualObservations: string = "";
  experimentNote: string = "";
  visualObservationspopup: string;
  experimentNotepopup: string;
  IsScreenDisabled: boolean = false;
  selectedTab: any;
  hasCompleted: boolean;
  previousExperimentStatus: string;

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.groupRequestId = params['exp'];

    });

    let claims = new UserClaim();
    let rolesData = this.commonService.getSessionStorageValue(AppConstants.UIUSERROLE);
    //this.newMessage(this.data.SampleCount);
    if (rolesData !== '' && rolesData !== null) {
      this.claims = JSON.parse(rolesData) as UserClaim;
      if (this.claims.isOneRing_Requester && !(this.claims.isOneRing_Analyst || this.claims.isOneRing_Admin)) {
        this.IsScreenDisabled = true;

      }
    }
    this.getAnalystExperimentDetailsById();

  }
  @ViewChild('updateExperimentStatusDialog') updateExperimentStatusDialog: TemplateRef<any>
  @ViewChild('updateExperimentNoteDialog') updateExperimentNoteDialog: TemplateRef<any>
  @ViewChild('updateVisualObservationsDialog') updateVisualObservationsDialog: TemplateRef<any>
  @ViewChild('isProcessedCheckforMarkCompletedDialog') isProcessedCheckforMarkCompletedDialog: TemplateRef<any>
  selectedValue: any;
  getAnalystExperimentDetailsById() {

    this.analystExperimentDetailsService.getAnalystExperimentDetailsById(this.groupRequestId)
      .subscribe(
        result => {
          result = result;
          this.AnalystExperimentDetails = result[0];
          this.AnalystExperimentDetails.AnalyticalMethods = this.AnalystExperimentDetails.AnalyticalMethods.filter(x=>(x.SampleDays != null && x.SampleDays.length > 0));
          this.AnalystExperimentDetails.AnalyticalMethods.forEach(element => {
            element.SampleDays = this.sortPipe.transform(element.SampleDays,'asc','Day');
          });
          
          this.selectedValue = this.AnalystExperimentDetails.ExperimentStatus;
          this.previousExperimentStatus = this.selectedValue;
          this.SampleCount = this.AnalystExperimentDetails.TotalVesselsSubmitted * this.AnalystExperimentDetails.SampleNumber;
          this.hasCompleted = this.AnalystExperimentDetails.HasCompleted;
        })
  }


  popoupMessage: any = AppConstants.ExperimentStatus;
  tabs = ['No Method'];
  selected = new FormControl(0);

  addTab(selectAfterAdding: boolean) {
    this.tabs.push('New');

    if (selectAfterAdding) {
      this.selected.setValue(this.tabs.length - 1);

    }
  }



  removeTab(index: number) {
    this.tabs.splice(index, 1);
  }

  onclickExperimentId(groupRequestId) {
    this.router.navigateByUrl("/group-request?exp=" + groupRequestId);
  }
  experimentStatus(value) {
    //this.groupRequestId=1;// used for dev  testing 
    let dialogConfig = this.analystExperimentDetailsService.getDialogConfig();
    dialogConfig.data = { isActive: value.checked };
    let dialogRef = this.dialog.open(this.updateExperimentStatusDialog, dialogConfig);

    dialogRef.afterClosed().subscribe(
      status => {
        if (status === 'confirm') {
          let isExperimentStatusNotAllowed = false;
          let isMethodStatusNotAllowed=false;
          for(let method of this.AnalystExperimentDetails != undefined? this.AnalystExperimentDetails.AnalyticalMethods:[])
          {                 
            if(value!=undefined && value.value=='Completed'&& (method.SampleDays.some((sampleDay) => sampleDay.MethodStatus == 'In Progress')|| method.SampleDays.some((sampleDay) => sampleDay.MethodStatus == 'New')))
            {             
              isMethodStatusNotAllowed=true;              
              break;
            }
            if(!method.SampleDays.every((sampleDay) => sampleDay.MethodStatus === 'Cancelled'))
            {
              isExperimentStatusNotAllowed = true;
              break;
            }
          }
          if(this.hasCompleted && value.value === 'Cancelled' && isExperimentStatusNotAllowed)
          {
            this.toastr.error('Can not cancel a previously completed experiment.  Mark the methods as cancelled to cancel the experiment !!');
            value.source.value = "";
            this.selectedValue = this.previousExperimentStatus;
          }
          else if (isMethodStatusNotAllowed)
          {
            this.toastr.error('Method Status must be complete or cancelled before Experiment Status can be marked complete.');
            value.source.value = "";
            this.selectedValue = this.previousExperimentStatus;
          }
          else
          {
            let isRequester: boolean;
            isRequester = this.commonService.isLoggedInUserRequester();
            this.analystExperimentDetailsService.updateAnalystExperimentStatusInfo(this.groupRequestId, value.value, isRequester)
              .subscribe(result => {
                this.toastr.success('Request status updated successfully !!');
                this.getAnalystExperimentDetailsById();
              },
                error => {
                  this.toastr.error('Request status update failed !!');
                })
            }
        }
        else {
          value.source.value = "";
          this.getAnalystExperimentDetailsById();
        }

      },
      error => {
        console.log(error);
      },
      () => {

      }
    );

  }
  onProcessedChecked(sampleDaysDataId, methodId, sampleday, event) {    
  
    this.popoupMessage = event.checked ? AppConstants.UNPROCESSEDMESSAGE : AppConstants.PROCESSEDMESSAGE;  
    let dialogConfig = this.analystExperimentDetailsService.getDialogConfig();
    let dialogRef = this.dialog.open(this.updateExperimentStatusDialog, dialogConfig);
     let isProcessed = event.checked;
       dialogRef.afterClosed().subscribe(
       status => {
           if (status === 'confirm') {        
                    this.analystExperimentDetailsService.UpdateSampleDayProcessedInfo(this.groupRequestId, methodId,sampleday,isProcessed)
                 .subscribe(result => {
                   this.toastr.success('Sample day updated successfully !!');
                   this.reloadAnalystExperimentDetails();  
                 },
                   error => {
                     this.toastr.error('Sample day update failed !!');
                   })            
           }
           else {
            this.reloadAnalystExperimentDetails();   
           }
            
       },
       error =>{
           console.log(error);
       },
       () =>{

       }
       );
  }

  reloadAnalystExperimentDetails()
  {
    this.getAnalystExperimentDetailsById();
    this.selected.setValue(this.selectedTab); 
  }

  onSampleDayAnlystChange(value, MethodId, DefaultAnalystId) {
    this.popoupMessage = "Are you sure you want to change the Analyst?";
    let dialogConfig = this.analystExperimentDetailsService.getDialogConfig();
    dialogConfig.data = { isActive: value.checked };
    let dialogRef = this.dialog.open(this.updateExperimentStatusDialog, dialogConfig);

    dialogRef.afterClosed().subscribe(
      status => {
        if (status === 'confirm') {
          this.analystExperimentDetailsService.UpdateAnalystInfo(this.groupRequestId, MethodId, DefaultAnalystId)
            .subscribe(result => {
              this.toastr.success('Request default analystId updated successfully !!');

            },
              error => {
                this.toastr.error('Request default analystId update failed !!');
              })
        }
        else {
          value.source.value = "";
          this.getAnalystExperimentDetailsById();
        }

      },
      error => {
        console.log(error);
      },
      () => {

      }
    );

  }

  onSampleDayInstrumentClick(value, MethodId, DefaultInstrumentId) {
    this.popoupMessage = "Are you sure you want to change the Instrument?";
    let dialogConfig = this.analystExperimentDetailsService.getDialogConfig();
    // dialogConfig.data = { isActive: event.checked };
    let dialogRef = this.dialog.open(this.updateExperimentStatusDialog, dialogConfig);

    dialogRef.afterClosed().subscribe(
      status => {
        if (status === 'confirm') {

          this.analystExperimentDetailsService.UpdateInstrumentInfo(this.groupRequestId, MethodId, DefaultInstrumentId)
            .subscribe(result => {
              this.toastr.success('Request default instrument successfully !!');

            },
              error => {
                this.toastr.error('Request default instrument  update failed !!');
              })
        }
        else {
          value.source.value = "";
          this.getAnalystExperimentDetailsById();
        }
      },
      error => {
        console.log(error);
      },
      () => {

      }
    );
  }

  onSampleDayexperimentStatusClick(value,itemrow) {   

    if(value.source.value===AppConstants.Complete && itemrow.IsProcessed===false)
    {
      let dialogConfig = this.analystExperimentDetailsService.getDialogConfig();        
      let dialogRef =this.dialog.open(this.isProcessedCheckforMarkCompletedDialog, dialogConfig);
      dialogRef.afterClosed().subscribe(
        status => {
          if (status === 'confirm') {
            value.source.value = "";
            this.getAnalystExperimentDetailsById();
            this.selected.setValue(this.selectedTab);
          }
        } ,
        error => {
          console.log(error);
        },
        () => {
  
        } 
      )
      return;
    }
    this.selectedTab = this.selected.value;    
    this.popoupMessage = AppConstants.ExperimentStatus;
    //this.groupRequestId=1;// used for dev  testing 
    let dialogConfig = this.analystExperimentDetailsService.getDialogConfig();
    //dialogConfig.data = { isActive: event.checked };
    let dialogRef = this.dialog.open(this.updateExperimentStatusDialog, dialogConfig);

    dialogRef.afterClosed().subscribe(
      status => {
        if (status === 'confirm') {
          let isRequester: boolean;
          isRequester = this.commonService.isLoggedInUserRequester();
          this.analystExperimentDetailsService.UpdateSampleDayStatusInfo(this.groupRequestId, itemrow.MethodId, itemrow.Day, itemrow.MethodStatus)
            .subscribe(result => {
              this.getAnalystExperimentDetailsById();
              this.toastr.success('Sample Day status updated successfully !!');

            },
              error => {
                this.toastr.error('Sample Day status update failed !!');
              })
        }
        else {
          value.source.value = "";
          this.getAnalystExperimentDetailsById();
          this.selected.setValue(this.selectedTab);

        }
      },
      error => {
        console.log(error);
      },
      () => {

      }
    );
  }


  onExperimentNoteClick(methodId, note) {

    let dialogConfig = this.analystExperimentDetailsService.getTextDialogConfig();
    //dialogConfig.data = { isActive: event.checked };
    let dialogRef = this.dialog.open(this.updateExperimentNoteDialog, dialogConfig);
    this.experimentNotepopup = note;//this.experimentNote;
    dialogRef.afterClosed().subscribe(
      status => {
        if (status === 'confirm') {

          this.experimentNote = this.experimentNotepopup;
          this.analystExperimentDetailsService.UpdateExperimentNoteInfo(this.groupRequestId, methodId, this.experimentNote)
            .subscribe(result => {
              this.getAnalystExperimentDetailsById();
              this.toastr.success('Experiment Note updated successfully !!');


            },
              error => {
                this.toastr.error('Experiment Note update failed !!');
              })

        }

      },
      error => {
        console.log(error);
      },
      () => {

      }
    );
  }


  onVisualObservationsClick(methodId, vsobs) {

    let dialogConfig = this.analystExperimentDetailsService.getTextDialogConfig();
    //dialogConfig.data = { isActive: event.checked };
    let dialogRef = this.dialog.open(this.updateVisualObservationsDialog, dialogConfig);
    this.visualObservationspopup = vsobs;//this.visualObservations; 
    dialogRef.afterClosed().subscribe(
      status => {
        if (status === 'confirm') {
          this.visualObservations = this.visualObservationspopup;
          this.analystExperimentDetailsService.UpdateVisualObservationsInfo(this.groupRequestId, methodId, this.visualObservations)
            .subscribe(result => {
              this.getAnalystExperimentDetailsById();
              this.toastr.success('Visual Observation updated successfully !!');


            },
              error => {
                this.toastr.error('Visual Observation update failed !!');
              })
        }
      },
      error => {
        console.log(error);
      },
      () => {

      }
    );
  }

  cloneDataSource(data) {
    let oldDataSource = data;
    let arr = [];  
    Object.keys(oldDataSource).map(function(key){  
        arr.push(oldDataSource[key])  
            
    }); 
    return arr;
  }
}