import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConstants } from '../common-utility/appconstants';
import { QCDataModel } from '../models/qc-data.model';
import { ResultDetailsService } from '../services/result-deatils-service/result-details.service';
import { MessageService } from '../services/message-service';
import { EntriesLinkRendererComponent } from '../custom/entries-link-renderer/entries-link-renderer.component';
import { SlideCellRendererComponent } from '../custom/slide-cell-renderer/slide-cell-renderer.component';
import { GridSizeChangedEvent } from 'ag-grid-community';
import { QcDataDetailsLinkRendererComponent } from '../custom/qc-data-details-link-renderer/qc-data-details-link-renderer.component';
import { StatusDialogComponent } from '../shared/status-dialog/status-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material';

@Component({
  selector: 'app-view-qc-data',
  templateUrl: './view-qc-data.component.html',
  styleUrls: ['./view-qc-data.component.scss']
})
export class ViewQcDataComponent implements OnInit {
  sampleDaysDataId: number;
  fermDay: number;
  methodId: number;
  experimentId: any;
  methodName: any;
  rowData: QCDataModel[] = [];
  experimentDetails: any;
  public context: any;
  private gridApi;
  private gridColumnApi;
  public subscription: any;
  componentName = AppConstants.QCDATAPOPUPVALUE;
  subComponentName = AppConstants.ACTIVEINACTIVEAll;
  public activeInactiveAll: boolean = false;
  constructor(private route: ActivatedRoute,
    private resultDetailsService: ResultDetailsService,
    public datepipe: DatePipe, private toastr: ToastrService,
    private _messageService: MessageService,
    private router: Router, private dialog: MatDialog) {
    this.context = { componentParent: this };
    this._messageService.listen().subscribe((m: any) => {
      this.refreshGrid();
    });
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.sampleDaysDataId = Number(params.get("sampleDaysDataId"));
    });

    this.getQCData();

  }

  getQCData() {
    this.resultDetailsService.getQCData(this.sampleDaysDataId)
      .subscribe(
        result => {
          this.experimentDetails = result.ExperimentDetails != undefined ? result.ExperimentDetails : {};
          this.rowData = [];
          if (result.QCData != undefined) {
            result.QCData.forEach(r => {
              this.rowData.push({
                IsActive: r.IsActive,
                FileName: r.FileName,
                RunDate: this.datepipe.transform(r.RunDate, 'MM/dd/yyyy'),
                RunTime: r.RunTime,
                InstrumentName: r.InstrumentName,
                ResultFileId: r.ResultFileId,
                SampleDaysDataId: r.SampleDaysDataId,
                IsProcessed: this.experimentDetails.IsProcessed
              })
            });
            this.activeInactiveAll = !this.rowData.every(x => !x.IsActive);
          }
        });
  }


  frameworkComponents = {
    linkRenderer: QcDataDetailsLinkRendererComponent,
    slideCellRenderer: SlideCellRendererComponent
  };
  defaultColDef: {
    flex: 1,
    enableValue: true,
    enableRowGroup: true,
    enablePivot: true,
    sortable: true,
    filter: true,
  }
  columnDefs = [
    { headerName: 'Active', field: 'IsActive', cellRenderer: 'slideCellRenderer', sortable: true, filter: true },
    { headerName: 'File Name', field: 'FileName', cellRenderer: 'linkRenderer', sortable: true, filter: true, tooltipValueGetter: (params) => params.value },
    { headerName: 'Date', field: 'RunDate', sortable: true, filter: true },
    { headerName: 'Time', field: 'RunTime', sortable: true, filter: true },
    { headerName: 'Instrument', field: 'InstrumentName', sortable: true, filter: true, tooltipValueGetter: (params) => params.value }
  ];

  updateStatus(qcData: any, isActive: boolean) {
    this.updateQCDataStatusByFile(qcData, isActive);
  }

  updateQCDataStatusByFile(qcData: any, isActive: boolean) {
    this.resultDetailsService.updateQCDataStatusByFile(qcData.ResultFileId, isActive)
      .subscribe(result => {
        this.getQCData();
        this.toastr.success('QC Data updated successfully !!');
      },
        error => {
          this.toastr.error('QC Data update failed !!');
        })
  }

  cancelStatusUpdate() {
    this.resultDetailsService.cancelStatusUpdate();
  }

  

  refreshGrid() {
    this.getQCData();
    this.gridApi.refreshClientSideRowModel('aggregate')
    this.onCloseEvent();
  }

  onCloseEvent() {
    if (this.subscription !== undefined) {
      this.subscription.unsubscribe();
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    //this.gridApi.setDomLayout("autoHeight");
  }

  onGridSizeChanged(params: GridSizeChangedEvent) {
    params.api.sizeColumnsToFit();
  }



  onExperimentResultDetails() {
    this.router.navigate([AppConstants.RESULTDETAILS, this.sampleDaysDataId]);
  }

  public onChange(event) {
    let dialogConfig = this.getDialogConfig();
    dialogConfig.data = { isActive: event.checked, componentName: this.componentName, subComponentName: this.subComponentName };
    let dialogRef = this.dialog.open(StatusDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
    status => {
        if (status) {
            this.updateAllQCDataStatusBySampleDay(event.checked);
        }     
        else {        
            this.cancelStatusUpdateAll();        
        }
    },
    error =>{
        console.log(error);
    },
    // () =>{
    //     this.refreshGrid();
    // }
    );
}

getDialogConfig() {
  const dialogConfig = new MatDialogConfig();
  dialogConfig.disableClose = true;
  dialogConfig.autoFocus = true;
  dialogConfig.width = "30%";
  return dialogConfig;

}

cancelStatusUpdateAll() {
  this.activeInactiveAll = !this.rowData.every(x => !x.IsActive);
  this.resultDetailsService.cancelStatusUpdate();

}

updateAllQCDataStatusBySampleDay(isActive: boolean) {
  this.resultDetailsService.updateAllQCDataStatusBySampleDay(this.sampleDaysDataId, isActive)
    .subscribe(result => {
      this.getQCData();
      this.toastr.success('QC Data updated successfully !!');
    },
      error => {
        this.toastr.error('QC Data update failed !!');
      })
}

}
