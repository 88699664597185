import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigurationSettings } from "../../configuration-settings"
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs-compat/BehaviorSubject';
import { FileMappingModel } from 'src/app/models/file-mapping.model';
import { ColumnMappingRulesModel } from 'src/app/models/column-mapping.model';
import { MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs-compat/Observable';
import { LoadingService } from '../loading.service';

@Injectable({
    providedIn: 'root'
  })
export class FileMappingService {
  private messageSource;
  // currentMessage = this.messageSource.asObservable();
  
    httpOptions = {
        headers: new HttpHeaders({ 
            'Content-Type': 'application/json' 
        })
    };

    constructor(private http: HttpClient, private loadingService: LoadingService) { }

    form: FormGroup = new FormGroup({
        FileMappingId: new FormControl(null),
        MethodName: new FormControl('', Validators.required),
        SheetName: new FormControl('', Validators.required),
        MethodId:new FormControl(null),
        Vessel:new FormControl(''),
        Description: new FormControl(''), 
        Version: new FormControl(''),
        IsActive: new FormControl(true)
      });

      initializeFormGroup() {
        this.form.setValue({
          FileMappingId: null,
          MethodName: '',
          MethodId:null,
          SheetName: '',
          Vessel:'',
          Description: '',
          Version: '',
          IsActive: 1
        });
      }

    public add(model):  Observable<any> {     
      this.loadingService.setMessage('Adding File Mapping...');
      this.messageSource = new BehaviorSubject(model);
      let url = ConfigurationSettings.REST_API_URL + "/FileMapping/insert";
      let body = JSON.stringify(model);  
      
      return this.http.post(
          url,
          body, this.httpOptions)
          .map(result => result)
          .catch(error => error).finally(() => this.loadingService.clearMessage());
    }

    public get(): Observable<any> {
      this.loadingService.setMessage('Loading File Mappings...');
      let url = ConfigurationSettings.REST_API_URL + "/FileMapping/getColumnMappingRules";

      return this.http.get(url)
          .map(result => result)
          .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
    }
   public getDelimiters(): Observable<any>{
    this.loadingService.setMessage('Loading File Mappings...');
    let url = ConfigurationSettings.REST_API_URL + "/FileMapping/getDelimiters";

    return this.http.get(url)
        .map(result => result)
        .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
    };

    public getMethods(): Observable<any>{
      this.loadingService.setMessage('Loading File Mappings...');
      let url = ConfigurationSettings.REST_API_URL + "/FileMapping/getMethods";
  
      return this.http.get(url)
          .map(result => result)
          .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
      };
   public getFriendlyHeaders(): Observable<any>{
    this.loadingService.setMessage('Loading File Mappings...');
    let url = ConfigurationSettings.REST_API_URL + "/FileMapping/getFriendlyHeader";

    return this.http.get(url)
        .map(result => result)
        .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
   };
    public cancelStatusUpdate() {
      this.messageSource = new BehaviorSubject('cancel');
    }

    public getById(Id: number): Observable<any> {
      this.loadingService.setMessage('Loading FIle Mapping...');
      let url = ConfigurationSettings.REST_API_URL + "/FileMapping/getFileMappingById?fileMalppingId="+ Id;
      return this.http.get(url)
          .map(result => result)
          .catch(error => error.json()).finally(() => this.loadingService.clearMessage());
    }

    public update(model):  Observable<any> {
      this.loadingService.setMessage('Updating File Mapping...');
      let url = ConfigurationSettings.REST_API_URL + "/FileMapping/update";
      this.messageSource = new BehaviorSubject(model);

      let body = JSON.stringify(model);
      return this.http.put(url, body, this.httpOptions)
          .map(result => result)
          .catch(error => error).finally(() => this.loadingService.clearMessage());
    }

    public updateStatus(Id, isActive): Observable<any> {
      this.loadingService.setMessage('Updating File Mapping Status...');
      let url = ConfigurationSettings.REST_API_URL + "/FileMapping/updateStatus";
      let inputParams = { params: { "fileMappingId": Id, "isActive" : isActive } }

      return this.http.put(url, this.httpOptions, inputParams)
          .map(result => result)
          .catch(error => error).finally(() => this.loadingService.clearMessage());
    }

    populateForm(popupForm) {
        this.form.setValue(_.omit(popupForm,['CreatedBy','CreatedDate','ModifiedBy','ModifiedDate','show']));
      }

      refreshGrid() {
        let currentMessage;
        if(this.messageSource !== undefined) {
          currentMessage = this.messageSource.asObservable();
          this.messageSource = undefined;
        }
        return currentMessage;
      }

      getDialogConfig() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = "45%";
       // dialogConfig.height = "75%"       
        return dialogConfig;

      }
      getDuplicateRecordDialogConfig() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = "23%";
        return dialogConfig;

      }
      getValidationDialogConfig() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = "20%";
        return dialogConfig;

      }
}