import { Component, Inject, OnInit } from '@angular/core';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/pairwise';
import {SelectionModel} from '@angular/cdk/collections';
import * as _ from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { AppConstants } from 'src/app/common-utility/appconstants';
import { RequestService } from 'src/app/services/request-service/request.service';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { StrainInfoModel } from 'src/app/models/strain-info.model';
import { ToastrService } from 'ngx-toastr';
import { StrainInfoPopupAlertComponent } from '../strain-info-popup/strain-popup-alerts/strain-info-popup-alert.component';
import { ActivatedRoute, Router } from '@angular/router';
import { isNumeric } from 'rxjs/internal-compatibility';

@Component({
  selector: 'app-sample-information',
  templateUrl: './sample-information.component.html',
  styleUrls: ['./sample-information.component.scss']
})
export class SampleInformationComponent implements OnInit {
  name = 'Paste it';
  val: any;
  dataSource = new MatTableDataSource<StrainInfoModel>();
  selection = new SelectionModel<any>(true, []);
  displayedHeaders: string;
  expectedColumns: string[] = [];
  copyHeader: [];
  showCommonComponents: boolean = false;
  isPageDisabled:boolean=false;
  isSaveButtonDisabled:boolean=false;
  selected = new FormControl(0);
  sampleDays: any[] = [];
  sampleDaysData: any[] = [];
  hideFermDay = true;
  hideGroupType = true;
  groupRequestId: any;
  experimentDetails: any;
  sampleInfoGroups: any[] = [];
  sampleInfoGroup: any;
  pasteText: any;
  constructor(public dialog: MatDialog,
    private requestService: RequestService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router) { }

    ngOnInit() { 
        this.pasteText = AppConstants.PasteText;
        this.route.paramMap.subscribe(params => {
        this.groupRequestId = Number(params.get("id"));
        });
        this.getStrainInformation(this.groupRequestId);
  }

  initializeSampleInfo()
    {
        if(this.dataSource.data !== undefined && this.dataSource.data.length > 0) {            
            if(this.experimentDetails.ExperimentStatus!=undefined &&(this.experimentDetails.ExperimentStatus==AppConstants.Complete 
            || this.experimentDetails.ExperimentStatus==AppConstants.Cancel))
            { 
                this.isPageDisabled=true;
                this.isSaveButtonDisabled=true;
            }
            else  
            {
                this.isPageDisabled=false; 
            }
            this.showCommonComponents = true;
            this.displayGrid();
        }
        else {
            this.isSaveButtonDisabled=true;
            this.isPageDisabled=false;
            this.showCommonComponents = true;
            this.displayGrid();
        }
    }

    getStrainInformation(GroupRequestId)
    {       
        this.requestService.getStrainInformation(GroupRequestId) 
        .subscribe(result => { 
        if(result != undefined) { 
            this.sampleDays = result.SampleDays;
            this.experimentDetails = result.ExperimentDetails;
            this.sampleInfoGroups = result.SampleInfoGroups;
            if(result.StrainInformation.length > 0) {
                this.dataSource.data = result.StrainInformation;
                this.sampleInfoGroup = this.sampleInfoGroups.filter(x=>x.SampleInfoGroupId === result.StrainInformation[0].SampleInfoGroupId)[0];
            }
        }
        this.initializeSampleInfo();
        },
        error => {
            this.toastr.error('Error to get sample information!!');
        });
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAllSelected() ?
            this.selection.clear() :
            this.dataSource.data.forEach(row => this.selection.select(row));
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: any): string {
        if (!row) {
        return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
    }

    onSampleInfoGroupChanged(event) {
        this.dataSource.data = [];
        this.displayGrid();
    }

    displayGrid()
    {
        this.sampleDays.forEach(sampleDay => {
            sampleDay[AppConstants.SampleData] = this.getFilteredData(sampleDay.Day);
        });

    }

    getFilteredData(day)
    {
        return this.dataSource.data.filter(sampleData => sampleData.FermDay == day);
    }

    getIsProcessed(day)
    {
        let sampleDay = this.sampleDays.filter(sampleData => sampleData.Day == day);
        return sampleDay != undefined && sampleDay.length > 0 ? sampleDay[0].IsProcessed : false;
    }

    generateColumns(columnNames: string): string[] {
        let newColumns: string[] = [];
        columnNames.split(AppConstants.Comma).forEach(item => {
            let columnName = item.trim().replace(AppConstants.ID,AppConstants.Id).split(AppConstants.SingleSpace).join(AppConstants.EmptySpace);
            newColumns.push(columnName);
        });

        return newColumns;
    }

    isInputFormatCorrect(inputFormat) : boolean {
        let requiredFields: string[] = [];
        requiredFields = this.generateColumns(this.sampleInfoGroup.RequiredHeaders);
        const unique = (Array.from(new Set(inputFormat)).length === inputFormat.length);
        return unique ? inputFormat.every( inputColumnName => this.expectedColumns.some(expectedColumnName => 
                                            expectedColumnName.trim().toLocaleLowerCase() === inputColumnName.trim().toLocaleLowerCase()) ) &&
                        requiredFields.every( requiredColumnName => inputFormat.some(inputColumnName => 
                                            inputColumnName.trim().toLocaleLowerCase() === requiredColumnName.trim().toLocaleLowerCase()) ) : false;
    }

    onPasteClick(event) {
        this.pasteText = "";
    }

    onClickOut(event) {
        this.pasteText = AppConstants.PasteText;
    }

    pasteData(event: ClipboardEvent) {
        if(this.sampleInfoGroup != undefined) {
            this.expectedColumns = this.generateColumns(this.sampleInfoGroup.SampleInfoFormat);
            let clipboardData = event.clipboardData;
            let pastedData = clipboardData.getData('text');
            let row_data = pastedData.split('\n');
            let displayedColumns = row_data[0].split('\t');
            // Remove Header Row.
            delete row_data[0];
            // Remove empty row
            delete row_data[row_data.length-1];
            
            let inputSampleHeaders = []; 
            Object.keys(displayedColumns).map(function(key){ 
                let column=displayedColumns[key].toLowerCase().trim().split(AppConstants.SingleSpace).join(AppConstants.EmptySpace);             
                inputSampleHeaders.push(column.trim())                    
            }); 
            
            if(this.getSampleDataExistsForDay(this.getSelectedSampleDay()).length === 0) {
                if(!this.getIsProcessed(this.getSelectedSampleDay()))
                {                    
                    if(this.isInputFormatCorrect(inputSampleHeaders)) {
                        this.createDataSource(row_data, inputSampleHeaders);
                    }
                    else {
                        this.displayMessage(AppConstants.InvalidSampleInformationFormat.replace(AppConstants.SampleFormat, this.sampleInfoGroup.SampleInfoFormat));
                    }
                }
                else {
                    this.displayMessage(AppConstants.SampleDayProcessed);
                }
                
            }
            else {
                this.displayMessage(AppConstants.SampleInformationExists);
            }
        }
        else {
            this.displayMessage(AppConstants.SelectGroup);
        }

        this.pasteText = AppConstants.PasteText;
    }

    createDataSource(row_data, inputSampleHeaders ) {
        // Create table dataSource
        let data = [];
        let sampleDay = this.getSelectedSampleDay();
        let sortOrder = 1;

        row_data.forEach(r => {
            let row = {};
            row[AppConstants.SORTORDER] = sortOrder;
            sortOrder = sortOrder + 1;
            this.expectedColumns.forEach((a, index) => { 
                var column = inputSampleHeaders.findIndex(d=> d.trim().toLowerCase() === a.trim().toLowerCase());
                if(column !== -1 && r.split('\t')[column] !== '' || r.split('\t')[column] !== undefined) {  
                    // PlateBarcode, VialBarcode, SampleId and Tank (Fermentation Sample - No Barcode) fields where mapped to Barcode column in the database table
                    if(a.toLocaleLowerCase() === AppConstants.PlateBarcode.toLocaleLowerCase() || 
                        a.toLocaleLowerCase() === AppConstants.VialBarcode.toLocaleLowerCase()||
                        a.toLocaleLowerCase() === AppConstants.SampleId.toLocaleLowerCase() ||
                        (a.toLocaleLowerCase() === AppConstants.Tank.toLocaleLowerCase() && this.sampleInfoGroup.SampleInfoFormat.indexOf(AppConstants.Barcode, AppConstants.BarcodeSearchStartPosition) === -1)) {
                        row[AppConstants.Barcode] = r.split('\t')[column].trim();
                    }
                    else {
                        if(a.toLocaleLowerCase() === AppConstants.StrainType.toLocaleLowerCase()) {
                            let strainType = r.split('\t')[column].trim();
                            row[a] = this.convertToTitleCase(strainType);
                        }
                        else {
                            row[a] = r.split('\t')[column].trim();
                        }
                        
                    }
                }
            });

            row[AppConstants.FermDay] = sampleDay;
            row[AppConstants.SampleInfoGroupId] = this.sampleInfoGroup.SampleInfoGroupId;

            if(Object.keys(row).length) {
                data.push(row);
            }            
        })
        
        if(this.isInputDataValid(data)) {
            this.isSaveButtonDisabled=false;
            this.dataSource.data = this.dataSource.data.concat(data);
            this.displayGrid(); 
        }
        
    }

    isInputDataValid(data) {
        let wellDataValid = true;
        let dilutionFactorDataValid = true;
        let requiredColumns = this.generateColumns(this.sampleInfoGroup.RequiredHeaders);
        let isRequiredDataReceived = data.every(x => this.isRowValid(x, requiredColumns));
        let uniqueData = this.getUniqueSampleInformation(data);
        let isDuplicateDataNotFound = uniqueData.length === data.length;
        
        if (!isRequiredDataReceived) {
            this.displayMessage(AppConstants.RequiredDataMissed);
        }
        else if(!isDuplicateDataNotFound) {
            this.displayMessage(AppConstants.DuplicateDataFound);
        }
        else if(!this.isWellDataValid(data)) {
            wellDataValid = false;
            this.displayMessage(AppConstants.WellDataNotValid);
        }
        else if(!this.isDilutionFactorDataValid(data)) {
            dilutionFactorDataValid = false
            this.displayMessage(AppConstants.DilutionFactorDataNotValid);
        }


        return isRequiredDataReceived && isDuplicateDataNotFound && wellDataValid && dilutionFactorDataValid;

    }

    isRowValid(rowData, requiredColumns) : boolean {
        let isRowValid: boolean = true;
        requiredColumns.forEach(element => {
            if(element.toLocaleLowerCase() === AppConstants.PlateBarcode.toLocaleLowerCase() || 
            element.toLocaleLowerCase() === AppConstants.VialBarcode.toLocaleLowerCase()||
            element.toLocaleLowerCase() === AppConstants.SampleId.toLocaleLowerCase() ||
            (element.toLocaleLowerCase() === AppConstants.Tank.toLocaleLowerCase() && this.sampleInfoGroup.SampleInfoFormat.indexOf(AppConstants.Barcode, AppConstants.BarcodeSearchStartPosition) === -1)) {
                element = AppConstants.Barcode;
            }

            if(rowData[element].trim().length === 0) {
                isRowValid = false;
            }
        });
        return isRowValid;

    }

    isWellDataValid(data) {
        let wellDataValid: boolean = false;
        if((this.sampleInfoGroup.SampleInfoFormat.indexOf(AppConstants.Well) === -1) || ( this.sampleInfoGroup.SampleInfoFormat.indexOf(AppConstants.Well) > 0 && data.every(x => !isNumeric(x[AppConstants.Well])))) {
            wellDataValid = true;
        }

        return wellDataValid;
    }

    isDilutionFactorDataValid(data) {
        return data.every(x => this.isRowDilutionFactorValid(x));
    }

    isRowDilutionFactorValid(rowData) : boolean {
        let isRowValid: boolean = true;
        if(rowData[AppConstants.DilutionFactor] != undefined && rowData[AppConstants.DilutionFactor].trim().length > 0 && isNaN(rowData[AppConstants.DilutionFactor].trim())) {
            isRowValid = false;
        }

        return isRowValid;
    }


    displayMessage(messageText)
    {
        let dialogConfig = this.requestService.getDialogConfig();
        dialogConfig.data = { message: messageText };
        this.dialog.open(StrainInfoPopupAlertComponent, dialogConfig);
    }

    getUniqueSampleInformation(data)
    {
        let newData = [];  
        Object.keys(data).map(function(key){  
            newData.push(data[key])       
        }); 
        
        var filterArray = newData.reduce((accumalator, current) => {
            if(this.sampleInfoGroup.SampleInfoFormat.indexOf(AppConstants.Well) === -1) {
                if(!accumalator.some(item => item.FermDay.toString() === current.FermDay.toString() 
                                    && item.Barcode.toLowerCase() === current.Barcode.toLowerCase())) {
                    accumalator.push(current);
                } 
            }
            else {
                if(!accumalator.some(item => item.FermDay.toString() === current.FermDay.toString() 
                                    && item.Barcode.toLowerCase() === current.Barcode.toLowerCase()
                                    && item.Well.toLowerCase() === current.Well.toLowerCase() )) {
                    accumalator.push(current);
                } 
            }
            
            return accumalator;
        },[]);
        return filterArray;
    }

    getSelectedSampleDay()
    {
        return this.sampleDays[this.selected.value].Day;
    }

    getSampleDataExistsForDay(sampleDay)
    {
        return this.dataSource.data.filter(sampleData => sampleData.FermDay.toString() == sampleDay.toString());
    }

    clearTable(sampleDay) {
        this.dataSource.data = this.dataSource.data.filter(sampleData => sampleData.FermDay.toString() !== sampleDay.toString());
    }

    closeDialog() {
        this.sampleDays.forEach(sampleDay => {
            sampleDay[AppConstants.SampleData] = [];
            sampleDay[AppConstants.VesselsSubmitted] = 0;
            sampleDay[AppConstants.SamplesSubmitted] = 0;
            sampleDay[AppConstants.CreatedBy] = "";
            sampleDay[AppConstants.CreatedDate] = new Date();
        });
    }

    saveSampleInformation() {
        this.dataSource.data = this.cloneDataSource(this.dataSource.data);
        this.requestService.insertStrainInformation(this.groupRequestId, this.dataSource.data)
        .subscribe(result => {
            this.toastr.success('Sample information saved successfully !!');
            this.onclickExperimentId();
        },
        error => {
          this.toastr.error('Sample information save failed !!');
        });

    }

    handleRemoveRow(row) {
        this.RemoveElementFromArray(row);
    }

    RemoveElementFromArray(row) {
        this.dataSource.data.forEach((value,index)=>{
            if(value.FermDay === row.FermDay 
                && value.StrainName === row.StrainName
                && value.StrainType === row.StrainType
                && value.Barcode === row.Barcode && value.Well === row.Well 
                && value.DilutionFactor === row.DilutionFactor) 
            {
                this.dataSource.data.splice(index,1);
                return false;
            }
               
        });
    }

    onclickExperimentId()
    {
      this.router.navigateByUrl("/group-request?exp=" + this.groupRequestId);
    }

    convertToTitleCase(orignalString) {
        orignalString = orignalString.replace(/(\r\n|\n|\r)/gm, "");
        return orignalString.split(AppConstants.SingleSpace)
                            .filter(word => word.trim().length > 0)
                            .map(word => word[0].toUpperCase() + word.substr(1).toLowerCase())
                            .join(AppConstants.SingleSpace);
      }

    cloneDataSource(data) {
        let oldDataSource = data;
        let arr = [];  
        Object.keys(oldDataSource).map(function(key){  
            arr.push(oldDataSource[key])  
                
        }); 
        return arr;
    }

}
