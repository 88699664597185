import { NgModule } from '@angular/core';
import { MsalGuard } from '@azure/msal-angular';
import { Routes, RouterModule } from '@angular/router';
import { RoleGuardService } from './services/role-gaurd-service';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { UnauthorizedComponent } from './login/unauthorized.component';
import { GroupRequestComponent } from './group-request/group-request.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { GroupComponent } from './administration/group/group.component';
import { VesselComponent } from './administration/vessel/vessel.component';
import { InstrumentComponent } from './administration/instrument/instrument.component';
import { MethodComponent } from './administration/method/method.component';
import { AppConstants } from './common-utility/appconstants';
import { ProjectComponent } from './administration/project/project.component';
import { ExperimentTypeComponent } from './administration/experiment-type/experiment-type.component';
import{AnalystExperimentDetailsComponent} from './analyst-experiment-details/analyst-experiment-details.component';
import { ImportResultsFileManuallyComponent } from './AnalystAdministration/import-results-file-manually/import-results-file-manually.component';
import { ProcessRogueFilesComponent } from './AnalystAdministration/process-rogue-files/process-rogue-files.component';
import { UserFriendlyHeadersComponent } from './administration/user-friendly-headers/user-friendly-headers.component';
import { FileMappingComponent } from './administration/file-mapping/file-mapping.component';
import { ExperimentResultDetailsComponent } from './experiment-result-details/experiment-result-details.component';
import { SearchAndReportingComponent } from './search-and-reporting/search-and-reporting.component';
import { SampleInformationComponent } from './group-request/sample-information/sample-information.component';
import { ViewQcDataComponent } from './view-qc-data/view-qc-data.component';
import { ViewQcDataDetailsComponent } from './view-qc-data-details/view-qc-data-details.component';
// Search And Reporting
// New Request
// Dashboard/Home
// Administrator


// Edit Screen
const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'dashboard', component: DashboardComponent, canActivate: [MsalGuard, RoleGuardService], data: {expectedRoles: [AppConstants.ONERING_ADMIN,AppConstants.ONERING_ADMIN_DEV,AppConstants.ONERING_ANALYST,AppConstants.ONERING_ANALYST_DEV,AppConstants.ONERING_REQUESTER,AppConstants.ONERING_REQUESTER_DEV]} },
  { path: 'group-request', component: GroupRequestComponent, canActivate: [MsalGuard, RoleGuardService], data: {expectedRoles: [AppConstants.ONERING_ADMIN,AppConstants.ONERING_ADMIN_DEV,AppConstants.ONERING_ANALYST,AppConstants.ONERING_ANALYST_DEV,AppConstants.ONERING_REQUESTER,AppConstants.ONERING_REQUESTER_DEV]} },
  { path: 'analyst-experiment-details', component: AnalystExperimentDetailsComponent, canActivate: [MsalGuard, RoleGuardService], data: {expectedRoles: [AppConstants.ONERING_ADMIN,AppConstants.ONERING_ADMIN_DEV,AppConstants.ONERING_ANALYST,AppConstants.ONERING_ANALYST_DEV,AppConstants.ONERING_REQUESTER,AppConstants.ONERING_REQUESTER_DEV]} },
  { path: 'import-result-files', component: ImportResultsFileManuallyComponent, canActivate: [MsalGuard, RoleGuardService], data: {expectedRoles: [AppConstants.ONERING_ADMIN,AppConstants.ONERING_ADMIN_DEV,AppConstants.ONERING_ANALYST,AppConstants.ONERING_ANALYST_DEV]} },
  { path: 'assign-files', component: ProcessRogueFilesComponent, canActivate: [MsalGuard, RoleGuardService], data: {expectedRoles: [AppConstants.ONERING_ADMIN,AppConstants.ONERING_ADMIN_DEV,AppConstants.ONERING_ANALYST,AppConstants.ONERING_ANALYST_DEV]} },
  { path: 'home', component: HomeComponent, canActivate: [MsalGuard], data: {expectedRoles: [AppConstants.ONERING_ADMIN,AppConstants.ONERING_ADMIN_DEV,AppConstants.ONERING_ANALYST,AppConstants.ONERING_ANALYST_DEV,AppConstants.ONERING_REQUESTER,AppConstants.ONERING_REQUESTER_DEV]} },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: 'group', component: GroupComponent, canActivate: [MsalGuard,RoleGuardService], data: {expectedRoles: [AppConstants.ONERING_ADMIN,AppConstants.ONERING_ADMIN_DEV]} },
  { path: 'vessel', component: VesselComponent, canActivate: [MsalGuard,RoleGuardService], data: {expectedRoles: [AppConstants.ONERING_ADMIN,AppConstants.ONERING_ADMIN_DEV]} },
  { path: 'instrument', component: InstrumentComponent, canActivate: [MsalGuard,RoleGuardService], data: {expectedRoles: [AppConstants.ONERING_ADMIN,AppConstants.ONERING_ADMIN_DEV]} },
  { path: 'method', component: MethodComponent, canActivate: [MsalGuard,RoleGuardService], data: {expectedRoles: [AppConstants.ONERING_ADMIN,AppConstants.ONERING_ADMIN_DEV]} },
  { path: 'experimenttype', component: ExperimentTypeComponent, canActivate: [MsalGuard,RoleGuardService], data: {expectedRoles: [AppConstants.ONERING_ADMIN,AppConstants.ONERING_ADMIN_DEV]} },
  { path: 'userfriendlyheaders', component: UserFriendlyHeadersComponent, canActivate: [MsalGuard,RoleGuardService], data: {expectedRoles: [AppConstants.ONERING_ADMIN,AppConstants.ONERING_ADMIN_DEV]} },
  { path: 'filemapping', component: FileMappingComponent, canActivate: [MsalGuard,RoleGuardService], data: {expectedRoles: [AppConstants.ONERING_ADMIN,AppConstants.ONERING_ADMIN_DEV]} },
  // { path: 'businessalignment', component: BusinessAlignmentComponent, canActivate: [MsalGuard,RoleGuardService], data: {expectedRoles: [AppConstants.ONERING_ADMIN]} },
  { path: 'project', component: ProjectComponent, canActivate: [MsalGuard,RoleGuardService], data: {expectedRoles: [AppConstants.ONERING_ADMIN,AppConstants.ONERING_ADMIN_DEV]} },
  { path: 'experiment-result-details/:sampleDaysDataId', component: ExperimentResultDetailsComponent, canActivate: [MsalGuard, RoleGuardService], data: {expectedRoles: [AppConstants.ONERING_ADMIN,AppConstants.ONERING_ADMIN_DEV,AppConstants.ONERING_ANALYST,AppConstants.ONERING_ANALYST_DEV]} },
  { path: 'view-qc-data/:sampleDaysDataId', component: ViewQcDataComponent, canActivate: [MsalGuard, RoleGuardService], data: {expectedRoles: [AppConstants.ONERING_ADMIN,AppConstants.ONERING_ADMIN_DEV,AppConstants.ONERING_ANALYST,AppConstants.ONERING_ANALYST_DEV]} },
  { path: 'view-qc-data-details/:resultFileId', component: ViewQcDataDetailsComponent, canActivate: [MsalGuard, RoleGuardService], data: {expectedRoles: [AppConstants.ONERING_ADMIN,AppConstants.ONERING_ADMIN_DEV,AppConstants.ONERING_ANALYST,AppConstants.ONERING_ANALYST_DEV]} },
  { path: 'sample-information/:id', component: SampleInformationComponent, canActivate: [MsalGuard, RoleGuardService], data: {expectedRoles: [AppConstants.ONERING_ADMIN,AppConstants.ONERING_ADMIN_DEV,AppConstants.ONERING_ANALYST,AppConstants.ONERING_ANALYST_DEV,AppConstants.ONERING_REQUESTER,AppConstants.ONERING_REQUESTER_DEV]} },
  { path: 'search-and-reporting', component: SearchAndReportingComponent, canActivate: [MsalGuard, RoleGuardService], data: {expectedRoles: [AppConstants.ONERING_ADMIN,AppConstants.ONERING_ADMIN_DEV,AppConstants.ONERING_ANALYST,AppConstants.ONERING_ANALYST_DEV,AppConstants.ONERING_REQUESTER,AppConstants.ONERING_REQUESTER_DEV]}},
  //Below route is used to redirect the user on login page when given route in URL is empty.(http://localhost:4200/)
  { path: '', redirectTo: '/login', pathMatch: 'full', canActivate: [MsalGuard] },
  //Below route is used to redirect the user on login page when given route in URL is not defined in application routes.(http://localhost:4200/**)
  { path: '**', redirectTo: '/login', pathMatch: 'full', canActivate: [MsalGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
