import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: 'file-mapping-duplicate-record-alert',
  templateUrl: './file-mapping-duplicate-record-alert.component.html'
//   styleUrls: ['./strain-info-popup-alert.component.css']
})
export class FileMappingDuplicateRecordAlertComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<FileMappingDuplicateRecordAlertComponent>, @Inject(MAT_DIALOG_DATA) private data: any) { }

  ngOnInit() {
  }

  onSubmit(){
    this.dialogRef.close(true);
  }

  onCancel(){
    this.dialogRef.close(false);
  }

}
