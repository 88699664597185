import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-match-renderer',
  templateUrl: './match-renderer.component.html',
  styleUrls: ['./match-renderer.component.scss']
})
export class MatchRendererComponent implements ICellRendererAngularComp {

  public params: ICellRendererParams;
  constructor() { }

  agInit(params: ICellRendererParams): void {
    this.params = params;
}

refresh(params: ICellRendererParams): boolean {
  return true;
}

}
