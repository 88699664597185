import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MethodService } from 'src/app/services/method-service/method.service';
import { ToastrService } from "ngx-toastr";
import { MessageService } from 'src/app/services/message-service';
import { InstrumentService } from 'src/app/services/instrument-service/instrument.service';
import { AppConstants } from 'src/app/common-utility/appconstants';
import { DataService } from 'src/app/services/data.service';
import { AnalystModel } from 'src/app/models/analyst.model';
import { FormControl } from '@angular/forms';
import { ConfigurationSettings } from 'src/app/configuration-settings';

@Component({
    selector: 'add-method',
    templateUrl: 'method-popup.component.html',
    styleUrls: ['./method.component.css']
})

export class MethodDialogComponent {
    selectedInstrumentsValue: [] = [];
    selectedAnalystsValue: [] = [];
    isFormInvalid: boolean = false;
    clickEventTrigger: boolean = false;
    instrumentsList: [];
    analystsList: Array<AnalystModel> = [];
    constructor(
        private service: MethodService,
        private dataService: DataService,
        private instrumentService: InstrumentService,
        public dialogRef: MatDialogRef<MethodDialogComponent>,
        private toastr: ToastrService,
        private _messageService: MessageService,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.populateDropdowns();
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    onClear() {
        this.service.form.reset();
        this.service.initializeFormGroup();
    }

    onSubmit() {
        // console.log(this.service.form.value);
        this.checkValidRecord();
    }

    editAnalystsSelection() {
        this.defaultAnalystFormControl.patchValue('');
    }

    editInstrumentsSelection() {
        this.defaultInstrumentsFormControl.patchValue('');
    }

    get defaultAnalystFormControl() {
        return this.service.form.get("DefaultAnalyst") as FormControl;
    }

    get defaultInstrumentsFormControl() {
        return this.service.form.get("DefaultInstrument") as FormControl;
    }

    onKeyDown() {
        this.isFormInvalid = false;
    }

    checkValidRecord() {
        let isValid: boolean = false;
        let model = this.service.form.value;
        if (model !== null && model.MethodName !== null && model.MethodName !== "") {
            this.service.getMethods()
                .subscribe(
                    result => {
                        if (!result.some((methodModel) => methodModel.MethodName.trim().toLowerCase() == model.MethodName.trim().toLowerCase())) {
                            this.isFormInvalid = false;
                            isValid = true;
                        }
                        else {
                            let method = result.find(method => method.MethodId === model.MethodId);
                            if (method !== null && method != undefined) {
                                if (result.some((methodModel) => methodModel.MethodName.trim().toLowerCase() == model.MethodName.trim().toLowerCase()
                                    && methodModel.MethodId != model.MethodId)) {
                                    this.isFormInvalid = true;
                                    isValid = false;
                                }
                                else if (method.MethodName.trim() !== model.MethodName.trim()
                                    || method.Description.trim() !== model.Description.trim()
                                    || method.TurnAroundTimeInDays !== model.TurnAroundTimeInDays
                                    || JSON.stringify(method.Instruments) !== JSON.stringify(model.Instruments)
                                    || JSON.stringify(method.Analysts) !== JSON.stringify(model.Analysts)
                                    || JSON.stringify(method.DefaultAnalyst) !== JSON.stringify(model.DefaultAnalyst)
                                    || JSON.stringify(method.DefaultInstrument) !== JSON.stringify(model.DefaultInstrument)) {
                                    this.isFormInvalid = false;
                                    isValid = true;
                                }
                                else {
                                    this.isFormInvalid = true;
                                    isValid = false;
                                }
                            }
                            else {
                                this.isFormInvalid = true;
                                isValid = false;
                            }
                        }

                        if (this.service.form.valid && isValid) {
                            if (!this.service.form.get('MethodId').value) {
                                this.service.addMethod(this.service.form.value)
                                    .subscribe(
                                        result => {
                                            this._messageService.filter('refresh');
                                            this.toastr.success('Method created successfully !!');
                                        },
                                        error => {
                                            this.toastr.error(error, 'Method creation failed !!');
                                        }
                                    );
                            }
                            else {
                                this.service.updateMethod(this.service.form.value)
                                    .subscribe(
                                        result => {
                                            this._messageService.filter('refresh');
                                            this.toastr.success('Method updated successfully !!');
                                        },
                                        error => {
                                            this.toastr.error(error, 'Method update failed !!');
                                        }
                                    );
                            }

                            this.onClose();
                        }

                    },
                    error => {
                        console.log(error);
                    });
        }
        return isValid;
    }

    populateDropdowns() {
        this.instrumentService.get()
            .subscribe(
                result => {
                    this.instrumentsList = result.filter(r => r.IsActive === true);
                },
                error => {
                    console.log(error);
                }
            );

        this.dataService.getUserGroup().subscribe(res => {
            res.value.forEach(item => {
                if (ConfigurationSettings.CURRENT_ENVIRONMENT_NAME.toLowerCase() === AppConstants.Prod_Env || ConfigurationSettings.CURRENT_ENVIRONMENT_NAME.toLowerCase() === AppConstants.Uat_Env) {
                    if (item.displayName.includes(AppConstants.ONERING_ANALYST)) {
                        this.addAnalysts(item);
                    }
                }
                else if (ConfigurationSettings.CURRENT_ENVIRONMENT_NAME.toLowerCase() === AppConstants.Dev_Env || ConfigurationSettings.CURRENT_ENVIRONMENT_NAME.toLowerCase() === AppConstants.Qa_Env || ConfigurationSettings.CURRENT_ENVIRONMENT_NAME.toLowerCase() === AppConstants.Local_Env) {
                    if (item.displayName.includes(AppConstants.ONERING_ANALYST_DEV)) {
                        this.addAnalysts(item);
                    }
                }
            });
        });
    }

    analystSelected() {
        if (this.service.form.value.Analysts == null || this.service.form.value.Analysts.length === 0) {
            return true;
        }
        else {
            return false;
        }
    }

    addAnalysts(item) {
        for (let i = 0; i < item.members.length; i++) {
            let user = this.analystsList.find(x => x.AnalystName === (item.members[i].givenName + ' ' + item.members[i].surname));
            if ((user === undefined || user === null) && item.members[i].accountEnabled && item.members[i].givenName !== null) {
                this.analystsList.push({
                    AnalystId: null,
                    AnalystName: item.members[i].givenName + ' ' + item.members[i].surname,
                    AnalystEmail: item.members[i].mail,
                    CreatedBy: '', ModifiedBy: '', CreatedDate: null, ModifiedDate: null, IsActive: true
                });
            }
        }
    }

    instrumentsSelected() {
        if (this.service.form.value.Instruments == null || this.service.form.value.Instruments.length === 0) {
            return true;
        }
        else {
            return false;
        }
    }
    compareFn: ((f1: any, f2: any) => boolean) | null = this.compareByValue;

    compareByValue(f1: any, f2: any) {
        return f1 && f2 && f1.InstrumentName === f2.InstrumentName;
    }

    compareAnalystFn: ((f1: any, f2: any) => boolean) | null = this.compareAnalystByValue;

    compareAnalystByValue(f1: any, f2: any) {
        return f1 && f2 && f1.AnalystName === f2.AnalystName;
    }

    onClose() {
        this.service.form.reset();
        this.service.initializeFormGroup();
        this.dialogRef.close();
    }
}