import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { MSAL_CONFIG } from '@azure/msal-angular/dist/msal.service';
import { SettingService } from './app/services/setting.service';

if (environment.production) {
  enableProdMode();
}

function bootstrapApp() {
  var req = new XMLHttpRequest();

  req.addEventListener('error', (res: any) => {
    console.log('Error: ', res);
  })
  req.addEventListener('load', (res: any) => {
    const appConfig = JSON.parse(res.currentTarget.response);

    platformBrowserDynamic([
      {
        provide: MSAL_CONFIG,
        useValue: appConfig.msalConfig
      },
      {
        provide: SettingService,
        useValue: appConfig
      },
    ]).bootstrapModule(AppModule)
      .catch(err => console.error(err));
  });

  req.open('GET', '/assets/settings.json');
  req.send(null);
}

bootstrapApp();
