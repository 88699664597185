import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MsalAuthService } from '@corteva-research/ngx-components-msal-integration';
import { CommonService } from '../services/common/common.service';
import { DataService } from '../services/data.service';
import { AppConstants } from '../common-utility/appconstants';
import { UserClaim } from '../models/userClaim';
import { AuthStatus, AUTH_SERVICE_TOKEN, OnAuthStatusUpdate } from '@corteva-research/ngx-components-core';
import { ConfigurationSettings } from '../configuration-settings';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.css']
})
export class LoginComponent implements OnInit, OnDestroy, OnAuthStatusUpdate {

  public authenticating: boolean = false;
  public isNavigated = false;
  public claims: UserClaim = new UserClaim();
  public groups;
  //Using Dictionary
  public map = new Map<string, any[]>();

  constructor(private router: Router,
    @Inject(AUTH_SERVICE_TOKEN) private auth: MsalAuthService,
    private service: DataService,
    private commonService: CommonService) {
  }

  public login(): void {
    this.auth.login();
  }

  public ngOnDestroy() {
    this.auth.unsubscribeFomAuthStatusChanges(this);
  }

  public resOnAuthStatusUpdate(authStatus: AuthStatus): void {
    switch (authStatus) {
      case AuthStatus.Authenticated:

        let userInfo = this.auth.userInfo;
        this.authenticating = false;
        this.claims.isOneRing_Admin = false;
        this.claims.isOneRing_Analyst = false;
        this.claims.isOneRing_Requester = false;
        this.claims.isOneRing_User = true;
        this.claims.securityRoles = [];

        this.isNavigated = true;

        let loggedUserId = userInfo.id;
        
        this.commonService.setSessionStorageValue(AppConstants.LOGGEDINUSERINFO, JSON.stringify(userInfo));

        this.service.getGroups().subscribe(async res => {
          this.authenticating = true;
          this.groups = res.value;          
          for (const group of res.value) {
            await new Promise<void>((resolve, reject) => {
              this.service.getData(group.id)
                .subscribe(resu => {                  
                  this.map.set(group.displayName, resu.value)
                  resolve();
                });
            });
          }

          if (this.map !== undefined && this.map !== null && this.map.size > 0) {
            let requestorDetails;
            let analystDetails;
            let adminDetails;

            if (ConfigurationSettings.CURRENT_ENVIRONMENT_NAME.toLowerCase() === AppConstants.Prod_Env || ConfigurationSettings.CURRENT_ENVIRONMENT_NAME.toLowerCase() === AppConstants.Uat_Env) {
              requestorDetails = this.map.get(AppConstants.ONERING_REQUESTER);
              analystDetails = this.map.get(AppConstants.ONERING_ANALYST);
              adminDetails = this.map.get(AppConstants.ONERING_ADMIN);
            }
            else if (ConfigurationSettings.CURRENT_ENVIRONMENT_NAME.toLowerCase() === AppConstants.Dev_Env
              || ConfigurationSettings.CURRENT_ENVIRONMENT_NAME.toLowerCase() === AppConstants.Qa_Env
              || ConfigurationSettings.CURRENT_ENVIRONMENT_NAME.toLowerCase() === AppConstants.Local_Env) {
              requestorDetails = this.map.get(AppConstants.ONERING_REQUESTER_DEV);
              analystDetails = this.map.get(AppConstants.ONERING_ANALYST_DEV);
              adminDetails = this.map.get(AppConstants.ONERING_ADMIN_DEV);
            }

            this.addAdminRole(adminDetails, loggedUserId);
            this.addAnalystRole(analystDetails, loggedUserId);
            this.addRequestorRole(requestorDetails, loggedUserId);
            this.commonService.setSessionStorageValue(AppConstants.UIUSERROLE, JSON.stringify(this.claims));
          }
        });
        break;
      case AuthStatus.Authenticating:
        this.authenticating = true;
        break;
      case AuthStatus.Unauthenticated:
        this.authenticating = false;
        break;
    }
  }


  addAdminRole(item, loggedUserId) {
    for (let i = 0; i < item.length; i++) {
      if (item[i].id === loggedUserId) {

        this.claims.isOneRing_Admin = true;
        this.addSecurityRole();
        break;
      }
    }
  }

  addAnalystRole(item, loggedUserId) {
    for (let i = 0; i < item.length; i++) {
      if (item[i].id === loggedUserId) {
        this.claims.isOneRing_Analyst = true;
        this.addSecurityRole();
        break;
      }
    }
  }

  addRequestorRole(item, loggedUserId) {
    for (let i = 0; i < item.length; i++) {
      if (item[i].id === loggedUserId) {
        this.claims.isOneRing_Requester = true;
        this.addSecurityRole();
        break;
      }
    }
  }

  addSecurityRole() {
    if (ConfigurationSettings.CURRENT_ENVIRONMENT_NAME.toLowerCase() === AppConstants.Prod_Env || ConfigurationSettings.CURRENT_ENVIRONMENT_NAME.toLowerCase() === AppConstants.Uat_Env) {
      if (this.claims.isOneRing_Requester) {
        this.claims.securityRoles.push(AppConstants.ONERING_REQUESTER);
      }
      if (this.claims.isOneRing_Analyst) {
        this.claims.securityRoles.push(AppConstants.ONERING_ANALYST)
      }
      if (this.claims.isOneRing_Admin) {
        this.claims.securityRoles.push(AppConstants.ONERING_ADMIN)
      }
    }
    else if (ConfigurationSettings.CURRENT_ENVIRONMENT_NAME.toLowerCase() === AppConstants.Dev_Env
      || ConfigurationSettings.CURRENT_ENVIRONMENT_NAME.toLowerCase() === AppConstants.Qa_Env
      || ConfigurationSettings.CURRENT_ENVIRONMENT_NAME.toLowerCase() === AppConstants.Local_Env) {
      if (this.claims.isOneRing_Requester) {
        this.claims.securityRoles.push(AppConstants.ONERING_REQUESTER_DEV);
      }
      if (this.claims.isOneRing_Analyst) {
        this.claims.securityRoles.push(AppConstants.ONERING_ANALYST_DEV)
      }
      if (this.claims.isOneRing_Admin) {
        this.claims.securityRoles.push(AppConstants.ONERING_ADMIN_DEV)
      }
    }
  }

  /**
  *  Reads hashed values in the url passed from the IDP.
  *  If successfully reads token, redirects to afterLoginRoute defined in ResearchAuthService
  */
  ngOnInit() {
    this.auth.subscribeToAuthStatusChanges(this);
  }
}
