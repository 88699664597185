import {Component, Inject, Output, EventEmitter} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { ProjectService } from 'src/app/services/project-service/project.service';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from 'src/app/services/message-service';


@Component({
    selector: 'group-popup',
    templateUrl: '../popup-component/popup.component.html',
    styleUrls: ['../popup-component/popup.component.css']
  })


  export class ProjectPopupComponent {
    isFormInvalid: boolean = false;
    clickEventTrigger: boolean = false;
    constructor(
        private service: ProjectService,
        public dialogRef: MatDialogRef<ProjectPopupComponent>,
        private toastr: ToastrService,
        private _messageService: MessageService,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        }
  
        onNoClick(): void {
        this.dialogRef.close();
        }

        onClear() {
            this.service.form.reset();
            this.service.initializeFormGroup();
        }

        onSubmit() {
            this.checkValidRecord();
        }

        onKeyDown(event) {
            this.isFormInvalid = false;
        }
  
        checkValidRecord() {
            let isValid: boolean = false;
            let model = this.service.form.value;
            this.service.get()
            .subscribe(
                result => {
                    if (!result.some((projectModel) => projectModel.ProjectName.trim().toLowerCase() == model.ProjectName.trim().toLowerCase())) {
                        this.isFormInvalid = false;
                        isValid = true;
                    }
                    else {
                        let project = result.find(project => project.ProjectId === model.ProjectId);
                        if(project !== null && project != undefined) {
                            if(result.some((projectModel) => projectModel.ProjectName.trim().toLowerCase() == model.ProjectName.trim().toLowerCase()
                            && projectModel.ProjectId != model.ProjectId))
                            {
                                this.isFormInvalid = true;
                                isValid = false;
                            }                            
                            else if(project.ProjectName.trim() !== model.ProjectName.trim()
                                || project.Description.trim() !== model.Description.trim() ) {
                                this.isFormInvalid = false;
                                isValid = true;
                            }
                            else {
                                this.isFormInvalid = true;
                                isValid = false;
                            }
                        }
                        else {
                            this.isFormInvalid = true;
                            isValid = false;
                        }
                    }

            if (this.service.form.valid && isValid) {
                if (!this.service.form.get('ProjectId').value) {
                    this.service.add(this.service.form.value)
                    .subscribe(
                        result =>{
                            this._messageService.filter('refresh');
                            this.toastr.success('Project created successfully !!');
                        },
                        error =>{
                            this.toastr.error(error, 'Project creation failed !!');
                        }
                    );
                }
                else{
                    this.service.update(this.service.form.value)
                    .subscribe(
                        result =>{
                            this._messageService.filter('refresh');
                            this.toastr.success('Project updated successfully !!');
                        },
                        error =>{
                            this.toastr.error(error, 'Project update failed !!');
                        }
                    );
                }
                this.onClose();
            }
        },
        error =>{
            console.log(error);
        }); 
        return isValid;
        }

        onClose() {
            this.service.form.reset();
            this.service.initializeFormGroup();
            this.dialogRef.close();
        }

        popupHeader() {
           return this.service.form.controls['ProjectId'].value?"Modify " + this.data.componentName:"New " + this.data.componentName
        }
  }