import { Component, Inject, OnInit } from '@angular/core';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/pairwise';
import {SelectionModel} from '@angular/cdk/collections';
import * as _ from 'lodash';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppConstants } from 'src/app/common-utility/appconstants';
import { StrainInfoPopupAlertComponent } from './strain-popup-alerts/strain-info-popup-alert.component';
import { RequestService } from 'src/app/services/request-service/request.service';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { StrainInfoModel } from 'src/app/models/strain-info.model';
import { ToastrService } from 'ngx-toastr';

@Component({
    templateUrl: './strain-info-popup.component.html',
    styleUrls: ['./strain-info-popup.component.css'],
})

export class StrainInfoComponent implements OnInit {

    name = 'Paste it';
    val: any;
    dataSource = new MatTableDataSource<StrainInfoModel>();
    selection = new SelectionModel<any>(true, []);
    displayedHeaders: string[];
    copyHeader: [];
    showHTPGrid: boolean = false;
    showOtherGrid: boolean = false;
    showCommonComponents: boolean = false;
    isPageDisabled:boolean=false;
    isSaveButtonDisabled:boolean=false;
    selected = new FormControl(0);
    sampleDays: any[] = [];
    sampleDaysData: any[] = [];
    hideFermDay = true;
    hideGroupType = true;  
    
    constructor(
        public dialogRef: MatDialogRef<StrainInfoComponent>,
        public dialog: MatDialog,
        private requestService: RequestService,
        private toastr: ToastrService,
        @Inject(MAT_DIALOG_DATA) public data: any) {

        }

    ngOnInit() { 
        if(this.data.SampleDays !== undefined && this.data.SampleDays.length > 0) {
            this.sampleDays = this.data.SampleDays;
        }
        
        if(this.data.GroupRequestId != undefined && this.data.GroupRequestId != 0) {
            this.getStrainInformation(this.data.GroupRequestId);
        }
        else {
            this.initializeSampleInfo();
        }
        

        

        // if(this.data.HTPGridData !== undefined && this.data.HTPGridData.length > 0) {            
        //     if(this.data.HTPGridData[0].ExperimentStatus!=undefined &&(this.data.HTPGridData[0].ExperimentStatus==AppConstants.Complete 
        //     || this.data.HTPGridData[0].ExperimentStatus==AppConstants.Cancel))
        //     { 
        //         this.isPageDisabled=true;
        //         this.isSaveButtonDisabled=true;
        //     }
        //     else  
        //     {
        //         this.isPageDisabled=false; 
        //     }

        //     if(this.data.HTPGridData==undefined || this.data.HTPGridData.length == 0)
        //     {

        //         this.isSaveButtonDisabled=true;
        //         this.isPageDisabled=false;
        //     }
        //     this.showCommonComponents = true;
        //     this.showHTPGrid = true;
        //     this.dataSource.data = this.data.HTPGridData;
        //     this.displayGrid();
        // }
        // else if(this.data.OtherGridData !== undefined && this.data.OtherGridData.length > 0) {
        //     this.showOtherGrid = true; 
        //     if(this.data.OtherGridData[0].ExperimentStatus!=undefined &&(this.data.OtherGridData[0].ExperimentStatus==AppConstants.Complete 
        //     || this.data.OtherGridData[0].ExperimentStatus==AppConstants.Cancel))
        //     { this.isPageDisabled=true;
        //         this.isSaveButtonDisabled=true;
        //     }
        //     else  {this.isPageDisabled=false;}
        //     if(this.data.OtherGridData==undefined || this.data.OtherGridData.length == 0)
        //     {

        //         this.isSaveButtonDisabled=true;
        //         this.isPageDisabled=false;
        //     }
        //     this.showCommonComponents = true;
        //     this.showOtherGrid = true;
        //     this.displayedHeaders = AppConstants.OtherGrid;
        //     this.dataSource.data = this.data.OtherGridData;
        //     this.displayGrid();
        // }
        // else
        // { 
        //      this.showOtherGrid = false;
        //     this.displayedHeaders = AppConstants.HTPGrid
        //     this.showHTPGrid = true;
        //     this.showCommonComponents = true;
        //     this.isSaveButtonDisabled=true;
        // }   
    }

    initializeSampleInfo()
    {
        if(this.dataSource.data !== undefined && this.dataSource.data.length > 0) {            
            if(this.dataSource.data[0].ExperimentStatus!=undefined &&(this.dataSource.data[0].ExperimentStatus==AppConstants.Complete 
            || this.dataSource.data[0].ExperimentStatus==AppConstants.Cancel))
            { 
                this.isPageDisabled=true;
                this.isSaveButtonDisabled=true;
            }
            else  
            {
                this.isPageDisabled=false; 
            }
            this.showCommonComponents = true;
            this.displayGrid();
        }
        else {
            this.isSaveButtonDisabled=true;
            this.isPageDisabled=false;
            this.showCommonComponents = true;
            this.displayGrid();
        }
        this.showHTPGrid = true;
        this.displayedHeaders = AppConstants.HTPGrid;
    }

    getStrainInformation(GroupRequestId)
    {       
        this.requestService.getStrainInformation(GroupRequestId) 
        .subscribe(result => { 
        if(result != undefined) { 
            if(result.StrainInformation.length > 0) {
                this.dataSource.data = result.StrainInformation;
            }

            this.sampleDaysData = result.SampleDays;
            
        }
        this.initializeSampleInfo();
        },
        error => {
            this.toastr.error('Error to get HTP information!!');
        });
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAllSelected() ?
            this.selection.clear() :
            this.dataSource.data.forEach(row => this.selection.select(row));
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: any): string {
        if (!row) {
        return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
    }

    onHTPClick() {
        if(this.showOtherGrid || (!this.showHTPGrid && !this.showOtherGrid)) {           
            this.dataSource.data = [];
            this.showOtherGrid = false;
            this.displayedHeaders = AppConstants.HTPGrid
            // if(this.data.HTPGridData !== undefined && this.data.HTPGridData.length > 0)
            // {
            // this.dataSource.data = this.data.HTPGridData;
            // }
            this.showHTPGrid = true;
            this.showCommonComponents = true;   
            this.displayGrid();         
        }        
    }

    onOtherClick() {
        if(this.showHTPGrid || (!this.showHTPGrid && !this.showOtherGrid)) {           
            this.dataSource.data = [];
            this.showHTPGrid = false;
            this.displayedHeaders = AppConstants.OtherGrid;
            this.showOtherGrid = true;
            this.showCommonComponents = true;
            this.displayGrid();
        }        
    }

    displayGrid()
    {
        this.sampleDays.forEach(sampleDay => {
            sampleDay[AppConstants.SampleData] = this.getFilteredData(sampleDay.Day);
            sampleDay[AppConstants.ISPROCESSED] = this.getIsProcessed(sampleDay.Day);
        });

    }

    getFilteredData(day)
    {
        return this.dataSource.data.filter(sampleData => sampleData.FermDay == day);
    }

    getIsProcessed(day)
    {
        let sampleDay = this.sampleDaysData.filter(sampleData => sampleData.Day == day);
        return sampleDay != undefined && sampleDay.length > 0 ? sampleDay[0].IsProcessed : false;
    }

    pasteData(event: ClipboardEvent) {
        let clipboardData = event.clipboardData;
        let pastedText = clipboardData.getData('text');
        let row_data = pastedText.split('\n');
        let displayedColumns = row_data[0].split('\t');
        // Remove Header Row.
        delete row_data[0];
        // Remove empty row
        delete row_data[row_data.length-1];
        
        let arr = []; 
        Object.keys(displayedColumns).map(function(key){ 
            let column=displayedColumns[key].toLowerCase();             
            arr.push(column.trim())                    
        }); 
        
        if(this.getSampleDataExistsForDay(this.getSelectedSampleDay()).length === 0) {
            if(!this.getIsProcessed(this.getSelectedSampleDay()))
            {
                if(this.showHTPGrid) {                          
                    if(JSON.stringify(arr) === JSON.stringify(AppConstants.HTPGridFormatTwo)) {
                        this.createDataSource(row_data, displayedColumns);
                    }
                    else {
                        this.displayMessage(AppConstants.InvalidSampleInformationFormat);
                    }
                }
                else if(this.showOtherGrid) {
                    if(JSON.stringify(arr) === JSON.stringify(AppConstants.OtherGridFormat)) {
                        this.createDataSource(row_data, displayedColumns)
                    }
                    else {
                        this.displayMessage(AppConstants.InvalidSampleInformationFormat);
                    }
                }
            }
            else {
                this.displayMessage(AppConstants.SampleDayProcessed);
            }
            
        }
        else {
            this.displayMessage(AppConstants.SampleInformationExists);
        }

    }

    createDataSource(row_data, displayedColumns ) {
        // Create table dataSource
        let data = [];
        let sampleDay = this.getSelectedSampleDay();
        let sortOrder = 1;

        row_data.forEach(r => {
            let row = {};
            row[AppConstants.SORTORDER] = sortOrder;
            sortOrder = sortOrder + 1;
            this.displayedHeaders.forEach((a, index) => { 
                switch (a) {
                    case 'select':
                        break;
                    case 'FermDay':
                        row[a] = sampleDay;
                        break;
                    case 'GroupType':
                        row[a] = this.showHTPGrid ? AppConstants.HTPGroupType : AppConstants.OtherGroupType;
                        break;
                    default:
                        var column = displayedColumns.findIndex(d=> d.trim().toLowerCase() === a.trim().toLowerCase()) 
                        if(column !== -1 && r.split('\t')[column] !== '' || r.split('\t')[column] !== undefined) {  
                            row[a] = r.split('\t')[column];
                        }
                        break;
                }
            });
            if(Object.keys(row).length) {
                data.push(row);
            }            
        })
    
        let uniqueData = this.getUniqueSampleInformation(data);
        this.isSaveButtonDisabled=false;
        this.dataSource.data = this.dataSource.data.concat(uniqueData);
        this.displayGrid(); 
    }

    displayMessage(messageText)
    {
        let dialogConfig = this.requestService.getDialogConfig();
        dialogConfig.data = { message: messageText };
        this.dialog.open(StrainInfoPopupAlertComponent, dialogConfig);
    }

    getUniqueSampleInformation(data)
    {
        let newData = [];  
        Object.keys(data).map(function(key){  
            newData.push(data[key])       
        }); 
        
        var filterArray = newData.reduce((accumalator, current) => {
            if(!accumalator.some(item => item.FermDay.toString() === current.FermDay.toString() 
                                    && item.StrainName.toLowerCase() === current.StrainName.toLowerCase()
                                    && item.StrainType.toLowerCase() === current.StrainType.toLowerCase()
                                    && item.Barcode.toLowerCase() === current.Barcode.toLowerCase()
                                    && item.Well.toLowerCase() === current.Well.toLowerCase() 
                                    && item.DilutionFactor.toLowerCase() === current.DilutionFactor.toLowerCase())) {
              accumalator.push(current);
            } 
            return accumalator;
        },[]);
        return filterArray;
    }

    getSelectedSampleDay()
    {
        return this.sampleDays[this.selected.value].Day;
    }

    getSampleDataExistsForDay(sampleDay)
    {
        return this.dataSource.data.filter(sampleData => sampleData.FermDay.toString() == sampleDay.toString());
    }

    clearTable(sampleDay) {
        this.dataSource.data = this.dataSource.data.filter(sampleData => sampleData.FermDay.toString() !== sampleDay.toString());
    }

    closeDialog() {
        this.sampleDays.forEach(sampleDay => {
            sampleDay[AppConstants.SampleData] = [];
            sampleDay[AppConstants.VesselsSubmitted] = 0;
            sampleDay[AppConstants.SamplesSubmitted] = 0;
            sampleDay[AppConstants.CreatedBy] = "";
            sampleDay[AppConstants.CreatedDate] = new Date();
        });
        this.dialogRef.close();
    }

    saveButtonName() {
        return this.data.GroupRequestId?"Save":"Back To Request"
    }

    addDataToGroup() {
        this.dataSource.data = this.cloneDataSource(this.dataSource.data);
        if(this.showHTPGrid) {
            return {isHTPData: this.showHTPGrid, dataSource: this.dataSource.data}
        }
        else {
            return {isOtherData: this.showOtherGrid, dataSource: this.dataSource.data}
        }
    }

    handleRemoveRow(row) {
        this.RemoveElementFromArray(row);
    }

    RemoveElementFromArray(row) {
        this.dataSource.data.forEach((value,index)=>{
            if(value.FermDay === row.FermDay 
                && value.StrainName === row.StrainName
                && value.StrainType === row.StrainType
                && value.Barcode === row.Barcode && value.Well === row.Well 
                && value.DilutionFactor === row.DilutionFactor) 
            {
                this.dataSource.data.splice(index,1);
                return false;
            }
               
        });
    }

    cloneDataSource(data) {
        let oldDataSource = data;
        let arr = [];  
        Object.keys(oldDataSource).map(function(key){  
            arr.push(oldDataSource[key])  
                
        }); 
        return arr;
    }
}