import { ChangeDetectorRef, Component, OnInit, TemplateRef } from '@angular/core';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from 'src/app/services/message-service';
import { DatePipe } from '@angular/common';
import { RogueFileModel } from 'src/app/models/rogue-file.model';
import { RequestService } from 'src/app/services/request-service/request.service';
import { SortByPipe } from 'src/app/shared/sort/sort-pipe';
import { startWith, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { FormControl } from "@angular/forms";


import { ViewChild, OnDestroy } from '@angular/core';
import { MatTree, MatTreeNestedDataSource } from '@angular/material';
import { NestedTreeControl } from '@angular/cdk/tree';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app/common-utility/appconstants';
import { ResultFilesService } from 'src/app/services/result-files-service/result-files-service';
import { MatDialog } from '@angular/material/dialog';
import { GroupRequestModel } from 'src/app/models/group-request.model';

export class TreeModel {
  children: Array<TreeModel>;
  name: string;
  LastModified: string;
  AssignedMethodId: number;
  AnalystName: string;
  AnalystId: number;
  ErrorMessage: string;
}

@Component({
  selector: 'app-process-rogue-files',
  templateUrl: './process-rogue-files.component.html',
  styleUrls: ['./process-rogue-files.component.scss']
})
export class ProcessRogueFilesComponent implements OnInit {
  @ViewChild('deleteFilesDialog') deleteFilesDialog: TemplateRef<any>
  @ViewChild('tree') tree: MatTree<any>;
  TREE_DATA: TreeModel[] = [];
  data;
  selectedFiles: RogueFileModel[] = [];
  isDeleteFilesSuccess : boolean = false;
  isDeleteHappen: boolean = false;
  isProcessFilesSuccess : boolean = false;
  isProcessFilesHappen: boolean = false;
  isFilesSelected: boolean = false;
  isFilesLoaded: boolean = false;
  
  filteredExperimentOptions: Observable<Array<GroupRequestModel>>;
  experimentFilterControl = new FormControl();
 
    // subscription;
    count = 0;
    nestedTreeControl: NestedTreeControl<TreeModel>;
    nestedDataSource: MatTreeNestedDataSource<TreeModel>;
    inputValue;


  rowData: RogueFileModel[] = [];
  errorFiles: RogueFileModel[] = [];
  experiments: GroupRequestModel[] = [];
  selectedExperimentValue : any;
  // isStrainExists : boolean = false;
  // isStrainInfoReceived : boolean = false;

  constructor(private service: ResultFilesService, 
              private requestService: RequestService, 
              private sortPipe: SortByPipe,
              public datepipe: DatePipe,
              private toastr: ToastrService,
              private _messageService: MessageService,
              private cdr: ChangeDetectorRef,
              private router: Router,
              public dialog: MatDialog) { 
                
    /** Data Source and Tree Control used by Tree View */
    this.nestedTreeControl = new NestedTreeControl<TreeModel>(this._getChildren);
    this.nestedDataSource = new MatTreeNestedDataSource();
      this._messageService.listen().subscribe((m:any) => {
      })
    }

  ngOnInit() {
    this.getRogueFiles();
  }

  /** Checks if datasource for material tree has any child groups */
  hasNestedChild = (_: number, nodeData: TreeModel) => nodeData.children.length > 0;

  /** Returns child groups from security group */
  private _getChildren = (node: TreeModel) => node.children;

  // To select individual file
  clickedActive(element) {
    element.checked = !element.checked;
    this.getSelectedFiles();
    this.isFilesSelected = this.selectedFiles.length > 0;
  }

  //Selects/deselects all the children
  SelectAllChildren(data, isSelected) {
    data.forEach(d => {
      d.checked = isSelected;
      if (d.children && d.children.length > 0) {
        this.SelectAllChildren(d.children, isSelected);
      }
    });
  }

  // Calls when Experiment checkbox selected
  clickedParentActive(element) {
    element.checked = !element.checked;
    this.SelectAllChildren(element.children, element.checked)
    this.getSelectedFiles();
    this.isFilesSelected = this.selectedFiles.length > 0;
  }

  /** Loops recursively through data finding the amount of checked children */
  getCheckedAmount(data) {
    this.count = 0; // resetting count
    this.loopData(data);
    return this.count;
  }

  /** Used by getCheckedAmount() */
  loopData(data) {
    data.children.forEach(d => {
      if (d.checked) {
        this.count += 1;
      }
      if (d.children && d.children.length > 0) {
        this.loopData(d.children);
      }
    });
    if(data.children.length === this.count)
    {
      data.checked = this.count > 0;
    }
    else
    {
      data.checked = false;
    }
  }

  //To prevent change detections
  ngAfterViewChecked() {
    this.cdr.detectChanges(); 
  }

  //Calls when open/close the group
  changeState(data) {
    data.expanded = !data.expanded;
  }

  //To load active experiments in the dropdown
  getActiveRequests() {
    this.requestService.getActiveRequests()
      .subscribe(async result => {
          this.experiments = result;
          this.experiments = this.sortPipe.transform(this.experiments,'asc', 'ExperimentId');

          await new Promise<void>((resolve, reject) => {
            
            this.filteredExperimentOptions = this.experimentFilterControl.valueChanges.pipe(
              startWith(""),
              map((value: string) => {
                this.experiments.forEach(option => {
                  option.show = option.ExperimentId
                    .toLocaleLowerCase()
                    .includes(value.toLowerCase());
                });
                return this.experiments;
              })
            )
            resolve();
        });
        },
        error => {
          console.log(error);
        }

      )

  }

  //To load files in the tree
  getRogueFiles(){
    this.service.getRogueFiles()
      .subscribe(
        result => {
          this.rowData = [];
          result.forEach(r => {
            let data = this.TREE_DATA.find(x=> x.name === r.AssignedMethod);
            if(data === undefined || data === null) {
              this.TREE_DATA.push({
                name: r.AssignedMethod,
                children: [{ name: r.FileName , children: [], AssignedMethodId: r.AssignedMethodId, AnalystId: r.AnalystId, AnalystName: r.AnalystName, ErrorMessage: r.ErrorMessage, LastModified: this.datepipe.transform(r.LastModified,'MM/dd/yyyy')}],
                AssignedMethodId: 0,
                AnalystName: '',
                AnalystId: 0,
                LastModified: '',
                ErrorMessage: '',
              })
            }
            else {
              data.children.push({ name: r.FileName, children: [], AssignedMethodId: r.AssignedMethodId, AnalystId: r.AnalystId, AnalystName: r.AnalystName, ErrorMessage: r.ErrorMessage, LastModified: this.datepipe.transform(r.LastModified,'MM/dd/yyyy')})
            }
            
          })
          const data = this.TREE_DATA;
          data.map(d => {
            d['checked'] = false;
            d['expanded'] = false;
            return d;
          });
          this.nestedDataSource.data = data;
          this.isFilesLoaded = true;
        },
        error =>{
          console.log(error);
          this.isFilesLoaded = true;
        }
      ) 
  }

  //Calls Active Experiments value changed
  onExperimentChanged(event) {
    if(event.value !== undefined)
    {
      // this.isStrainInfoReceived = true;
      // this.requestService.hasStrainInformation(event.value.GroupRequestId)
      //   .subscribe(
      //     result => {
      //       this.isStrainExists = result;
      //     },
      //     error => {
      //       this.isStrainExists = false;
      //       console.log(error);
      //     }

      //   );
    }
    else
    {
      // this.isStrainExists = false;
      // this.isStrainInfoReceived = false;
    }
  }

  //Calls when Cancel button selected
  onCancel() {
    this.router.navigate([AppConstants.ONERINGDASHBOARD]);
   }

   //Calls when Delete button selected
   deleteFiles()
   {
    this.isDeleteFilesSuccess = false;
    this.isDeleteHappen = false; 
    let dialogConfig = this.service.getDialogConfig();
    //dialogConfig.data = { isActive: value.checked };
    let dialogRef = this.dialog.open(this.deleteFilesDialog, dialogConfig);

    dialogRef.afterClosed().subscribe(
      status => {
        if (status === 'confirm') {
        this.getSelectedFiles();
        this.deleteSlectedFiles();
      }
  
    })
  }

   //Calls when Process button selected
   processFiles()
   {
    this.isProcessFilesSuccess = false;
    this.isProcessFilesHappen = false; 
    this.getSelectedFiles();
    this.processSlectedFiles();
   }

   getSelectedFiles()
   {
    this.selectedFiles = [];
    this.nestedDataSource.data.forEach(d => {
      if (d.children && d.children.length > 0) {
        d.children.forEach(row => {
          if(row['checked'])
          {
            let rogueFile = {
              FileName: row.name,
              AssignedMethodId: row.AssignedMethodId,
              AssignedMethod: d.name,
              LastModified: row.LastModified,
              AnalystId: row.AnalystId,
              AnalystName: row.AnalystName
            }
            this.selectedFiles.push(rogueFile);           
          }
          
        })
      }
    });
   }

   deleteSlectedFiles() {
    this.service.deleteRogueFiles(this.selectedFiles)
      .subscribe(
        result => {
          this.isDeleteFilesSuccess = true;
          this.isDeleteHappen = true;
          this.isFilesSelected = false;
          this.TREE_DATA = [];
          this.getRogueFiles();
        },
        error =>{
          this.isDeleteFilesSuccess = false;
          this.isDeleteHappen = true;
          }
        );
  }

  processSlectedFiles() {
    this.service.processRogueFiles(this.selectedFiles)
      .subscribe(
        result => {
          if(result.length == 0)
          {
          this.isProcessFilesSuccess = true;
          this.isProcessFilesHappen = true;
          this.isFilesSelected = false;
          this.TREE_DATA = [];
          this.getRogueFiles();
          }
          else
          {
            this.errorFiles = result;
          }
        },
        error =>{
          this.isProcessFilesSuccess = false;
          this.isProcessFilesHappen = true;
          }
        );
  }

}
