import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { AppConstants } from 'src/app/common-utility/appconstants';
import { MatSort } from '@angular/material/sort';
import { StrainInfoModel } from 'src/app/models/strain-info.model';
import { MatPaginator } from '@angular/material/paginator';
import { CommonTableColumn } from 'src/app/models/common-table-column.model';

@Component({
  selector: 'app-strain-info-table',
  templateUrl: './strain-info-table.component.html',
  styleUrls: ['./strain-info-table.component.scss']
})
export class StrainInfoTableComponent implements OnInit, OnChanges {

  @Input()
  sampleDay: number;
  @Input()
  sampleData: StrainInfoModel[] = [];
  @Input()
  isPageDisabled: boolean=false;

  @Input()
  isProcessed: boolean=false;

  @Input()
  displayedHeaders: string;

  @Input()
  requiredHeaders: string;

  @Output() 
  clearAll: EventEmitter<number> =   new EventEmitter();
  @Output() 
  removeRow: EventEmitter<any> =   new EventEmitter();

  dataSource = new MatTableDataSource<StrainInfoModel>();
  displayedColumns: string[] = [];
  hideFermDay = true;
  hideGroupType = true;
  vesselsSubmitted: any;
  samplesSubmitted: any;
  createdBy: any;
  createdDate: any;
  searchTerm: string;
  tableDef: Array<CommonTableColumn> = [];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor() {}

  ngOnInit() {
  }

  calculateDisplayCounts()
  {
    this.vesselsSubmitted = this.getUniqueVesselsSubmitted(this.dataSource.data);
    this.samplesSubmitted = this.dataSource.data.length;
    this.createdBy = this.dataSource.data.length > 0 ? this.dataSource.data[0].CreatedBy : "";
    this.createdDate = this.dataSource.data.length > 0 ? this.dataSource.data[0].CreatedDate : new Date();
  }

  ngOnChanges()
  {
    this.generateColumns(this.displayedHeaders);

    if(this.dataSource.data.length === 0) {
      this.dataSource.data = this.sampleData;
      this.calculateDisplayCounts();
    } 

  }
  ngAfterViewInit(): void {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.filterPredicate = this.isSerachTermFound;
      this.dataSource.data = this.sampleData;
      this.calculateDisplayCounts();
  }

  generateColumns(columnNames: string) {
    this.tableDef = [];
    this.displayedColumns = [];
    this.displayedColumns.push(AppConstants.SelectColumn);
    let requiredColumns = this.generateRequiredColumns(this.requiredHeaders);
    columnNames.split(AppConstants.Comma).forEach(item => {

      let columnName = this.getColumnName(item);
      this.tableDef.push({
        DataColumnName: columnName,
        HeaderText: item.trim(),
        OptionalText: this.isOptionalColumn(columnName, requiredColumns) ? AppConstants.Optional : AppConstants.EmptySpace
      });
      this.displayedColumns.push(columnName);
    });
  }

  trackByColumn(index, item) { 
    return item.DataColumnName; 
  } 

  generateRequiredColumns(columnNames: string): string[] {
    let newColumns: string[] = [];
    columnNames.split(AppConstants.Comma).forEach(item => {
        let columnName = this.getColumnName(item);
        newColumns.push(columnName);
    });

    return newColumns;
}

  getColumnName(inputColumnName: string) : string
  {
    let columnName = inputColumnName.trim().replace(AppConstants.ID,AppConstants.Id).split(AppConstants.SingleSpace).join(AppConstants.EmptySpace);
      // PlateBarcode, VialBarcode, SampleId and Tank (Fermentation Sample - No Barcode) fields where mapped to Barcode column in the database table
      if(columnName.toLocaleLowerCase() === AppConstants.PlateBarcode.toLocaleLowerCase() || 
          columnName.toLocaleLowerCase() === AppConstants.VialBarcode.toLocaleLowerCase() ||
          columnName.toLocaleLowerCase() === AppConstants.SampleId.toLocaleLowerCase() ||
          (columnName.toLocaleLowerCase() === AppConstants.Tank.toLocaleLowerCase() && this.displayedHeaders.indexOf(AppConstants.Barcode, AppConstants.BarcodeSearchStartPosition) == -1)) {
        columnName = AppConstants.Barcode;
          }
    return columnName;
  }

  isOptionalColumn(columnName:string, requiredColumns: string[]) : boolean {
    return !requiredColumns.some(requiredColumn => columnName.toLocaleLowerCase() == requiredColumn.toLocaleLowerCase())
  }

  getUniqueVesselsSubmitted(data) {
      return data != undefined && data.length > 0 ? data.map(x=>x.Barcode).filter((value, index, self) => self.indexOf(value) === index).length : 0;
  }

  clearTable() {
    this.dataSource.data = [];
    this.calculateDisplayCounts();
    this.clearAll.emit(this.sampleDay);
  }

  onClick(row,index) {
    this.RemoveElementFromArray(row);
    this.dataSource.data = this.cloneDataSource(this.dataSource.data);
    this.calculateDisplayCounts();
    this.removeRow.emit(row);
  }

  RemoveElementFromArray(row) {
    this.dataSource.data.forEach((value,index)=>{
        if(value.FermDay === row.FermDay 
            && value.StrainName === row.StrainName
            && value.StrainType === row.StrainType
            && value.Barcode === row.Barcode && value.Well === row.Well 
            && value.DilutionFactor === row.DilutionFactor) 
        {
            this.dataSource.data.splice(index,1);
            return false;
        }
           
    });
  }

  public filterSampleData = () => {
    this.dataSource.filter = this.searchTerm.trim().toLocaleLowerCase();
  }

  isSerachTermFound(sampleData: StrainInfoModel, searchTerm: string) : boolean
  {
      searchTerm = searchTerm.trim().toLocaleLowerCase();
      return sampleData.StrainName.trim().toLocaleLowerCase().includes(searchTerm)
              || sampleData.StrainType.trim().toLocaleLowerCase().includes(searchTerm)
              || sampleData.Barcode.trim().toLocaleLowerCase().includes(searchTerm)
              || (!!sampleData.Well && sampleData.Well.trim().toLocaleLowerCase().includes(searchTerm))
              || (!!sampleData.DilutionFactor && sampleData.DilutionFactor.trim().toLocaleLowerCase().includes(searchTerm))
              || (!!sampleData.Condition && sampleData.Condition.trim().toLocaleLowerCase().includes(searchTerm))
              || (!!sampleData.Tank && sampleData.Tank.trim().toLocaleLowerCase().includes(searchTerm))
              || (!!sampleData.StrainBarcodeId && sampleData.StrainBarcodeId.trim().toLocaleLowerCase().includes(searchTerm))
  }

  cloneDataSource(data) {
    let oldDataSource = data;
    let arr = [];  
    Object.keys(oldDataSource).map(function(key){  
        arr.push(oldDataSource[key])  
            
    }); 
    return arr;
  }

}
