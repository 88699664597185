import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AppConstants } from 'src/app/common-utility/appconstants';

@Component({
  selector: 'app-status-dialog',
  templateUrl: './status-dialog.component.html',
  styleUrls: ['./status-dialog.component.css']
})
export class StatusDialogComponent implements OnInit {

  message: any;
  constructor(public dialogRef: MatDialogRef<StatusDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: any) { }

  ngOnInit() {
    if(this.data.componentName == AppConstants.BARCODE && this.data.subComponentName == AppConstants.ACTIVEINACTIVEAll) {
      this.message = this.data.isActive ? "All the latest processed results files will be enabled. Are you sure you wish to proceed?" : "All barcodes will be marked inactive. Are you sure you wish to proceed?";
    }
    else if(this.data.componentName == AppConstants.BARCODE) {
      this.message = this.data.isActive ? "The latest processed results file will be enabled. Are you sure you wish to proceed?" : "All records for this barcode will be marked inactive. Are you sure you wish to proceed?";
    }
    else if(this.data.componentName == AppConstants.QCDATAPOPUPVALUE && this.data.subComponentName == AppConstants.ACTIVEINACTIVEAll) {
      this.message = this.data.isActive?"Are you sure, would you like to enable all the" + " " + this.data.componentName + "?":"Are you sure, would you like to disable all the" + " " + this.data.componentName + "?";
    }
    else {
      this.message = this.data.isActive?"Are you sure, would you like to enable the" + " " + this.data.componentName + "?":"Are you sure, would you like to disable the" + " " + this.data.componentName + "?";
    }
  }

  onSubmit(){
    this.dialogRef.close(true);
  }

  onCancel(){
    this.dialogRef.close(false);
  }

}
