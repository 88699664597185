import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { SlideCellRendererComponent } from 'src/app/custom/slide-cell-renderer/slide-cell-renderer.component';
import { MatDialog } from '@angular/material';
import * as _ from 'lodash';
import { EditButtonRendererComponent } from 'src/app/custom/edit-button-renderer/edit-button-renderer.component';
import { InstrumentModel } from 'src/app/models/instrument.model';
import { AppConstants } from 'src/app/common-utility/appconstants';
import { InstrumentService } from 'src/app/services/instrument-service/instrument.service';
import { InstrumentPopupComponent } from './instrument-popup.component';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from 'src/app/services/message-service';
import { GridSizeChangedEvent } from 'ag-grid-community';
import { DatePipe } from '@angular/common';

@Component({
    templateUrl: '../../shared/grid-component/grid.component.html',
    styleUrls: ['./instrument.component.css']
})

export class InstrumentComponent implements OnInit {
  private gridApi;
  private gridColumnApi;
  rowData: InstrumentModel[] = [];
  request: FormGroup;
  public context;
  public subscription: any;
  isSaveDisabled = false;
  componentName = AppConstants.INSTRUMENT;
  componentTitle = AppConstants.INSTRUMENTTITLE;

  columnDefs = [
    { headerName: 'Name', field: 'InstrumentName', sortable: true, filter: true, width: 200, tooltipValueGetter: (params) => params.value },
    { headerName: 'Description', field: 'Description', sortable: true, filter: true, width: 350, tooltipValueGetter: (params) => params.value },
    { headerName: 'Modified By', field: 'ModifiedBy', sortable: true, filter: true, width: 200, tooltipValueGetter: (params) => params.value },
    { headerName: 'Modified Date', field: 'ModifiedDate', sortable: true, filter: true, width: 200 },
    { headerName: 'Active', field: 'IsActive', sortable: true,  filter: true,  cellRenderer: 'slideCellRenderer',  width: 150}, // cellRenderer:slideCellRenderer is to load slider with event binded
    {
      field: '',
      cellRenderer: 'btnEditRenderer',
      tooltipValueGetter: (params) => 'Edit'
    },
  ];

  ngOnInit() {
    this.getInstrumentsInfo();
  }

  onGridSizeChanged(params: GridSizeChangedEvent) {
    params.api.sizeColumnsToFit();
  }
  
  getInstrumentsInfo(){
    this.service.get()
      .subscribe(
        result => {
          this.rowData = [];
          result.forEach(r => {
            this.rowData.push({
              InstrumentId: r.InstrumentId,
              InstrumentName: r.InstrumentName,
              Description: r.Description,    
              IsActive: r.IsActive,
              CreatedDate: r.CreatedDate,
              CreatedBy: r.CreatedBy,
              ModifiedDate: this.datepipe.transform(r.ModifiedDate,'MM/dd/yyyy'),
              ModifiedBy: r.ModifiedBy,
              show: true
            })
          })
        },
        error =>{
          console.log(error);
        }
      ) 
  }

  defaultColDef: {
    flex: 1,
    minWidth: 100,
    // allow every column to be aggregated
    enableValue: true,
    // allow every column to be grouped
    enableRowGroup: true,
    // allow every column to be pivoted
    enablePivot: true,
    sortable: true,
    filter: true,
  }
  frameworkComponents = {
    slideCellRenderer: SlideCellRendererComponent,
    btnEditRenderer: EditButtonRendererComponent
  };

  constructor(fb: FormBuilder, private dialog: MatDialog,
    private service: InstrumentService,
    private toastr: ToastrService,
    public datepipe: DatePipe,
    private _messageService: MessageService) {
    this.context = { componentParent: this };
    this._messageService.listen().subscribe((m:any) => {
      this.refreshGrid();
    })
  }

  updateStatus(instrumentModel: InstrumentModel, isActive: boolean) {
    this.service.updateStatus(instrumentModel.InstrumentId, isActive)
      .subscribe(result => {
        this.getInstrumentsInfo();
        this.toastr.success('Instrument updated successfully !!');
      },
        error => {
          this.toastr.error(error, 'Instrument update failed !!');
        })
  }

  add() {
    this.showPopup();
  }

  update(row){
    if(row.colDef != undefined && row.colDef !== '' && row.colDef !== null && row.colDef.field == '') {
      this.service.populateForm(row.data);
      this.showPopup();
    }
  }

  showPopup() {
      let dialogConfig = this.service.getDialogConfig();      
      dialogConfig.data = { componentName: this.componentName, componentId: "InstrumentId", controlName: "InstrumentName", popupHeight: "300px" };
      this.dialog.open(InstrumentPopupComponent,dialogConfig);
  }

  cancelStatusUpdate() {
    this.service.cancelStatusUpdate();
  }

  refreshGrid(){
    this.getInstrumentsInfo();
    this.gridApi.refreshClientSideRowModel('aggregate');
    this.onCloseEvent();
}

onCloseEvent() {
  if(this.subscription !== undefined) {
    this.subscription.unsubscribe();
  }
}

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.setDomLayout("autoHeight");
  }
}