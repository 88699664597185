export class AppConstants {
  public static get TOKEN(): string {
    return "msal.idtoken";
  }
  public static get ONERING_ADMIN(): string {
    return "RES-SG-One-Ring_Admin";
  }

  public static get ONERING_ANALYST(): string {
    return "RES-SG-One-Ring_Analyst";
  }

  public static get ONERING_REQUESTER(): string {
    return "RES-SG-One-Ring_Requestor";
  }

  public static get ONERING_ADMIN_DEV(): string {
    return "RES-SG-1Ring_DevT_Admin";
  }

  public static get ONERING_ANALYST_DEV(): string {
    return "RES-SG-1Ring_DevT_Analyst";
  }

  public static get ONERING_REQUESTER_DEV(): string {
    return "RES-SG-1Ring_DevT_Requestor";
  }

  public static get Dev_Env(): string {
    return "dev";
  }

  public static get Prod_Env(): string {
    return "prod";
  }

  public static get Qa_Env(): string {
    return "qa";
  }

  public static get Uat_Env(): string {
    return "uat"
  }

  public static get Local_Env(): string {
    return "local"
  }

  public static get AUTHUSERINFO(): string {
    return "userInfo";
  }

  public static get UIUSERROLE(): string {
    return "uiUserRole";
  }

  public static get ANALYSTUSERS(): string {
    return "uiAnalystUsers";
  }

  public static get CLICKTYPE(): string {
    return "clickType";
  }

  public static get LOADING(): string {
    return "Loading...";
  }

  public static get USERPROPERTYFILTER(): string {
    return "$select=id,givenName,surname,displayName,mail,jobTitle,businessPhones,mobilePhone,displayName,streetAddress,city,postalCode,state,country,department,officeLocation,onPremisesSamAccountName,companyName"
  }

  public static get LOGGEDINUSERINFO(): string {
    return "userInfo";
  }

  public static get SAVEDGRIDFILTER(): string {
    return "filterinfo";
  }

  public static get SAVEDGRIDSORT(): string {
    return "sortinfo";
  }

  public static get ADD(): string {
    return "add";
  }

  public static get EDIT(): string {
    return "edit";
  }

  public static get SUCCESS(): string {
    return "success";
  };

  public static get ERROR(): string {
    return "error";
  };

  public static get DASHBOARD(): string {
    return "Orders Due";
  }

  public static get SEARCH(): string {
    return "Search & Reporting";
  }

  public static get ONERINGADMIN(): string {
    return "/admin";
  }

  public static get ONERINGLOGIN(): string {
    return "/login";
  }

  public static get ONERINGDASHBOARD(): string {
    return "/dashboard";
  }

  public static get ONERINGREQUESTPAGE(): string {
    return "/group-request";
  }

  public static get ONERINGANALYSTPAGE(): string {
    return "/analyst-experiment-details";
  }

  public static get ONERINGSAMPLEINFORMATION(): string {
    return "/sample-information";
  }

  public static get QCDATA(): string {
    return "/view-qc-data";
  }

  public static get QCDATADETAILS(): string {
    return "/view-qc-data-details";
  }

  public static get RESULTDETAILS(): string {
    return "/experiment-result-details";
  }

  public static get ONERINGHOME(): string {
    return "/home";
  }

  public static get UNAUTHORIZED(): string {
    return "/unauthorized";
  }

  public static get BUSINESSALIGNMENT(): string {
    return "Business Alignment";
  }

  public static get EXPERIMENTTYPE(): string {
    return "Experiment Type";
  }

  public static get USERFRIENDLYHEADER(): string {
    return "User Friendly Header";
  }
  public static get USERFRIENDLYHEADERTITLE(): string {
    return "User Friendly Headers";
  }

  public static get FILEMAPPINGNEW(): string {
    return "New File Mapping";
  }
  public static get FILEMAPPINGTITLE(): string {
    return "File Mappings";
  }
  public static get FILEMAPPINGNAME(): string {
    return "File Mapping";
  }
  public static get FILEMAPPINGMODIFY(): string {
    return "Modify File Mapping";
  }

  public static get PROJECT(): string {
    return "Project";
  }

  public static get VESSEL(): string {
    return "Vessel";
  }

  public static get INSTRUMENT(): string {
    return "Instrument";
  }

  public static get METHOD(): string {
    return "Method";
  }

  public static get GROUP(): string {
    return "Group";
  }

  public static get BUSINESSALIGNMENTTITLE(): string {
    return "Business Alignments";
  }

  public static get EXPERIMENTTYPETITLE(): string {
    return "Experiment Types";
  }

  public static get PROJECTTITLE(): string {
    return "Projects";
  }

  public static get VESSELTITLE(): string {
    return "Vessels";
  }

  public static get INSTRUMENTTITLE(): string {
    return "Instruments";
  }

  public static get METHODTITLE(): string {
    return "Methods";
  }

  public static get GROUPTITLE(): string {
    return "Groups";
  }

  public static get UPDATESTATUS(): string {
    return "Update Status";
  }

  public static get InProgress(): string {
    return "InProgress";
  }

  public static get InProgressUI(): string {
    return "In Progress";
  }

  public static get New(): string {
    return "New";
  }

  public static get Complete(): string {
    return "Completed";
  }

  public static get Cancel(): string {
    return "Cancelled";
  }

  public static get SampleData(): string {
    return "SampleData";
  }


  public static get Time(): string {
    return "Time";
  }

  public static get Barcode(): string {
    return "Barcode";
  }
  public static get ExperimentID(): string {
    return "Experiment ID";
  }

  public static get Well(): string {
    return "Well";
  }
  public static get Instrument(): string {
    return "Instrument";
  }

  public static get Date(): string {
    return "Date";
  }
  public static get Day(): string {
    return "Day";
  }
  public static get StrainType(): string {
    return "StrainType";
  }
  // public static get HTPGridFormatOne(): string[] {
  //   return ['StrainName','StrainType','PlateID','Barcode','Well','FermDay','DilutionFactor'];
  // }

  public static get HTPGridFormatTwo(): string [] {
    return ['strainname','straintype','barcode','well','dilutionfactor'];
  }

  // public static get HTPGridFormatThree(): string[] {
  //   return ['Strain ID','Strain Type','Plate ID','Well','Ferm Day','Dilution Factor'];
  // }

  public static get OtherGridFormat(): string[] {
    return ['StrainName','Tank','Condition','SampleType','Barcode','Well','FermDay'];
  }

  public static get HTPGrid(): string[] {
    return ['select','StrainName','StrainType','Barcode','Well','FermDay','DilutionFactor','GroupType'];
  }

  public static get OtherGrid(): string[] {
    return ['select','StrainName','Tank','Condition','SampleType','Barcode','Well','FermDay','GroupType'];
  }

  public static get HTPGroupType(): string {
    return "HTP";
  }

  public static get FermDay(): string {
    return "FermDay";
  }
  public static get SampleInfoGroupId(): string {
    return "SampleInfoGroupId";
  }

  public static get SingleSpace(): string {
    return ' ';
  }

  public static get EmptySpace(): string {
    return '';
  }

  public static get Comma(): string {
    return ",";
  }

  public static get SelectColumn(): string {
    return 'select';
  }

  public static get ID(): string {
    return "ID";
  }

  public static get Id(): string {
    return "Id";
  }
  
  public static get OtherGroupType(): string {
    return "OTHER";
  }

  public static get PlateBarcode(): string {
    return "PlateBarcode";
  }

  public static get VialBarcode(): string {
    return "VialBarcode";
  }

  public static get SampleId(): string {
    return "SampleId";
  }

  public static get Tank(): string {
    return "Tank";
  }

  public static get StrainBarcodeID(): string {
    return "StrainBarcodeId";
  }

  public static get DilutionFactor(): string {
    return "DilutionFactor";
  }

  public static get Optional(): string {
    return "(Optional)";
  }

  public static get BarcodeSearchStartPosition(): number {
    return 35;
  }

  public static get SampleFormat(): string {
    return "SampleFormat";
  }

  public static get PasteText(): string {
    return "Paste (CTRL+V) Sample Information Here";
  }
  public static get SelectGroup(): string {
    return "Please select the group before adding the sample information.";
  }
  
  public static get SampleInformationExists(): string {
    return "Please clear the grid values and paste again.";
  }

  public static get DuplicateDataFound(): string {
    return "Duplicate data has been found in the pasted data set.  Please review the data and try again.";
  }

  public static get WellDataNotValid(): string {
    return "Valid format for Well is alpha numeric.  Example:  A1 or B4.";
  }

  public static get DilutionFactorDataNotValid(): string {
    return "Dilution Factor value must be numeric.";
  }

  public static get RequiredDataMissed(): string {
    return "Missing data has been found in the pasted data set.  Please review the data and try again.";
  }

  public static get SampleDayProcessed(): string {
    return "The selected sample day is processed. You can not add sample information in this stage.";
  }

  public static get InvalidSampleInformationFormat(): string {
    return "Invalid Format. Please paste the correct data format and try again.(Columns Format: SampleFormat).";
  }

  public static get DuplicateSampleInformation(): string {
    return "There were duplicate sample information. Please correct and try again.";
  }

  public static get ProcessedSamples(): string {
    return "There were processed samples for this sample day, those records will not be cleared.";
  }

  public static get SampleInfoExistsForDay(): string {
    return "You have data saved for the sample day. Please remove the sample information before removing the sample day.";
  }

  public static get SampleInfoExistsForMethod(): string {
    return "You have data saved for sample day associated with this method. Please remove the sample information before removing the method.";
  }
  public static get RequestorEmailExistInListPartOne(): string {
    return "Requestor email ";
  }
  public static get UserNotExistsInOneRing(): string {
    return "User doesn’t have access to the One Ring. Please contact administrator.";
  }
 
  public static get RequestorEmailExistInListPartTwo(): string {
    return " already exists. ";
  }
  public static get InvalidEmailId(): string {
    return "Provide valid email Id.";
  }
  public static get ExperimentStatus() {
    return "Are you sure you want to change the experiment status?";
  }
  
  public static get VesselsSubmitted(): string {
    return "VesselsSubmitted";
  }
  public static get SamplesSubmitted(): string {
    return "SamplesSubmitted";
  }
  public static get CreatedBy(): string {
    return "CreatedBy";
  }

  public static get CreatedDate(): string {
    return "CreatedDate";
  }

  public static get SORTORDER(): string {
    return "SortOrder";
  }

  public static get BARCODE(): string {
    return "Barcode";
  } 

  public static get ACTIVEINACTIVEAll(): string {
    return "ActiveInactiveAll";
  }
  
  public static get SAMPLEID(): string {
    return "Sample ID";
  }
  public static get PADDINGCHARZERO(): string {
    return "0";
  }

  public static get ISPROCESSED(): string {
    return "IsProcessed";
  }

  public static get UNPROCESSEDMESSAGE(): string {
    return "Are you sure you want to change from unprocessed to processed?";
  }

  public static get PROCESSEDMESSAGE(): string {
    return "Are you sure you want to change from processed to unprocessed?";
  }

  public static get STARTDATE(): string {
    return "StartDate";
  }

  public static get ENDDATE(): string {
    return "EndDate";
  }

  public static get DATEFORMAT(): string {
    return 'MM/dd/yyyy';
  }
  
  public static get QCDATAPOPUPVALUE(): string {
    return "QC Data";
  } 

  public static get SAMPLEDATESTRING(): string {
    return "SampleDate";
  }

  public static get EXPERIMENTSTARTDATESTRING(): string {
    return "ExperimentStartDate";
  }

  public static get ANDWITHSPACESSTRING(): string {
    return " And ";
  }

  public static get BETWEENWITHSPACESSTRING(): string {
    return " Between ";
  }

  public static get EQUALSYMBOLWITHSPACESSTRING(): string {
    return " = ";
  }

  
  
  
}