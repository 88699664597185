import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { SlideCellRendererComponent } from 'src/app/custom/slide-cell-renderer/slide-cell-renderer.component';
import { MatDialog } from '@angular/material';
import * as _ from 'lodash';
import { EditButtonRendererComponent } from 'src/app/custom/edit-button-renderer/edit-button-renderer.component';
import { GroupTypeModel } from 'src/app/models/group.model';
import { AppConstants } from 'src/app/common-utility/appconstants';
import { GroupTypeService } from 'src/app/services/group-service/group.service';
import { GroupTypePopupComponent } from './group-popup.component';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from 'src/app/services/message-service';
import { GridSizeChangedEvent } from 'ag-grid-community';
import { DatePipe } from '@angular/common';

@Component({
    templateUrl: '../../shared/grid-component/grid.component.html',
    styleUrls: ['./group.component.css']
})

export class GroupComponent implements OnInit {
    private gridApi;
    private gridColumnApi;
    rowData: GroupTypeModel[] = [];
    request: FormGroup;
    public context;
    public subscription: any;
    isSaveDisabled = false;
    componentName = AppConstants.GROUP;
    componentTitle = AppConstants.GROUPTITLE;

    columnDefs = [
      { headerName: 'Name', field: 'GroupName', sortable: true, filter: true, width: 200, tooltipValueGetter: (params) => params.value },
      { headerName: 'Description', field: 'Description', sortable: true, filter: true, width: 350, tooltipValueGetter: (params) => params.value },
      { headerName: 'Modified By', field: 'ModifiedBy', sortable: true, filter: true, width: 200, tooltipValueGetter: (params) => params.value },
      { headerName: 'Modified Date', field: 'ModifiedDate', sortable: true, filter: true, width: 200 },
      { headerName: 'Active', field: 'IsActive', sortable: true,  filter: true,  cellRenderer: 'slideCellRenderer',  width: 150}, // cellRenderer:slideCellRenderer is to load slider with event binded
      {
        field: '',
        cellRenderer: 'btnEditRenderer',
        tooltipValueGetter: (params) => 'Edit'
      },
    ];

    ngOnInit() {
      this.getGroupsInfo();
    }

    getGroupsInfo(){
      this.service.get()
        .subscribe(
          result => {
            this.rowData = [];
            result.forEach(r => {
              this.rowData.push({
                GroupTypeId: r.GroupTypeId,
                GroupName: r.GroupName,
                Description: r.Description, 
                ExperimentTypes: r.ExperimentTypes,   
                IsActive: r.IsActive,
                CreatedDate: r.CreatedDate,
                CreatedBy: r.CreatedBy,
                ModifiedDate: this.datepipe.transform(r.ModifiedDate,'MM/dd/yyyy'),
                ModifiedBy: r.ModifiedBy,
                show: true
              })
            })
          },
          error =>{
            console.log(error);
          }
        ) 
    }
    
    defaultColDef: {
      flex: 1,
      minWidth: 100,
      // allow every column to be aggregated
      enableValue: true,
      // allow every column to be grouped
      enableRowGroup: true,
      // allow every column to be pivoted
      enablePivot: true,
      sortable: true,
      filter: true,
    }
    frameworkComponents = {
      slideCellRenderer: SlideCellRendererComponent,
      btnEditRenderer: EditButtonRendererComponent
    };
//   hideRequiredControl = new FormControl(false);

  constructor(fb: FormBuilder, private dialog: MatDialog,
    private service: GroupTypeService,
    private toastr: ToastrService,
    public datepipe: DatePipe,
    private _messageService: MessageService) {
    this.context = { componentParent: this };
    this._messageService.listen().subscribe((m:any) => {
      this.refreshGrid();
    })
  }

  updateStatus(groupModel: GroupTypeModel, isActive: boolean) {
    this.service.updateStatus(groupModel.GroupTypeId, isActive)
      .subscribe(result => {
        this.getGroupsInfo();
        this.toastr.success('Group updated successfully !!');
      },
        error => {
          this.toastr.error(error, 'Group update failed !!');
        })
  }

  add() {
    this.showPopup();
  }

  update(row){
    if(row.colDef != undefined && row.colDef !== '' && row.colDef !== null && row.colDef.field == '') {
      this.service.populateForm(row.data);
      this.showPopup();
    }
  }

  showPopup() {
      let dialogConfig = this.service.getDialogConfig();      
      dialogConfig.data = { componentName: this.componentName, componentId: "GroupTypeId", controlName: "GroupName", popupHeight: "360px" };
      this.dialog.open(GroupTypePopupComponent,dialogConfig);
  }

  cancelStatusUpdate() {
    this.service.cancelStatusUpdate();
  }

  refreshGrid(){
    this.getGroupsInfo();
    this.gridApi.refreshClientSideRowModel('aggregate');
    this.onCloseEvent();
}

onCloseEvent() {
  if(this.subscription !== undefined) {
    this.subscription.unsubscribe();
  }
}

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.setDomLayout("autoHeight");
  }

  onGridSizeChanged(params: GridSizeChangedEvent) {
    params.api.sizeColumnsToFit();
  }
}