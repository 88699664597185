import { Component, OnInit , TemplateRef, ViewChild} from '@angular/core';
import { AppConstants } from '../common-utility/appconstants';
import { SearchCriteria } from '../models/search-criteria.model';
import { ResultDetailsService } from '../services/result-deatils-service/result-details.service';
import { GridSizeChangedEvent } from 'ag-grid-community';
import { MessageService } from '../services/message-service';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-search-and-reporting',
  templateUrl: './search-and-reporting.component.html',
  styleUrls: ['./search-and-reporting.component.scss']
})
export class SearchAndReportingComponent implements OnInit {

  componentTitle = AppConstants.SEARCH;
  searchField: any;
  searchFieldValue: any;
  searchStartDate: any;
  searchEndDate: any;
  isTextBoxVisible: boolean = true;
  searchCriteria: SearchCriteria[] = [];
  searchCriteriaList: any;
  showSearchFieldError: boolean = false;
  showSearchFieldValueError: boolean = false;
  showNoDataFound: boolean = false;
  searchList: any[] = [{id: 'Barcode', name: 'Barcode (Plate or Vial)'}, {id: 'ExperimentId', name: 'Experiment ID'}, {id: 'Instrument', name: 'Instrument'},
                       {id: 'StrainName', name: 'Strain Name'}, {id: 'StrainType', name: 'Strain Type'}, {id: 'GroupName', name: 'Group'},
                       {id: 'MethodName', name: 'Analytical Method'},{id: 'Day', name: 'Sample Day'},{id: 'ExperimentTypeName', name: 'Experiment Type'},
                       {id: 'StrainBarcodeId', name: 'Strain Barcode ID'},{id: 'Barcode', name: 'Sample ID'},{id: 'Barcode', name: 'Tank'},{id: 'SampleDate', name: 'Sample Date'}
                       ,{id: 'ExperimentStartDate', name: 'Experiment Start Date'} ,{id: 'ProjectName', name: 'Project'}
                      ];
  rowData: any[] = [];
  public context: any;
  private gridApi;
  private gridColumnApi;
  public subscription: any;
  columnDefs;
  @ViewChild('isMultiBarcodeTankSampleIdDialog') isMultiBarcodeTankSampleIdDialog: TemplateRef<any>
  constructor(private resultDetailsService: ResultDetailsService,public dialog: MatDialog,
              private _messageService: MessageService,
              public datepipe: DatePipe,) {
                this.context = { componentParent: this };
                this._messageService.listen().subscribe((m: any) => {
                  this.refreshGrid();
                });
               }

  frameworkComponents = {
};
defaultColDef: {
  flex: 1,
  enableValue: true,
  enableRowGroup: true,
  enablePivot: true,
  sortable: true,
  filter: true,
}

refreshGrid(){
  this.getSearchResults();
  this.gridApi.refreshClientSideRowModel('aggregate')
  this.onCloseEvent();
}

onCloseEvent() {
  if(this.subscription !== undefined) {
    this.subscription.unsubscribe();
  }
}

onGridReady(params) {
  this.gridApi = params.api;
  this.gridColumnApi = params.columnApi;
  //this.gridApi.setDomLayout("autoHeight");
}

onGridSizeChanged(params: GridSizeChangedEvent) {
  params.api.sizeColumnsToFit();
}
  
  ngOnInit() {
    this.searchList = this.searchList.sort((searchField1, searchField2) => (searchField1.name > searchField2.name) ? 1 : -1)
  }

  onSearchFieldChanged(event)
  {
    this.searchFieldValue = "";
    this.showSearchFieldError = false;
    this.showSearchFieldValueError = false;
    if(this.searchField.id === AppConstants.SAMPLEDATESTRING || this.searchField.id === AppConstants.EXPERIMENTSTARTDATESTRING) {
      this.isTextBoxVisible = false;
      this.searchStartDate = "";
      this.searchEndDate = "";
    }
    else {
      this.isTextBoxVisible = true;
      this.searchFieldValue = "";
    }
  }

  onSearchFieldValueChange(event)
  {
    if(this.searchFieldValue !== "") {
      this.showSearchFieldValueError = false;
    }
  }

  onAdd()
  {    
    if(this.searchField !== undefined && (this.searchFieldValue !== "") || (this.searchStartDate != "" && this.searchStartDate != null && this.searchEndDate != "" && this.searchEndDate != null)) {
      if(!this.isTextBoxVisible) {
        var startDate = new Date(this.searchStartDate);
        var endDate = new Date(this.searchEndDate);
        if(startDate > endDate) {
          this.showSearchFieldValueError = true;
        }
        else {
          this.showSearchFieldValueError = false;
        }
      }
      if(!this.searchCriteria.some(x=>x.SearchFieldId ===this.searchField.id) && !this.showSearchFieldValueError) {
        this.showSearchFieldError = false;
        this.showSearchFieldValueError = false;
        let calculatedSearchFieldValue = this.isTextBoxVisible ? this.searchFieldValue : this.datepipe.transform(this.searchStartDate, AppConstants.DATEFORMAT) + " - " + this.datepipe.transform(this.searchEndDate, AppConstants.DATEFORMAT);
        let calculatedSearchFieldDisplayValue = this.isTextBoxVisible ? this.searchFieldValue : this.datepipe.transform(this.searchStartDate, AppConstants.DATEFORMAT) + AppConstants.ANDWITHSPACESSTRING + this.datepipe.transform(this.searchEndDate, AppConstants.DATEFORMAT);
        let conditionOperator = this.isTextBoxVisible ? AppConstants.EQUALSYMBOLWITHSPACESSTRING : AppConstants.BETWEENWITHSPACESSTRING
        this.searchCriteria.push({
          SearchFieldId: this.searchField.id,
          SearchFieldName: this.searchField.name,
          SearchFieldValue: calculatedSearchFieldValue,
          SearchCondition: this.searchField.name + conditionOperator + calculatedSearchFieldDisplayValue
        });
        
        this.searchField = {};
        this.searchFieldValue = "";
        this.searchStartDate = "";
        this.searchEndDate = "";
        this.isTextBoxVisible = true;
  
      }
      else {      
        if(!this.showSearchFieldValueError)  {
          if(this.searchField.id===AppConstants.BARCODE ||this.searchField.id=== AppConstants.Tank ||this.searchField.id === AppConstants.SAMPLEID) {
          let dialogConfig = this.resultDetailsService.getDialogConfig();
          this.dialog.open(this.isMultiBarcodeTankSampleIdDialog, dialogConfig);
          }
          else
          {
          this.showSearchFieldError = true;
          }
        }
        
      }
    }
    else {
      if(this.searchField == undefined) {
        this.showSearchFieldError = true;
      }
      if(this.searchFieldValue == "" && this.isTextBoxVisible) {
        this.showSearchFieldValueError = true;
      }
      if((this.searchStartDate != "" || this.searchStartDate != null || this.searchEndDate != "" || this.searchEndDate != null) && !this.isTextBoxVisible) {
        this.showSearchFieldValueError = true;
      }
    }
  }

  clearSearchCriteria()
  {
    if(this.searchCriteriaList !== undefined && this.searchCriteriaList.length > 0) {
      this.searchCriteria = this.searchCriteria.filter(x=>!this.searchCriteriaList.some(y=> y.SearchFieldId === x.SearchFieldId));
    }
    else {
      this.searchCriteria = [];
    }

    this.rowData = [];
    this.columnDefs = [];
    this.showNoDataFound = false;

    
  }

  onSearch()
  {    
    this.getSearchResults();
  }

 

  onExport = () => {
    const params = this.getParams();
    this.gridApi.exportDataAsCsv(params);
};
getParams = () => {
 return {
   fileName: "SearchResults"
  };
}

  generateColumns(data: any[]) {
    let columnDefinitions = [];

    data.map(object => {

      Object
        .keys(object)
        .map(key => {
          let mappedColumn = {
            headerName: key,
            field: key,
            sortable: true, 
            filter: true
          }

          columnDefinitions.push(mappedColumn);
        })
    })
    //Remove duplicate columns
    columnDefinitions = columnDefinitions.filter((column, index, self) =>
      index === self.findIndex((colAtIndex) => (
        colAtIndex.field === column.field
      ))
    )
    return columnDefinitions;
  }

  getSearchResults() {
    this.showNoDataFound = false;
    let searchCriteriaModel = {};
    this.searchCriteria.forEach(x=>  {
      searchCriteriaModel[x.SearchFieldId] = x.SearchFieldValue;
    });

    this.resultDetailsService.getSearchResults(searchCriteriaModel)
      .subscribe(
        result => {
          this.rowData = [];
          this.rowData = result;
          if (this.rowData != undefined && this.rowData.length > 0) {           
            this.columnDefs = this.generateColumns(this.rowData);
          }
          else {
            this.columnDefs = [];
            this.showNoDataFound = true;
          }
        }); 
  }

}
