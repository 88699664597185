import { EnvironmentDefinition } from "./environment-defination.interface"

/* 
    This is the class where i can define the key and its value 
    and it will transform the value of the key based on environment.

    --> If you want to add a new key and its value in the below section 
        then first you define that key in the EnvironmentDefinition interface. 
*/
export class Environments {

    public static environments: EnvironmentDefinition[] = [
        {
            name: 'LOCAL',
            hosts: ['localhost'],
            APIPath: 'https://localhost:44319',
            UIPath: 'http://localhost:4200',
            clientID: '547569a2-9dd4-4cdb-86c8-cd98c7743dde',
            authority: 'https://login.microsoftonline.com/3e20ecb2-9cb0-4df1-ad7b-914e31dcdda4',
            SUPPORT_BOT_SETTINGS: {
                botAlias: "$LATEST",
                botName: "OneRingBot",
                supportName: "DSI Support",
                roleArn: "arn:aws:iam::074443567659:role/dsi_dv-lexrole-sts",
                roleSessionName: "LclSession"
            }
        },
        {
            name: 'DEV',
            hosts: ['dev'],
            APIPath: 'https://oneringapi-dev.se.research.corteva.com',
            UIPath: 'https://onering-dev.se.research.corteva.com/',
            clientID: '547569a2-9dd4-4cdb-86c8-cd98c7743dde',
            authority: 'https://login.microsoftonline.com/3e20ecb2-9cb0-4df1-ad7b-914e31dcdda4',
            SUPPORT_BOT_SETTINGS: {
                botAlias: "$LATEST",
                botName: "OneRingBot",
                supportName: "DSI Support",
                roleArn: "arn:aws:iam::074443567659:role/dsi_dv-lexrole-sts",
                roleSessionName: "DvSession"
            }
        },
        {
            name: 'QA',
            hosts: ['qa'],
            APIPath: 'https://oneringapi-qa.se.research.corteva.com',
            UIPath: 'https://onering-qa.se.research.corteva.com',
            clientID: '547569a2-9dd4-4cdb-86c8-cd98c7743dde',
            authority: 'https://login.microsoftonline.com/3e20ecb2-9cb0-4df1-ad7b-914e31dcdda4',
            SUPPORT_BOT_SETTINGS: {
                botAlias: "$LATEST",
                botName: "OneRingBot",
                supportName: "DSI Support",
                roleArn: "arn:aws:iam::078183859623:role/dsi_qa-lexrole-sts",
                roleSessionName: "QASession"
            }
        },
        {
            name: 'UAT',
            hosts: ['uat'],
            APIPath: 'https://oneringapi-uat.se.research.corteva.com',
            UIPath: 'https://onering-uat.se.research.corteva.com',
            clientID: '547569a2-9dd4-4cdb-86c8-cd98c7743dde',
            authority: 'https://login.microsoftonline.com/3e20ecb2-9cb0-4df1-ad7b-914e31dcdda4',
            SUPPORT_BOT_SETTINGS: {
                botAlias: "$LATEST",
                botName: "OneRingBot",
                supportName: "DSI Support",
                roleArn: "arn:aws:iam::078183859623:role/dsi_qa-lexrole-sts",
                roleSessionName: "QASession"
            }
        },
        {
            name: 'PROD',
            hosts: ['onering','prod'],
            APIPath: 'https://oneringapi.se.research.corteva.com',
            UIPath: 'https://onering.se.research.corteva.com',
            clientID: '547569a2-9dd4-4cdb-86c8-cd98c7743dde',
            authority: 'https://login.microsoftonline.com/3e20ecb2-9cb0-4df1-ad7b-914e31dcdda4',
            SUPPORT_BOT_SETTINGS: {
                botAlias: "$LATEST",
                botName: "OneRingBot",
                supportName: "DSI Support",
                roleArn: "arn:aws:iam::091853933339:role/dsi_pd-lexrole-sts",
                roleSessionName: "PdSession"
            }
        }
    ];
}