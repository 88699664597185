import {Component, Inject, Output, EventEmitter} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { BusinessAlignmentService } from 'src/app/services/business-alignment-service/business-alignment.service';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from 'src/app/services/message-service';


@Component({
    selector: 'business-alignment-popup',
    templateUrl: '../popup-component/popup.component.html',
    styleUrls: ['../popup-component/popup.component.css']
  })


  export class BusinessAlignmentPopupComponent {
    isFormInvalid: boolean = false;
    clickEventTrigger: boolean = false;
      componentName: string;
      componentId: string;
      controlName: string;
    constructor(
        private service: BusinessAlignmentService,
        public dialogRef: MatDialogRef<BusinessAlignmentPopupComponent>,
        private toastr: ToastrService,
        private _messageService: MessageService,
        @Inject(MAT_DIALOG_DATA) public data: any) {
            this.componentName = this.data.componentName;
            this.componentId = this.data.componentId;
            this.controlName = this.data.controlName;
        }
  
        onNoClick(): void {
        this.dialogRef.close();
        }

        onClear() {
            this.service.form.reset();
            this.service.initializeFormGroup();
        }
  
        onSubmit() {
            this.checkValidRecord();
        }

        onKeyDown(event) {
            this.isFormInvalid = false;
        }

        checkValidRecord() {
            let isValid: boolean = false;
            let model = this.service.form.value;
            this.service.get()
            .subscribe(
                result => {
                    if (!result.some((businessAlignmentModel) => businessAlignmentModel.BusinessAlignmentName.toLowerCase() == model.BusinessAlignmentName.toLowerCase())) {
                        this.isFormInvalid = false;
                        isValid = true;
                    }
                    else {
                        let businessAlignment = result.find(businessAlignment => businessAlignment.BusinessAlignmentId === model.BusinessAlignmentId);
                        if(businessAlignment !== null && businessAlignment != undefined) {                            
                            if(businessAlignment.Description !== model.Description ) {
                                this.isFormInvalid = false;
                                isValid = true;
                            }
                            else {
                                this.isFormInvalid = true;
                                isValid = false;
                            }
                        }
                        else {
                            this.isFormInvalid = true;
                            isValid = false;
                        }
                    }

                    if (this.service.form.valid && isValid) {
                        if (!this.service.form.get('BusinessAlignmentId').value) {
                            this.service.add(this.service.form.value)
                            .subscribe(
                                result =>{
                                    this._messageService.filter('refresh');
                                    this.toastr.success('Business Aignment created successfully !!');
                                },
                                error =>{
                                    this.toastr.error(error,'Business Alignment creation failed !!');
                                }
                            );
                        }
                        else {
                            this.service.update(this.service.form.value)
                            .subscribe(
                                result =>{
                                    this._messageService.filter('refresh');
                                    this.toastr.success('Business Alignment updated successfully !!');
                                },
                                error =>{
                                    this.toastr.error('Business Alignment update failed !!');
                                }
                            );
                        }
                        this.onClose();
                    }
                    
                },
                error =>{
                    console.log(error);
                }); 
            return isValid;
        }

        onClose() {
            this.service.form.reset();
            this.service.initializeFormGroup();
            this.dialogRef.close();
        }

        popupHeader() {
           return this.service.form.controls['BusinessAlignmentId'].value?"Modify " + this.componentName:"New " + this.componentName
        }
  }