export class InstrumentModel {
    InstrumentId: number;
    InstrumentName: string;
    Description: string;
    CreatedBy: string;
    CreatedDate: Date;
    ModifiedBy: string;
    ModifiedDate: string;
    IsActive: boolean;
    show: boolean = true;
}