import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { ResComponentsCoreModule } from '@corteva-research/ngx-components-core';
import { ResComponentsMsalIntegrationModule, MsalAuthService } from '@corteva-research/ngx-components-msal-integration';
import { MsalModule, MsalInterceptor } from '@azure/msal-angular';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatStepperModule, MatInputModule, MatRadioModule, MatCheckbox, MatCardModule, MatFormFieldModule, MatDatepickerModule, MatNativeDateModule, MatSelectModule, MatButtonModule, MatIconModule, MatSlideToggleModule, MatDialogModule, MatToolbarModule, MatGridTile, MatGridListModule, MatDialogRef, MAT_DIALOG_DATA, MatTabsModule, MatCheckboxModule, MatTableModule, MatTooltipModule, MatDividerModule,MatTreeModule, MatListModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgGridModule } from 'ag-grid-angular';

import { SupportBotModule, JWT_TOKEN_RETRIEVER, SUPPORT_BOT_SETTINGS } from '@corteva-research/ngx-support-bot';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { LoadingComponent } from './loading/loading.component';
import { UnauthorizedComponent } from './login/unauthorized.component';
import { httpSetHeaders } from './services/interceptor-service/httpSetHeaders.interceptor';
import { SecurityService } from './services/security.service';
import { RoleGuardService } from './services/role-gaurd-service';
import { ToastrService, ToastrModule } from "ngx-toastr";
import { UserComponent } from './user/user.component';
import '@stimulus/polyfills';

import { TableModule } from 'primeng/Table';
import { ToastModule } from 'primeng/toast';
import { DialogModule } from 'primeng/dialog';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { CalendarModule } from 'primeng/calendar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CardModule } from 'primeng/card';
import { ChartModule } from 'primeng/chart';
import { DataViewModule } from 'primeng/dataview';
import { TabViewModule } from 'primeng/tabview';
import { CheckboxModule } from 'primeng/checkbox';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RoleComponent } from './role/role.component';
import { UserRoleComponent } from './userrole/userrole.component';
import { ConfirmDialogModule, PanelModule } from 'primeng/primeng';
import { ConfirmationService } from 'primeng/api';
import { ConfigurationSettings } from './configuration-settings';
import { CheckboxCellRendererComponent } from './custom/checkbox-cell-renderer/checkbox-cell-renderer.component';
import { ButtonRendererComponent } from './custom/button-renderer/button-renderer.component';
import { CommonService } from './services/common/common.service';
import { CommonModule, DatePipe } from '@angular/common';
import { GroupRequestComponent } from './group-request/group-request.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { GroupComponent } from './administration/group/group.component';
import { MethodComponent } from './administration/method/method.component';
import { InstrumentComponent } from './administration/instrument/instrument.component';
import { VesselComponent } from './administration/vessel/vessel.component';
import { SlideCellRendererComponent } from './custom/slide-cell-renderer/slide-cell-renderer.component';
import { EditButtonRendererComponent } from './custom/edit-button-renderer/edit-button-renderer.component';
import { VesselDialogComponent } from './administration/vessel/vessel-popup.component';
import { BusinessAlignmentComponent } from './administration/business-alignment/business-alignment.component';
import { ExperimentTypeComponent } from './administration/experiment-type/experiment-type.component';
import { UserFriendlyHeadersComponent } from './administration/user-friendly-headers/user-friendly-headers.component';
import { ProjectComponent } from './administration/project/project.component';
import { StatusDialogComponent } from './shared/status-dialog/status-dialog.component';
import { BusinessAlignmentPopupComponent } from './administration/business-alignment/business-alignment-popup.component';
import { GroupTypePopupComponent } from './administration/group/group-popup.component';
import { ExperimentTypePopupComponent } from './administration/experiment-type/experiment-type-popup.component';
import { ProjectPopupComponent } from './administration/project/project-popup.component';
import { UserFriendlyHeadersPopupComponent } from './administration/user-friendly-headers/user-friendly-headers-popup.component';
import { MessageService } from './services/message-service';
import { LoadingService } from './services/loading.service';
import { InstrumentPopupComponent } from './administration/instrument/instrument-popup.component';
import { MethodDialogComponent } from './administration/method/method-popup.component';
import { LinkRendererComponent } from './custom/link-renderer/link-renderer.component';
//import { Analyst } from './analyst/analyst.component/analyst.component.component';
//import { AnalystComponent } from 'src/app/analyst/analyst.component';
import { AlertDialogComponent } from './group-request/group-alerts/alert-dialog.component';
import { DateAlertDialogComponent } from './group-request/group-alerts/date-alert-dialog.component';
import { SpecialCharacterDirective } from './shared/directives/specialCharacter.directive';
import { DashboardAlertDialogComponent } from './custom/link-renderer/dashboard-alert-dialog/dashboard-alert-dialog.component';
import { AnalystExperimentDetailsComponent } from './analyst-experiment-details/analyst-experiment-details.component';
import { DisableControlDirective } from './shared/directives/disable-control.directive';
import { SortByPipe } from './shared/sort/sort-pipe';
import { ClonerService } from './services/deep-clone/deep-clone.service';
import { StrainInfoComponent } from './group-request/strain-info-popup/strain-info-popup.component';
import { StrainInfoPopupAlertComponent } from './group-request/strain-info-popup/strain-popup-alerts/strain-info-popup-alert.component';
import { ImportResultsFileManuallyComponent } from './AnalystAdministration/import-results-file-manually/import-results-file-manually.component';
import { FileDropModule } from 'ngx-file-drop';
import { ProcessRogueFilesComponent } from './AnalystAdministration/process-rogue-files/process-rogue-files.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FileMappingPopupComponent } from './administration/file-mapping/file-mapping-popup.component';
import { FileMappingComponent } from './administration/file-mapping/file-mapping.component';
import { JwtTokenServiceService } from './services/jwt-token-service.service';
import { FileMappingDuplicateRecordAlertComponent } from './administration/file-mapping/file-mapping-popup_alerts/file-mapping-duplicate-record-alert.component';
import { FileMappingValidationPopupAlert } from './administration/file-mapping/file-mapping-validation-popup_alert/file-mapping-validation-popup_alert.component';
import { UserFriendlyHeadersValidationPopupAlert } from './administration/user-friendly-headers/user-friendly-headers_alerts/user-friendly-headers-validation-popup-alert.component';
import { FileMappingVesselValidationPopupAlert } from './administration/file-mapping/file-mapping-vessel_validation-popup_alert/file-mapping-vessel_validation-popup_alert.component';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { StrainInfoTableComponent } from './group-request/strain-info-popup/strain-info-table/strain-info-table.component';
import { ExperimentResultDetailsComponent } from './experiment-result-details/experiment-result-details.component';
import { MatchRendererComponent } from './custom/match-renderer/match-renderer.component';
import { EntriesLinkRendererComponent } from './custom/entries-link-renderer/entries-link-renderer.component';
import { BarcodeEntriesPopupComponent } from './custom/entries-link-renderer/barcode-entries-popup/barcode-entries-popup.component';
import { SearchAndReportingComponent } from './search-and-reporting/search-and-reporting.component';
import { SampleInformationComponent } from './group-request/sample-information/sample-information.component';
import { InformationPopupComponent } from './administration/file-mapping/information-popup/information-popup.component';
import { ViewQcDataComponent } from './view-qc-data/view-qc-data.component';
import { ViewQcDataDetailsComponent } from './view-qc-data-details/view-qc-data-details.component';
import { QcDataDetailsLinkRendererComponent } from './custom/qc-data-details-link-renderer/qc-data-details-link-renderer.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    DashboardComponent,
    GroupComponent,
    VesselDialogComponent,
    InstrumentPopupComponent,
    ProjectPopupComponent,
    MethodDialogComponent,
    ExperimentTypePopupComponent,
    FileMappingPopupComponent,
    UserFriendlyHeadersPopupComponent,
    GroupTypePopupComponent,
    BusinessAlignmentPopupComponent,
    MethodComponent,
    InstrumentComponent,
    VesselComponent,
    BusinessAlignmentComponent,
    ExperimentTypeComponent,
    UserFriendlyHeadersComponent,
    FileMappingComponent,
    ProjectComponent,
    GroupRequestComponent,
    LoadingComponent,
    UnauthorizedComponent,
    UserComponent,
    RoleComponent,
    UserRoleComponent,
    CheckboxCellRendererComponent,
    SlideCellRendererComponent,
    ButtonRendererComponent,
    EditButtonRendererComponent,
    StatusDialogComponent,
    AlertDialogComponent,
    DateAlertDialogComponent,
    SpecialCharacterDirective,
    DisableControlDirective,
    LinkRendererComponent,
    DashboardAlertDialogComponent,
    StrainInfoComponent,
    StrainInfoPopupAlertComponent,    
    AnalystExperimentDetailsComponent,
    ImportResultsFileManuallyComponent,
    ProcessRogueFilesComponent,
    FileMappingDuplicateRecordAlertComponent,
    FileMappingValidationPopupAlert,
    StrainInfoTableComponent,
    UserFriendlyHeadersValidationPopupAlert,
    ExperimentResultDetailsComponent,
    MatchRendererComponent,
    EntriesLinkRendererComponent,
    BarcodeEntriesPopupComponent,
    SearchAndReportingComponent,
    FileMappingVesselValidationPopupAlert,
    SampleInformationComponent,
    InformationPopupComponent,
    ViewQcDataComponent,
    ViewQcDataDetailsComponent,
    QcDataDetailsLinkRendererComponent 
  ],
  imports: [
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    AgGridModule.withComponents([CheckboxCellRendererComponent, LinkRendererComponent, SlideCellRendererComponent, EditButtonRendererComponent, ButtonRendererComponent, MatchRendererComponent, EntriesLinkRendererComponent, QcDataDetailsLinkRendererComponent]),
    AppRoutingModule,
    MatStepperModule,
    MatInputModule,
    MatCardModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatTooltipModule,
    MatRadioModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    MatToolbarModule,
    MatTableModule,
    MatDividerModule,
    MatTabsModule,
    MatCheckboxModule,
    MatGridListModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatTreeModule,
    MatListModule,
    FormsModule, ReactiveFormsModule,
    ResComponentsCoreModule.forRoot(),
    ToastrModule.forRoot({timeOut: 15000, closeButton: true}),
    ResComponentsMsalIntegrationModule,
    MsalModule.forRoot({
      clientID: ConfigurationSettings.CLIENT_ID,
      authority: ConfigurationSettings.AUTHORITY,
      redirectUri: ConfigurationSettings.APP_UI_URL,
      // validateAuthority : true,
      // cacheLocation : "localStorage",
      postLogoutRedirectUri: ConfigurationSettings.APP_UI_URL + "/login",
      // navigateToLoginRequestUrl : true,
      // popUp: true,
      consentScopes: [],
      //protectedResourceMap: protectedResourceMap,
    }),
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    TableModule,
    ButtonModule,
    InputTextModule,
    DialogModule,
    DropdownModule,
    CalendarModule,
    ToastModule,
    MessageModule,
    MessagesModule,
    RadioButtonModule,
    ConfirmDialogModule,
    PanelModule,
    CardModule,
    ChartModule,
    DataViewModule,
    TabViewModule,
    CheckboxModule,
    ProgressSpinnerModule,
    AgGridModule.withComponents([]),

    SupportBotModule,
    MatDialogModule,
    FileDropModule,
    FlexLayoutModule,
    MatSortModule,
    MatPaginatorModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true }, MsalAuthService, LoadingService,
    { provide: HTTP_INTERCEPTORS, useClass: httpSetHeaders, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
    { provide: SUPPORT_BOT_SETTINGS, useValue: ConfigurationSettings.SUPPORT_BOT_SETTINGS },
    { provide: JWT_TOKEN_RETRIEVER, useClass: JwtTokenServiceService },

    ToastrService,
    MessageService,
    MsalAuthService,
    RoleGuardService,
    SecurityService,
    ClonerService,
    ConfirmationService,
    CommonService,
    DatePipe,
    SortByPipe
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    ProjectPopupComponent,
    ExperimentTypePopupComponent,
    UserFriendlyHeadersPopupComponent,
    GroupTypePopupComponent,
    BusinessAlignmentPopupComponent,
    FileMappingPopupComponent,
    VesselDialogComponent,
    AlertDialogComponent,
    DateAlertDialogComponent,
    MethodDialogComponent,
    InstrumentPopupComponent,
    DashboardAlertDialogComponent,
    StrainInfoComponent,
    StrainInfoPopupAlertComponent,
    StatusDialogComponent,
    FileMappingDuplicateRecordAlertComponent,
    FileMappingValidationPopupAlert,
    UserFriendlyHeadersValidationPopupAlert,
    BarcodeEntriesPopupComponent,
    FileMappingVesselValidationPopupAlert,
    InformationPopupComponent

  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})

export class AppModule { }
